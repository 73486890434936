// import './App.css';
import Base from './layout/Base';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollTop from './components/ScrollTop';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { UserProvider } from './contexts/UserContext'
import Home from './pages/Home';
import Innovazione from './pages/Innovazione';
import Comunicazione from './pages/Comunicazione';
import Leadership from './pages/Leadership';
import Resources from './pages/Resources';
import Data from './pages/Data';
import Login from './pages/Login';
import SignIn from './pages/SignIn';
import Loading from './pages/Loading';
import WebinarRoom from './pages/WebinarRoom';

import { getCurrentTheme } from './config/themes';
import { PageTracker } from './utils/tracking';
import PreRoom from './youmain/pages/PreRoom';
import { LocalTracksProvider } from './youmain/contexts/LocalTracksContext';

const GlobalStyle = createGlobalStyle`

  * {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
  }

  body {
    color: #fff;
    font-family: Montserrat, sans-serif;
  }

  body.noscroll {
    overflow: hidden;
  }

  a {
    color: white;
  }
`;

function App() {
  return (
    <div>
      <GlobalStyle />
      <Router>
        <ThemeProvider theme={getCurrentTheme}>
          <Switch>
            <Route path="/s-data" component={Data} />
            <Route>
              <UserProvider>
                <PageTracker />
                <Base>
                  <ScrollTop />
                  <Header />
                  <Switch>
                    <Route path="/login">
                      <Login />
                    </Route>
                    <Route path="/signin">
                      <SignIn />
                    </Route>
                    <Route path="/innovazione">
                      <Innovazione />
                    </Route>
                    <Route path="/comunicazione">
                      <Comunicazione />
                    </Route>
                    <Route path="/innovazione">
                      <Innovazione />
                    </Route>
                    <Route path="/leadership">
                      <Leadership />
                    </Route>
                    <Route path="/room/:room">
                      <LocalTracksProvider>
                        <PreRoom />
                      </LocalTracksProvider>
                    </Route>
                    {/* <Route path="/resources">
                  <Resources />
                </Route> */}
                    <Route path="/webinar-room">
                      <WebinarRoom />
                    </Route>
                    <Route path="/loading">
                      <Loading />
                    </Route>
                    <Route path="/">
                      <Home />
                    </Route>
                  </Switch>
                  <Footer />
                </Base>
              </UserProvider>
            </Route>
          </Switch>
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
