import React from 'react';
import styled, {keyframes} from 'styled-components';

import {
  IconMicOff,
  IconCamOff,
  IconRecord,
  IconHand,
  IconPlaceholder,
} from '../../../Icons';
import Commands from './Commands';
import {darken} from 'polished';
import FirstLetters from 'first-letters';
import {ParticipantBoxProps} from '../types';
import VideoPlaceholder from '../../../VideoPlaceholder';
import {useParams} from 'react-router';
import useFirebaseVal from '../../../../../hooks/useFirebaseVal';

export default function VideoBox({
  owner,
  isMute,
  isLocalVideo,
  hasVideo,
  fullscreenRef,
  fullscreen,
  commands,
  recording,
  children,
  participantNumber,
}: ParticipantBoxProps) {
  const [user] = useFirebaseVal(`users/${owner}`);
  const nameFirstLetters = FirstLetters(user?.name || '');
  const {room} = useParams();
  const userName = user?.name;

  return (
    <VideoContainer
      $hasVideoTrack={hasVideo}
      $isFullscreen={fullscreen.isFullscreen}
      ref={fullscreenRef}
    >
      {children}
      {recording.isRecording && (
        <Recording onClick={recording.stop}>
          <span>REC</span>
          <IconRecord fill="#fff" size="22px" />
        </Recording>
      )}
      {!recording.isRecording && recording.isRecorded && (
        <LocalRecording>
          <span>REC</span>
          <IconRecord fill="#fff" size="22px" />
        </LocalRecording>
      )}
      {/* {handRaised && (
        <HandRaised>
          <IconHand fill="#fff" size="22px" />
          {isLocalVideo ? (
            <span>Hai alzato la mano</span>
          ) : (
            <span>{owner} ha alzato la mano</span>
          )}
        </HandRaised>
      )} */}
      {!hasVideo && (
        <Placeholder>
          <IconCamOff size="34px" fill="#fff" />
        </Placeholder>
      )}
      {!fullscreen.isFullscreen && (
        <VideoOver>
          <Commands commands={commands} color="#fff" size="20px" />
        </VideoOver>
      )}
      <StatusContainer>
        <VideoOwnerName>{isLocalVideo ? userName + ' (Tu)' : userName}</VideoOwnerName>
        <div>
          {isMute && (
            <IconContainer>
              <IconMicOff fill="#fff" size="16px" />
            </IconContainer>
          )}
          {!hasVideo && (
            <IconContainer>
              <IconCamOff fill="#fff" size="16px" />
            </IconContainer>
          )}
        </div>
      </StatusContainer>
    </VideoContainer>
  );
}

const VideoContainer = styled.div<{
  $hasVideoTrack: boolean;
  $isFullscreen: boolean;
}>`
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 100%;
  max-height: 100%;

  > div:first-child {
    height: 100%;
  }

  video {
    position: absolute;
    top: 0px;
    left: 50%;
    width: 100%;
    max-height: 100%;
    border-radius: 5px;
    transform: translate(-50%, 0px);
  }

  ${(props) =>
    props.$hasVideoTrack
      ? `background-color: transparent;`
      : `background-color: rgba(0,0,0,0.5); width: 100%; height: 100%;`}

  ${(props) =>
    props.$isFullscreen &&
    `
      video {
        width: 100%;
        height: 100%;
      }
    `}
`;

const HandRaisedAlert = keyframes`
  0% {
    background-color: black;
  }
  50% {
    background-color: #208B40;
  }
  100% {
    background-color: black;
  }
`;

const RecordingAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const Recording = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 1;
  color: #fff;
  background-color: ${(props) => props.theme.colors.secondary.decline};
  border-radius: 4px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color ease 0.3s;

  &:hover {
    background-color: ${(props) =>
      darken(0.1, props.theme.colors.secondary.decline)};
  }

  span {
    margin-right: 8px;
    font-size: 14px;
    white-space: nowrap;
    font-weight: 400;
  }

  i {
    animation: ${RecordingAnimation} infinite linear 2s;
  }
`;

const LocalRecording = styled(Recording)`
  pointer-events: none;
`;

const HandRaised = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-start;
  top: 16px;
  left: 16px;
  max-width: 100%;
  padding: 4px 8px;
  border-radius: 6px;
  animation: ${HandRaisedAlert} ease-in-out 3s infinite;

  span {
    display: block;
    margin-left: 8px;
    font-size: 14px;
    line-height: 24px;
    color: white;
  }
`;

const StatusContainer = styled.div`
  position: absolute;
  bottom: 0;
  padding: 0 3px 3px;
  width: 100%;
  left: 0;
  right: 0;
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media ${(props) => props.theme.breakpoints.tablet} {
    justify-content: flex-start;
    padding-left: 3%;
    padding-right: 3%;
    bottom: 0;
    padding-bottom: 10px;
    padding-top: 12px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
`;

const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${(props) =>
    darken(0.1, props.theme.colors.secondary.decline)};
  margin-right: 6px;

  &:last-child {
    margin-right: 0;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    background-color: transparent;
    width: 26px;
    height: 26px;
  }
`;

const VideoOwnerName = styled.span`
  display: inline-block;
  width: auto;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  opacity: 1;
  transition: opacity ease 300ms;

  @media ${(props) => props.theme.breakpoints.tablet} {
    background-color: transparent;
    padding: 3px 6px;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 27px;
  text-transform: uppercase;
  width: 20%;
  max-width: 90px;
  min-width: 50px;
  border-radius: 50%;

  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }

  span {
    position: absolute;
  }

  > svg {
    position: absolute;
  }
`;

const VideoOver = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  width: auto;
  align-items: center;
  justify-content: center;
  z-index: 1;
  opacity: 0;
  border-radius: 5px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity ease 300ms;

  ${VideoContainer}:hover & {
    opacity: 1;
    pointer-events: initial;
  }
`;
