import type Twilio from 'twilio-video';
import {
  Participant,
  RemoteParticipant,
  LocalParticipant,
  VideoRoom,
  ServerParticipantList,
} from '../types/video-room';

function extractParticipant(
  participant: Twilio.RemoteParticipant,
  name: string
): RemoteParticipant;

function extractParticipant(
  participant: Twilio.LocalParticipant,
  name: string
): LocalParticipant;

function extractParticipant(participant, name) {
  const audioPublication = [...participant.audioTracks.values()].filter(
    (track) => track.isSubscribed !== false
  )[0];

  const cameraPublication = [...participant.videoTracks.values()]
    .filter((track) => track.isSubscribed !== false)
    .find((track) => track.trackName === 'camera');

  const screenPublication = [...participant.videoTracks.values()]
    .filter((track) => track.isSubscribed !== false)
    .find((track) => track.trackName === 'sharing');

  return {
    identity: participant.identity,
    name: name,
    audio: audioPublication,
    video: cameraPublication,
    screen: screenPublication,
  };
}

export function extractDataFromTwilio(
  room: Twilio.Room,
  participants: ServerParticipantList
): VideoRoom {
  const dominantSpeaker = room.dominantSpeaker
    ? extractParticipant(
        room.dominantSpeaker,
        participants[room.dominantSpeaker.identity]
      )
    : null;

  const localParticipant = extractParticipant(
    room.localParticipant,
    participants[room.localParticipant.identity]
  );

  const remotes = [...room.participants.values()].map((p) =>
    extractParticipant(p, participants[p.identity])
  );

  const allParticipants: Participant[] = []
    .concat(remotes)
    .concat(localParticipant);

  const screenSharing = allParticipants.find((p) => p.screen);

  return {
    roomState: room.state as 'connected' | 'reconnecting' | 'disconnected',
    dominantSpeaker,
    localParticipant,
    remotes,
    screenSharing,
  };
}
