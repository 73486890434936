import {useEffect} from 'react';
import styled from 'styled-components';
import {AudioTrack, VideoTrack} from 'twilio-video';
import ParticipantMedia from './ParticipantMedia';
import {VideoPreview, VideoWrapper, VideoEmptyMessage} from './VideoPreview';
import VideoControls from './VideoControls';

interface LocalMediaProps {
  videoTrack?: VideoTrack;
  audioTrack?: AudioTrack;

  videoDevices: MediaDeviceInfo[];
  audioDevices: MediaDeviceInfo[];

  openMic: (deviceId?: string) => Promise<any>;
  closeMic: () => Promise<void>;
  openCamera: (deviceId?: string) => Promise<any>;
  closeCamera: () => Promise<void>;
}

export default function LocalMediaSetup({
  videoTrack,
  audioTrack,
  videoDevices,
  audioDevices,
  openMic,
  closeMic,
  openCamera,
  closeCamera,
}: LocalMediaProps) {
  useEffect(() => {
    openCamera()
      .catch(() => console.warn('camera error'))
      .then(() => openMic())
      .catch(() => console.warn('mic error'));
  }, []);

  return (
    <>
      <VideoPreview>
        {!videoTrack && (
          <VideoEmptyMessage>Camera disattivata</VideoEmptyMessage>
        )}
        <VideoWrapper>
          <StyledParticipantMedia
            videoTrack={videoTrack}
            audioTrack={audioTrack}
          />
        </VideoWrapper>

        <ControlsContainer>
          <VideoControls
            enabledCamera={!!videoTrack}
            enabledMic={!!audioTrack}
            videoDevices={videoDevices}
            audioDevices={audioDevices}
            openMic={openMic}
            closeMic={closeMic}
            openCamera={openCamera}
            closeCamera={closeCamera}
          />
        </ControlsContainer>
      </VideoPreview>
    </>
  );
}

const StyledParticipantMedia = styled(ParticipantMedia)`
  video {
    max-width: 100%;
    max-height: 100%;
  }
`;

const ControlsContainer = styled.div`
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;
