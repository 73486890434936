import styled, {keyframes, css} from 'styled-components';

const rotate = keyframes`
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const Loading = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => (props.width ? props.width : '32px')};
  height: ${(props) => (props.height ? props.height : '32px')};

  &::after {
    content: '';
    box-sizing: border-box;
    display: block;
    animation: ${rotate} infinite linear 0.6s;
    width: 100%;
    height: 100%;
    border: solid 4px ${(props) => props.theme.colors.grayscale.grayOne};
    border-bottom-color: ${(props) => props.theme.colors.primary.second};
    border-radius: 50%;
  }
`;
