import styled from 'styled-components';
import Icon from '../assets/images/icon-user.svg';

const User = ({name}) => {
    return(
        <Container>
            <img src={Icon} alt="Avatar" />
            <span>{name}</span>
        </Container>
    );
}

export default User;

const Container = styled.div`
    display: flex;
    align-items: center;

    img {
        display: blockl;
        width: 24px;
        height: 24px;
        margin-right: 16px;
    }

    span {
        color: ${props => props.theme.colors.yellow};
    }
`;