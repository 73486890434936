import styled from 'styled-components';

export const PeopleList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 50px;

    @media ${props => props.theme.breakpoints.laptop} {
        align-items: flex-start;
        padding-left: 0;
    }
`;

export const PeopleSingle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &:nth-child(1) {
        margin-right: 0;
    }
    &:nth-child(2) {
        margin-right: 15%;
    }
    &:nth-child(3) {
        margin-right: 30%;
    }
    &:nth-child(4) {
        margin-right: 45%;
    }

    @media ${props => props.theme.breakpoints.laptop} {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            margin-right: 0;
        }
    }

    img {
        display: block;
        width: 116px;
        margin-right: 16px;
    }

    span {
        color: ${props => props.theme.colors.yellow};
        font-size: 15px;
        line-height: 24px;
        white-space: nowrap;
    }
`;