import React from 'react';
import styled from 'styled-components';
import {VideoRoomContextType} from '../../../contexts/VideoRoomContext';
import {DuoItem} from '../Participant';

interface DuoProps {
  videoRoom: VideoRoomContextType;
}

export default function Duo({videoRoom}: DuoProps) {
  const local = videoRoom.room.localParticipant;
  const remote = videoRoom.room.remotes[0];

  return (
    <DuoContainer>
      <DuoContent>
        {remote && (
          <DuoItem
            participant={remote}
            adminMute={videoRoom.muteParticipant}
            adminKick={videoRoom.handleKick}
          />
        )}

        <DuoItem isLocalParticipant participant={local} />
      </DuoContent>
    </DuoContainer>
  );
}

export const DuoContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const DuoContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  @media ${(props) => props.theme.breakpoints.phone} {
    height: 100%;
    padding: 10px 0;
  }
`;
