import React from 'react';
import {useVideoRoom} from '../../../contexts/VideoRoomContext';
import Duo from './Duo';
import Group from './Group';

export default function ParticipantGrid(props: {hasSidebar: boolean}) {
  const videoRoom = useVideoRoom();

  if (videoRoom.room.remotes.length < 2 && !videoRoom.room.screenSharing) {
    return <Duo videoRoom={videoRoom} />;
  }

  return <Group hasSidebar={props.hasSidebar} videoRoom={videoRoom} />;
}
