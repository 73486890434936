import React from 'react';
import styled, {css} from 'styled-components';
import Avatar from './Avatar';
import Linkify from 'react-linkify';

export default function ChatMessage(props) {
  return (
    <Container mine={props.mine}>
      {/* <Avatar name={props.author} small /> */}
      <Content>
        <Info>
          {props.date} <strong>{props.mine ? 'Tu' : props.author}</strong>
        </Info>
        <Text mine={props.mine}>
          <Linkify componentDecorator={linkDecorator}>{props.text}</Linkify>
        </Text>
      </Content>
    </Container>
  );
}

const linkDecorator = (decoratedHref, decoratedText, key): React.Node => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={decoratedHref} key={key}>
      {decoratedText}
    </a>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-end;

  &:not(:last-of-type) {
    margin-bottom: 30px;
  }

  ${(props) =>
    props.mine &&
    css`
      justify-content: flex-end;
    `}
`;

const Content = styled.div`
  /* margin-left: 8px; */
`;

const Info = styled.span`
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
`;

const Text = styled.p`
  display: inline-block;
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  padding: 6px 12px;
  border-radius: 16px;
  border-top-left-radius: 4px;
  background-color: ${(props) =>
    props.mine
      ? props.theme.colors.elements.chatMine
      : props.theme.colors.primary.second};

  ${(props) =>
    props.mine &&
    css`
      border-top-left-radius: 16px;
      border-top-right-radius: 4px;
    `}

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  a {
    text-decoration: underline;
    color: currentColor;
    font-weight: 400;
  }
`;
