import React from 'react';
import styled from 'styled-components';

export default function Icon(props) {
  return <IconWrapper>{ICONS[props.type]}</IconWrapper>;
}

const IconWrapper = styled.i`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const ICONS = {
  fullScreenBlack: (
    <svg
      id="fullscreen"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_167" data-name="Group 167">
        <path
          id="Path_209"
          data-name="Path 209"
          d="M7.5,3H5A2,2,0,0,0,3,5V7.5"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_210"
          data-name="Path 210"
          d="M3,16.5V19a2,2,0,0,0,2,2H7.5"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_211"
          data-name="Path 211"
          d="M16.5,21H19a2,2,0,0,0,2-2V16.5"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_212"
          data-name="Path 212"
          d="M21,7.5V5a2,2,0,0,0-2-2H16.5"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <path id="Path_215" data-name="Path 215" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  fullScreen: (
    <svg
      id="fullscreen"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_167" data-name="Group 167">
        <path
          id="Path_209"
          data-name="Path 209"
          d="M7.5,3H5A2,2,0,0,0,3,5V7.5"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_210"
          data-name="Path 210"
          d="M3,16.5V19a2,2,0,0,0,2,2H7.5"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_211"
          data-name="Path 211"
          d="M16.5,21H19a2,2,0,0,0,2-2V16.5"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_212"
          data-name="Path 212"
          d="M21,7.5V5a2,2,0,0,0-2-2H16.5"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <path id="Path_215" data-name="Path 215" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  pipBlack: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="pip" transform="translate(-878 -189)">
        <g
          id="Rectangle_62"
          data-name="Rectangle 62"
          transform="translate(880 191)"
          fill="rgba(255,255,255,0)"
          stroke="#000"
          strokeWidth="1.5"
        >
          <rect width="20" height="20" rx="4" stroke="none" />
          <rect
            x="0.75"
            y="0.75"
            width="18.5"
            height="18.5"
            rx="3.25"
            fill="none"
          />
        </g>
        <rect
          id="Rectangle_63"
          data-name="Rectangle 63"
          width="9"
          height="5"
          rx="1"
          fill="#000"
          transform="translate(888 203)"
        />
        <path
          id="Path_225"
          data-name="Path 225"
          d="M0,0H24V24H0Z"
          transform="translate(878 189)"
          fill="none"
        />
      </g>
    </svg>
  ),
  pip: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="pip" transform="translate(-878 -189)">
        <g
          id="Rectangle_62"
          data-name="Rectangle 62"
          transform="translate(880 191)"
          fill="rgba(255,255,255,0)"
          stroke="#fff"
          strokeWidth="1.5"
        >
          <rect width="20" height="20" rx="4" stroke="none" />
          <rect
            x="0.75"
            y="0.75"
            width="18.5"
            height="18.5"
            rx="3.25"
            fill="none"
          />
        </g>
        <rect
          id="Rectangle_63"
          data-name="Rectangle 63"
          width="9"
          height="5"
          rx="1"
          fill="#fff"
          transform="translate(888 203)"
        />
        <path
          id="Path_225"
          data-name="Path 225"
          d="M0,0H24V24H0Z"
          transform="translate(878 189)"
          fill="none"
        />
      </g>
    </svg>
  ),
  pinBlack: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="pin" transform="translate(0 -0.014)">
        <path
          id="Path_226"
          data-name="Path 226"
          d="M0,.014H24v24H0Z"
          fill="none"
        />
        <path
          id="Path_228"
          data-name="Path 228"
          d="M21.708,13.007a.7.7,0,0,1,0,.995l-.615.615a.7.7,0,0,1-.636.192l-2.715-.544L15.5,16.5l.544,2.715a.706.706,0,0,1-.192.636l-.615.615a.7.7,0,0,1-.995,0L8.273,14.5a.7.7,0,0,1,0-.995l.615-.615a.7.7,0,0,1,.636-.192l2.715.544L14.477,11l-.544-2.715a.706.706,0,0,1,.192-.636l.615-.615a.7.7,0,0,1,.995,0l5.973,5.973"
          transform="translate(-2.239 -2.239)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_229"
          data-name="Path 229"
          d="M8.2,16.06l2.02-2.02"
          transform="translate(-2 2)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  ),
  pin: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="pin" transform="translate(0 -0.014)">
        <path
          id="Path_226"
          data-name="Path 226"
          d="M0,.014H24v24H0Z"
          fill="none"
        />
        <path
          id="Path_228"
          data-name="Path 228"
          d="M21.708,13.007a.7.7,0,0,1,0,.995l-.615.615a.7.7,0,0,1-.636.192l-2.715-.544L15.5,16.5l.544,2.715a.706.706,0,0,1-.192.636l-.615.615a.7.7,0,0,1-.995,0L8.273,14.5a.7.7,0,0,1,0-.995l.615-.615a.7.7,0,0,1,.636-.192l2.715.544L14.477,11l-.544-2.715a.706.706,0,0,1,.192-.636l.615-.615a.7.7,0,0,1,.995,0l5.973,5.973"
          transform="translate(-2.239 -2.239)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_229"
          data-name="Path 229"
          d="M8.2,16.06l2.02-2.02"
          transform="translate(-2 2)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  ),
  mic: (
    <svg
      id="Mic_Rec"
      data-name="Mic, Rec"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_135" data-name="Path 135" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_136"
        data-name="Path 136"
        d="M12,15h0a3,3,0,0,1-3-3V6a3,3,0,0,1,3-3h0a3,3,0,0,1,3,3v6A3,3,0,0,1,12,15Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_137"
        data-name="Path 137"
        d="M18,10v2a6,6,0,0,1-6,6h0a6,6,0,0,1-6-6V10"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_138"
        data-name="Path 138"
        d="M12,18v3"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_139"
        data-name="Path 139"
        d="M7.82,21h8.36"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  ),
  micMute: (
    <svg
      id="Microphone_Mute_Slash"
      data-name="Microphone, Mute, Slash"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_149" data-name="Group 149" transform="translate(4.25 2)">
        <path
          id="Path_160"
          data-name="Path 160"
          d="M12,19v3"
          transform="translate(-4.25 -2)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_161"
          data-name="Path 161"
          d="M9,22h6"
          transform="translate(-4.25 -2)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_162"
          data-name="Path 162"
          d="M8.36,4.36A3.995,3.995,0,0,1,16,6v6"
          transform="translate(-4.25 -2)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_163"
          data-name="Path 163"
          d="M4.25,4.25l15.5,15.5"
          transform="translate(-4.25 -2)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_164"
          data-name="Path 164"
          d="M8,8v4a4,4,0,0,0,6.828,2.828"
          transform="translate(-4.25 -2)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_165"
          data-name="Path 165"
          d="M19.1,11v.9a7.077,7.077,0,0,1-.525,2.674"
          transform="translate(-4.25 -2)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_166"
          data-name="Path 166"
          d="M4.9,11v.9a7.1,7.1,0,0,0,12.068,5.068"
          transform="translate(-4.25 -2)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <path id="Path_167" data-name="Path 167" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  micMuteBlack: (
    <svg
      id="Microphone_Mute_Slash"
      data-name="Microphone, Mute, Slash"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_149" data-name="Group 149" transform="translate(4.25 2)">
        <path
          id="Path_160"
          data-name="Path 160"
          d="M12,19v3"
          transform="translate(-4.25 -2)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_161"
          data-name="Path 161"
          d="M9,22h6"
          transform="translate(-4.25 -2)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_162"
          data-name="Path 162"
          d="M8.36,4.36A3.995,3.995,0,0,1,16,6v6"
          transform="translate(-4.25 -2)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_163"
          data-name="Path 163"
          d="M4.25,4.25l15.5,15.5"
          transform="translate(-4.25 -2)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_164"
          data-name="Path 164"
          d="M8,8v4a4,4,0,0,0,6.828,2.828"
          transform="translate(-4.25 -2)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_165"
          data-name="Path 165"
          d="M19.1,11v.9a7.077,7.077,0,0,1-.525,2.674"
          transform="translate(-4.25 -2)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_166"
          data-name="Path 166"
          d="M4.9,11v.9a7.1,7.1,0,0,0,12.068,5.068"
          transform="translate(-4.25 -2)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <path id="Path_167" data-name="Path 167" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  cam: (
    <svg
      id="Record_Videos_Camera"
      data-name="Record, Videos, Camera"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_7" data-name="Group 7" transform="translate(3 6.25)">
        <path
          id="Path_26"
          data-name="Path 26"
          d="M13.125,17.75H5.25A2.25,2.25,0,0,1,3,15.5v-7A2.25,2.25,0,0,1,5.25,6.25h7.875a2.25,2.25,0,0,1,2.25,2.25v7A2.25,2.25,0,0,1,13.125,17.75Z"
          transform="translate(-3 -6.25)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_27"
          data-name="Path 27"
          d="M15.375,13.1l3.8,3.054A1.124,1.124,0,0,0,21,15.275V8.725a1.124,1.124,0,0,0-1.83-.876l-3.8,3.054"
          transform="translate(-3 -6.25)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <path id="Path_28" data-name="Path 28" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  camOff: (
    <svg
      id="Video_Movies"
      data-name="Video, Movies"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_148" data-name="Group 148" transform="translate(3 3.5)">
        <path
          id="Path_155"
          data-name="Path 155"
          d="M3.24,3.5l11.9,17"
          transform="translate(-3 -3.5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_156"
          data-name="Path 156"
          d="M13.212,17.75H5.25A2.25,2.25,0,0,1,3,15.5v-7"
          transform="translate(-3 -3.5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_157"
          data-name="Path 157"
          d="M5.162,6.25h7.963a2.25,2.25,0,0,1,2.25,2.25v7"
          transform="translate(-3 -3.5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_158"
          data-name="Path 158"
          d="M15.375,13.1l3.8,3.054A1.124,1.124,0,0,0,21,15.275V8.725a1.124,1.124,0,0,0-1.83-.876l-3.8,3.054"
          transform="translate(-3 -3.5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <path id="Path_159" data-name="Path 159" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  esc: (
    <svg
      id="esc"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_8" data-name="Group 8" transform="translate(2 8)">
        <path
          id="Path_31"
          data-name="Path 31"
          fill="#fff"
          d="M18.434,12H20a2,2,0,0,0,2-2V8.986A3.138,3.138,0,0,0,20.6,6.4,15.43,15.43,0,0,0,12,4,15.422,15.422,0,0,0,3.4,6.4,3.137,3.137,0,0,0,2,8.986V10a2,2,0,0,0,2,2H5.566a1.5,1.5,0,0,0,1.5-1.5V9.137a.749.749,0,0,1,.467-.7,12.722,12.722,0,0,1,8.934,0,.749.749,0,0,1,.467.7V10.5A1.5,1.5,0,0,0,18.434,12Z"
          transform="translate(-2 -4)"
        />
      </g>
      <path id="Path_32" data-name="Path 32" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  shareScreen: (
    <svg
      id="shareScreen"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_74" data-name="Group 74" transform="translate(2.547 4.815)">
        <path
          id="Path_132"
          data-name="Path 132"
          d="M24.016,19.652H8.891A1.891,1.891,0,0,1,7,17.761v-7.3A1.891,1.891,0,0,1,8.891,8.569H24.016a1.891,1.891,0,0,1,1.891,1.891v7.3a1.892,1.892,0,0,1-1.891,1.893Z"
          transform="translate(-7 -8.569)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.528"
        />
        <path
          id="Path_133"
          data-name="Path 133"
          d="M9.5,17h9.453"
          transform="translate(-4.773 -1.06)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.394"
        />
      </g>
      <path id="Path_134" data-name="Path 134" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  shareScreenStop: (
    <svg
      id="shareScreen"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_74" data-name="Group 74" transform="translate(2.547 4.815)">
        <path
          id="Path_132"
          data-name="Path 132"
          d="M24.016,19.652H8.891A1.891,1.891,0,0,1,7,17.761v-7.3A1.891,1.891,0,0,1,8.891,8.569H24.016a1.891,1.891,0,0,1,1.891,1.891v7.3a1.892,1.892,0,0,1-1.891,1.893Z"
          transform="translate(-7 -8.569)"
          fill="none"
          stroke="#FF6060"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.528"
        />
        <path
          id="Path_133"
          data-name="Path 133"
          d="M9.5,17h9.453"
          transform="translate(-4.773 -1.06)"
          fill="none"
          stroke="#FF6060"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.394"
        />
      </g>
      <path id="Path_134" data-name="Path 134" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  people: (
    <svg
      id="people"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_13" data-name="Group 13" transform="translate(2 5)">
        <path
          id="Path_33"
          data-name="Path 33"
          d="M2,19H2a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4h0"
          transform="translate(-2 -5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_34"
          data-name="Path 34"
          d="M10.475,6.025a3.5,3.5,0,1,1-4.95,0,3.5,3.5,0,0,1,4.95,0"
          transform="translate(-2 -5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_35"
          data-name="Path 35"
          d="M16,14h3a3,3,0,0,1,3,3h0"
          transform="translate(-2 -5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_36"
          data-name="Path 36"
          d="M19.268,6.732a2.5,2.5,0,1,1-3.536,0,2.5,2.5,0,0,1,3.536,0"
          transform="translate(-2 -5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <path id="Path_37" data-name="Path 37" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  link: (
    <svg
      id="link"
      xmlns="http://www.w3.org/2fff/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_168" data-name="Path 168" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_169"
        data-name="Path 169"
        d="M15.456,8.543a6.267,6.267,0,1,0-.268,2.644"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_170"
        data-name="Path 170"
        d="M8.544,15.457a6.267,6.267,0,1,0,.268-2.644"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  ),
  questions: (
    <svg
      id="questions"
      xmlns="http://www.w3.org/2fff/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_171" data-name="Path 171" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_173"
        data-name="Path 173"
        d="M13.255,16.649v-.407a3.049,3.049,0,0,1,1.645-2.6,2.989,2.989,0,0,0,1.61-2.55,3.255,3.255,0,0,0-6.51,0"
        transform="translate(-1.255 -2.719)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_174"
        data-name="Path 174"
        d="M12.155,16a.407.407,0,1,0,.409.407A.4.4,0,0,0,12.155,16"
        transform="translate(-0.157 2.405)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  ),
  close: (
    <svg
      id="Delete_Disabled.1"
      data-name="Delete, Disabled.1"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_151" data-name="Path 151" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_152"
        data-name="Path 152"
        d="M14.83,9.17,9.17,14.83"
        fill="none"
        stroke="#9a9a9a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_153"
        data-name="Path 153"
        d="M14.83,14.83,9.17,9.17"
        fill="none"
        stroke="#9a9a9a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_154"
        data-name="Path 154"
        d="M12,21h0a9,9,0,0,1-9-9H3a9,9,0,0,1,9-9h0a9,9,0,0,1,9,9h0A9,9,0,0,1,12,21Z"
        fill="none"
        stroke="#9a9a9a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  ),
  copy: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Allow_Copying.1"
        data-name="Allow Copying.1"
        transform="translate(-7.258 -7.258)"
      >
        <g
          id="Group_93"
          data-name="Group 93"
          transform="translate(11.168 10.43)"
        >
          <path
            id="Path_141"
            data-name="Path 141"
            d="M9.159,22.551h7.768a1.759,1.759,0,0,0,1.759-1.759v-9.3A1.759,1.759,0,0,0,16.927,9.73H9.159A1.759,1.759,0,0,0,7.4,11.489v9.305A1.757,1.757,0,0,0,9.159,22.551Z"
            transform="translate(-7.4 -4.894)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_142"
            data-name="Path 142"
            d="M10.15,11.816V8.739A1.759,1.759,0,0,1,11.909,6.98h7.826a1.759,1.759,0,0,1,1.759,1.759v9.305A1.759,1.759,0,0,1,19.735,19.8H16.6"
            transform="translate(-5.314 -6.98)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
        <path
          id="Path_143"
          data-name="Path 143"
          d="M0,0H24V24H0Z"
          transform="translate(7.258 7.258)"
          fill="none"
        />
      </g>
    </svg>
  ),
  hand: (
    <svg
      id="hand"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_175" data-name="Path 175" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_176"
        data-name="Path 176"
        d="M14,12V3.5A1.5,1.5,0,0,0,12.5,2h0A1.5,1.5,0,0,0,11,3.5V12"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_177"
        data-name="Path 177"
        d="M14,5.5A1.5,1.5,0,0,1,15.5,4h0A1.5,1.5,0,0,1,17,5.5V12"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_178"
        data-name="Path 178"
        d="M11,5.5A1.5,1.5,0,0,0,9.5,4h0A1.5,1.5,0,0,0,8,5.5V14L6.1,12.1a1.554,1.554,0,0,0-2.2,0h0a1.554,1.554,0,0,0-.194,1.961l3.512,5.268A6,6,0,0,0,12.211,22H14a6,6,0,0,0,6-6V7.5A1.5,1.5,0,0,0,18.5,6h0A1.5,1.5,0,0,0,17,7.5"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path id="Path_179" data-name="Path 179" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  handWhite: (
    <svg
      id="hand"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_175" data-name="Path 175" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_176"
        data-name="Path 176"
        d="M14,12V3.5A1.5,1.5,0,0,0,12.5,2h0A1.5,1.5,0,0,0,11,3.5V12"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_177"
        data-name="Path 177"
        d="M14,5.5A1.5,1.5,0,0,1,15.5,4h0A1.5,1.5,0,0,1,17,5.5V12"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_178"
        data-name="Path 178"
        d="M11,5.5A1.5,1.5,0,0,0,9.5,4h0A1.5,1.5,0,0,0,8,5.5V14L6.1,12.1a1.554,1.554,0,0,0-2.2,0h0a1.554,1.554,0,0,0-.194,1.961l3.512,5.268A6,6,0,0,0,12.211,22H14a6,6,0,0,0,6-6V7.5A1.5,1.5,0,0,0,18.5,6h0A1.5,1.5,0,0,0,17,7.5"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path id="Path_179" data-name="Path 179" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  handDecline: (
    <svg
      id="hand"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_175" data-name="Path 175" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_176"
        data-name="Path 176"
        d="M14,12V3.5A1.5,1.5,0,0,0,12.5,2h0A1.5,1.5,0,0,0,11,3.5V12"
        fill="none"
        stroke="#FF6060"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_177"
        data-name="Path 177"
        d="M14,5.5A1.5,1.5,0,0,1,15.5,4h0A1.5,1.5,0,0,1,17,5.5V12"
        fill="none"
        stroke="#FF6060"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_178"
        data-name="Path 178"
        d="M11,5.5A1.5,1.5,0,0,0,9.5,4h0A1.5,1.5,0,0,0,8,5.5V14L6.1,12.1a1.554,1.554,0,0,0-2.2,0h0a1.554,1.554,0,0,0-.194,1.961l3.512,5.268A6,6,0,0,0,12.211,22H14a6,6,0,0,0,6-6V7.5A1.5,1.5,0,0,0,18.5,6h0A1.5,1.5,0,0,0,17,7.5"
        fill="none"
        stroke="#FF6060"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path id="Path_179" data-name="Path 179" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  options: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="options" transform="translate(-516 -247)">
        <rect
          id="Rectangle_50"
          data-name="Rectangle 50"
          width="24"
          height="24"
          transform="translate(516 247)"
          fill="rgba(255,255,255,0)"
        />
        <g
          id="Group_21"
          data-name="Group 21"
          transform="translate(526 250.044)"
        >
          <circle
            id="Ellipse_2"
            data-name="Ellipse 2"
            cx="2"
            cy="2"
            r="2"
            transform="translate(0 -0.044)"
            fill="#fff"
          />
          <circle
            id="Ellipse_3"
            data-name="Ellipse 3"
            cx="2"
            cy="2"
            r="2"
            transform="translate(0 6.956)"
            fill="#fff"
          />
          <circle
            id="Ellipse_4"
            data-name="Ellipse 4"
            cx="2"
            cy="2"
            r="2"
            transform="translate(0 13.956)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  ),
  chat: (
    <svg
      id="chat"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_150" data-name="Group 150">
        <path
          id="Path_188"
          data-name="Path 188"
          d="M21,11.291c0,4.539-4.048,8.182-9,8.182a9.9,9.9,0,0,1-1.869-.179"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_189"
          data-name="Path 189"
          d="M6.6,17.826A7.934,7.934,0,0,1,3,11.291"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_190"
          data-name="Path 190"
          d="M6.6,17.826c0,.854,0,2.006,0,3.215"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_191"
          data-name="Path 191"
          d="M3,11.291C3,6.752,7.048,3.11,12,3.11s9,3.643,9,8.182"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_192"
          data-name="Path 192"
          d="M10.13,19.29,6.6,21.04"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_193"
          data-name="Path 193"
          d="M12.177,11.323a.25.25,0,1,1-.354,0,.25.25,0,0,1,.354,0"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_194"
          data-name="Path 194"
          d="M16.177,11.323a.25.25,0,1,1-.354,0,.25.25,0,0,1,.354,0"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_195"
          data-name="Path 195"
          d="M8.177,11.323a.25.25,0,1,1-.354,0,.25.25,0,0,1,.354,0"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <path id="Path_196" data-name="Path 196" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  chatOff: (
    <svg
      id="chat"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_150" data-name="Group 150">
        <path
          id="Path_188"
          data-name="Path 188"
          d="M21,11.291c0,4.539-4.048,8.182-9,8.182a9.9,9.9,0,0,1-1.869-.179"
          fill="none"
          stroke="#FF6060"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_189"
          data-name="Path 189"
          d="M6.6,17.826A7.934,7.934,0,0,1,3,11.291"
          fill="none"
          stroke="#FF6060"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_190"
          data-name="Path 190"
          d="M6.6,17.826c0,.854,0,2.006,0,3.215"
          fill="none"
          stroke="#FF6060"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_191"
          data-name="Path 191"
          d="M3,11.291C3,6.752,7.048,3.11,12,3.11s9,3.643,9,8.182"
          fill="none"
          stroke="#FF6060"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_192"
          data-name="Path 192"
          d="M10.13,19.29,6.6,21.04"
          fill="none"
          stroke="#FF6060"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_193"
          data-name="Path 193"
          d="M12.177,11.323a.25.25,0,1,1-.354,0,.25.25,0,0,1,.354,0"
          fill="none"
          stroke="#FF6060"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_194"
          data-name="Path 194"
          d="M16.177,11.323a.25.25,0,1,1-.354,0,.25.25,0,0,1,.354,0"
          fill="none"
          stroke="#FF6060"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_195"
          data-name="Path 195"
          d="M8.177,11.323a.25.25,0,1,1-.354,0,.25.25,0,0,1,.354,0"
          fill="none"
          stroke="#FF6060"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <path id="Path_196" data-name="Path 196" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  send: (
    <svg
      id="send"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_124" data-name="Path 124" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_125"
        data-name="Path 125"
        d="M5.323,19.878l14.429-6.7a1.3,1.3,0,0,0,0-2.36L5.323,4.122A1.29,1.29,0,0,0,3.58,5.743L5.813,12,3.58,18.258a1.29,1.29,0,0,0,1.743,1.62Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_126"
        data-name="Path 126"
        d="M5.81,12H20.5"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  ),
  record: (
    <svg
      id="record"
      xmlns="http://www.w3.org/2ffffff/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_603" data-name="Group 603">
        <path
          id="Path_237"
          data-name="Path 237"
          d="M18.364,5.636a9,9,0,1,1-12.728,0,9,9,0,0,1,12.728,0"
          fill="none"
          stroke="#ffffff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_238"
          data-name="Path 238"
          d="M14.828,9.172a4,4,0,1,1-5.657,0,4,4,0,0,1,5.657,0"
          stroke="#ffffff"
          fill="#ffffff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <path id="Path_239" data-name="Path 239" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  recordBlack: (
    <svg
      id="record"
      xmlns="http://www.w3.org/2ffffff/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_603" data-name="Group 603">
        <path
          id="Path_237"
          data-name="Path 237"
          d="M18.364,5.636a9,9,0,1,1-12.728,0,9,9,0,0,1,12.728,0"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_238"
          data-name="Path 238"
          d="M14.828,9.172a4,4,0,1,1-5.657,0,4,4,0,0,1,5.657,0"
          stroke="#000000"
          fill="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <path id="Path_239" data-name="Path 239" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  recordOff: (
    <svg
      id="record"
      xmlns="http://www.w3.org/2ffffff/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_603" data-name="Group 603">
        <path
          id="Path_237"
          data-name="Path 237"
          d="M18.364,5.636a9,9,0,1,1-12.728,0,9,9,0,0,1,12.728,0"
          fill="none"
          stroke="#FF6060"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_238"
          data-name="Path 238"
          d="M14.828,9.172a4,4,0,1,1-5.657,0,4,4,0,0,1,5.657,0"
          stroke="#FF6060"
          fill="#FF6060"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <path id="Path_239" data-name="Path 239" d="M0,0H24V24H0Z" fill="none" />
    </svg>
  ),
  request: (
    <svg
      id="request"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_261" data-name="Path 261" d="M0,0H24V24H0Z" fill="none" />
      <path id="Path_262" data-name="Path 262" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_263"
        data-name="Path 263"
        d="M20,20V5.778A1.778,1.778,0,0,0,18.222,4H5.778A1.778,1.778,0,0,0,4,5.778V16.445a1.778,1.778,0,0,0,1.778,1.778H16.667Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
      />
      <path
        id="Path_264"
        data-name="Path 264"
        d="M12,14.791a.174.174,0,1,0,.175.174A.173.173,0,0,0,12,14.791"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_265"
        data-name="Path 265"
        d="M12,12.076v-.24a1.8,1.8,0,0,1,.974-1.54,1.767,1.767,0,0,0,.953-1.509,1.927,1.927,0,0,0-3.853,0"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  ),
  requestOff: (
    <svg
      id="request"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_261" data-name="Path 261" d="M0,0H24V24H0Z" fill="none" />
      <path id="Path_262" data-name="Path 262" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_263"
        data-name="Path 263"
        d="M20,20V5.778A1.778,1.778,0,0,0,18.222,4H5.778A1.778,1.778,0,0,0,4,5.778V16.445a1.778,1.778,0,0,0,1.778,1.778H16.667Z"
        fill="none"
        stroke="#FF6060"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
      />
      <path
        id="Path_264"
        data-name="Path 264"
        d="M12,14.791a.174.174,0,1,0,.175.174A.173.173,0,0,0,12,14.791"
        fill="none"
        stroke="#FF6060"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_265"
        data-name="Path 265"
        d="M12,12.076v-.24a1.8,1.8,0,0,1,.974-1.54,1.767,1.767,0,0,0,.953-1.509,1.927,1.927,0,0,0-3.853,0"
        fill="none"
        stroke="#FF6060"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  ),
  a: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="a" transform="translate(-749 -189)">
        <path
          id="Path_280"
          data-name="Path 280"
          d="M0,0H24V24H0Z"
          transform="translate(749 189)"
          fill="rgba(255,255,255,0)"
        />
        <path
          id="Path_279"
          data-name="Path 279"
          d="M-4.448.08a.689.689,0,0,1-.52-.208.71.71,0,0,1-.2-.512.871.871,0,0,1,.08-.32l4.08-9.632a1.227,1.227,0,0,1,.36-.472.886.886,0,0,1,.568-.184H.08a.886.886,0,0,1,.568.184,1.227,1.227,0,0,1,.36.472L5.088-.96a.871.871,0,0,1,.08.32.71.71,0,0,1-.2.512.689.689,0,0,1-.52.208A.731.731,0,0,1,4.04-.04a.765.765,0,0,1-.28-.328l-1.04-2.5H-2.72l-1.04,2.5a.765.765,0,0,1-.28.328A.731.731,0,0,1-4.448.08ZM-2.16-4.192H2.16L0-9.392Z"
          transform="translate(761 206)"
          stroke="#fff"
          fill="#fff"
          strokeWidth="0.5"
        />
      </g>
    </svg>
  ),
  b: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="b" transform="translate(-781 -189)">
        <path
          id="Path_281"
          data-name="Path 281"
          d="M0,0H24V24H0Z"
          transform="translate(781 189)"
          fill="rgba(255,255,255,0)"
        />
        <path
          id="Path_278"
          data-name="Path 278"
          d="M-3.272,0a.7.7,0,0,1-.512-.208A.7.7,0,0,1-3.992-.72v-9.712a.723.723,0,0,1,.208-.52.683.683,0,0,1,.512-.216H.5a4.012,4.012,0,0,1,2.648.784,2.613,2.613,0,0,1,.936,2.112,2.59,2.59,0,0,1-.44,1.544,2.708,2.708,0,0,1-1.128.92A3.538,3.538,0,0,1,4.1-4.832a2.413,2.413,0,0,1,.616,1.7,2.844,2.844,0,0,1-.992,2.3A4.807,4.807,0,0,1,.6,0ZM.12-6.336a3.175,3.175,0,0,0,2-.48A1.653,1.653,0,0,0,2.7-8.16a1.41,1.41,0,0,0-.616-1.2A2.819,2.819,0,0,0,.392-9.808H-2.536v3.472ZM.456-1.36a3.8,3.8,0,0,0,2.2-.48A1.593,1.593,0,0,0,3.3-3.2Q3.3-5.024.472-5.024H-2.536V-1.36Z"
          transform="translate(793 206)"
          stroke="#fff"
          fill="#fff"
          strokeWidth="0.5"
        />
      </g>
    </svg>
  ),
  c: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="c" transform="translate(-813 -189)">
        <path
          id="Path_282"
          data-name="Path 282"
          d="M0,0H24V24H0Z"
          transform="translate(813 189)"
          fill="rgba(255,255,255,0)"
        />
        <path
          id="Path_277"
          data-name="Path 277"
          d="M.92.16a5.884,5.884,0,0,1-2.872-.7,5.012,5.012,0,0,1-2-2A6.151,6.151,0,0,1-4.68-5.584a6.151,6.151,0,0,1,.728-3.048,5.012,5.012,0,0,1,2-2,5.884,5.884,0,0,1,2.872-.7,6.785,6.785,0,0,1,1.944.256,8.272,8.272,0,0,1,1.7.736.644.644,0,0,1,.352.608.6.6,0,0,1-.176.424.613.613,0,0,1-.464.184.635.635,0,0,1-.288-.064,7.321,7.321,0,0,0-1.376-.56,5.667,5.667,0,0,0-1.552-.192,4.556,4.556,0,0,0-2.344.568A3.647,3.647,0,0,0-2.736-7.816a4.957,4.957,0,0,0-.488,2.232,4.957,4.957,0,0,0,.488,2.232A3.647,3.647,0,0,0-1.28-1.8a4.556,4.556,0,0,0,2.344.568,5.667,5.667,0,0,0,1.552-.192,7.321,7.321,0,0,0,1.376-.56.635.635,0,0,1,.288-.064.627.627,0,0,1,.464.176.587.587,0,0,1,.176.432.644.644,0,0,1-.352.608,8.272,8.272,0,0,1-1.7.736A6.785,6.785,0,0,1,.92.16Z"
          transform="translate(825 206)"
          stroke="#fff"
          fill="#fff"
          strokeWidth="0.5"
        />
      </g>
    </svg>
  ),
  abc: (
    <svg
      id="abc"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_285" data-name="Path 285" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_286"
        data-name="Path 286"
        d="M9.328,14.172a4,4,0,1,1-5.657,0,4,4,0,0,1,5.657,0"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_287"
        data-name="Path 287"
        d="M20.328,14.172a4,4,0,1,1-5.657,0,4,4,0,0,1,5.657,0"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_288"
        data-name="Path 288"
        d="M14.828,4.172a4,4,0,1,1-5.657,0,4,4,0,0,1,5.657,0"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  ),
  abcOff: (
    <svg
      id="abc"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_285" data-name="Path 285" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_286"
        data-name="Path 286"
        d="M9.328,14.172a4,4,0,1,1-5.657,0,4,4,0,0,1,5.657,0"
        fill="none"
        stroke="#FF6060"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_287"
        data-name="Path 287"
        d="M20.328,14.172a4,4,0,1,1-5.657,0,4,4,0,0,1,5.657,0"
        fill="none"
        stroke="#FF6060"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_288"
        data-name="Path 288"
        d="M14.828,4.172a4,4,0,1,1-5.657,0,4,4,0,0,1,5.657,0"
        fill="none"
        stroke="#FF6060"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  ),
};
