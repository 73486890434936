import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import type {Room} from 'twilio-video';
import {useLocalTracks} from './LocalTracksContext';
import {RemoteParticipant, VideoRoom} from '../types/video-room';
import {extractDataFromTwilio} from '../utils/video-room-utils';
import TwilioWrapper from '../utils/twilio-wrapper';

export interface VideoRoomContextType {
  enter: (token: string, roomId: string) => Promise<void>;
  quit: () => Promise<void>;
  openMic: (deviceId?: string) => Promise<void>;
  closeMic: () => Promise<void>;
  openCamera: (deviceId?: string) => Promise<void>;
  closeCamera: () => Promise<void>;
  shareScreen: () => Promise<void>;
  stopSharing: () => Promise<void>;
  muteParticipant: (p: RemoteParticipant) => void | null;
  handleKick: (p: RemoteParticipant) => void | null;
  room: VideoRoom;
  roomId: string;
  twilioWrapper: TwilioWrapper;
  twilioRoomRef: React.MutableRefObject<Room>;
  initialized: boolean;
  isAdmin: boolean;
  enabledScreen: boolean;
  enabledMic: boolean;
  enabledCamera: boolean;
}

const VideoRoomContext = createContext<VideoRoomContextType>(null);

const VideoRoomProvider = ({
  isAdmin,
  children,
}: PropsWithChildren<{isAdmin: boolean}>) => {
  const wrapper = useRef<TwilioWrapper>(new TwilioWrapper()).current;
  const room = useRef<Room>(null);
  const localTracks = useLocalTracks();

  const [videoRoom, setVideoRoom] = useState<VideoRoom>(null);

  const updateVideoRoom = useCallback(() => {
    if (!room.current) {
      return;
    }

    setVideoRoom(extractDataFromTwilio(room.current, {}));
  }, []);

  const enter = useCallback(
    async (token: string, roomId: string) => {
      await wrapper.connect(
        token,
        localTracks.tracks.audio,
        localTracks.tracks.video
      );

      room.current = wrapper.room;
      updateVideoRoom();
    },
    [localTracks.tracks.audio, localTracks.tracks.video]
  );

  const quit = useCallback(async () => {
    if (!room.current) {
      return;
    }

    await localTracks.releaseVideoTrack();
    await localTracks.releaseAudioTrack();
    await localTracks.releaseScreenTrack();

    room.current.disconnect();
  }, [room, localTracks]);

  const closeMic = useCallback(async () => {
    if (!videoRoom) {
      return;
    }

    const audioTrack = videoRoom.localParticipant.audio?.track;

    if (audioTrack) {
      audioTrack.disable();
    }
    updateVideoRoom();
  }, [updateVideoRoom, videoRoom]);

  const openMic = useCallback(
    async (deviceId?: string) => {
      if (!videoRoom) {
        return;
      }

      // TODO: handle live switch of microphone

      const audioTrack = videoRoom.localParticipant.audio?.track;

      if (audioTrack) {
        audioTrack.enable();
      } else {
        console.log('ask');
        const track = await localTracks.getAudioTrack(deviceId);
        await room.current.localParticipant.publishTrack(track);
      }

      updateVideoRoom();
    },
    [updateVideoRoom, videoRoom, localTracks]
  );

  const openCamera = useCallback(
    async (deviceId?: string) => {
      if (!videoRoom) {
        return;
      }

      const oldTrack = videoRoom.localParticipant.video?.track;
      if (oldTrack) {
        room.current.localParticipant.unpublishTrack(oldTrack);
      }

      const track = await localTracks.getVideoTrack(deviceId);
      await room.current.localParticipant.publishTrack(track);

      updateVideoRoom();
    },
    [updateVideoRoom, videoRoom, localTracks]
  );

  const closeCamera = useCallback(async () => {
    if (!videoRoom) {
      return;
    }

    const track = videoRoom.localParticipant.video?.track;

    if (track) {
      await track.stop();
      await room.current.localParticipant.unpublishTrack(track);
    }

    updateVideoRoom();
  }, [updateVideoRoom, videoRoom]);

  const shareScreen = useCallback(async () => {
    const screenTrack = await localTracks.getScreenTrack();

    screenTrack.on('stopped', async () => {
      await room.current.localParticipant.unpublishTrack(screenTrack);
      updateVideoRoom();
    });

    await room.current.localParticipant.publishTrack(screenTrack);

    updateVideoRoom();
  }, [updateVideoRoom, localTracks]);

  const stopSharing = useCallback(async () => {
    await localTracks.releaseScreenTrack();
    updateVideoRoom();
  }, [updateVideoRoom, localTracks]);

  const muteParticipant = useMemo(() => {
    if (isAdmin) {
      return (p: RemoteParticipant) => {
        wrapper.sendCommand({type: 'mute', payload: p.identity});
      };
    }

    return null;
  }, [wrapper, isAdmin]);

  useEffect(() => {
    updateVideoRoom();
    wrapper.addListener('update', updateVideoRoom);

    return () => wrapper.removeListener('update', updateVideoRoom);
  }, [wrapper, updateVideoRoom]);

  useEffect(() => {
    wrapper.addListener('mute', function (from, payload) {
      if (room.current.localParticipant.identity === payload) {
        closeMic();
      }
    });
  }, [wrapper, room, closeMic]);

  const contextValue: VideoRoomContextType = {
    enter,
    quit,
    closeMic,
    openMic,
    openCamera,
    closeCamera,
    shareScreen,
    stopSharing,
    muteParticipant,
    room: videoRoom,
    twilioWrapper: wrapper,
    twilioRoomRef: room,
    initialized: !!videoRoom,
    isAdmin: isAdmin,
    enabledScreen: !!localTracks.tracks.screen,
    enabledMic: !!localTracks.tracks.audio?.isEnabled,
    enabledCamera: !!localTracks.tracks.video,
  };

  // useEffect(() => {
  //   if (videoRoom) {
  //     setInterval(async () => console.log(await room.current.getStats()), 2000);
  //   }
  // }, [videoRoom]);

  return (
    <VideoRoomContext.Provider value={contextValue}>
      {children}
    </VideoRoomContext.Provider>
  );
};

const useVideoRoom: () => VideoRoomContextType = () =>
  useContext(VideoRoomContext);

export {VideoRoomContext, VideoRoomProvider, useVideoRoom};
