import React from 'react';
import styled from 'styled-components';
import Icon from '../Icons';

export default function SidebarRight(props) {
  return (
    <Wrapper open={props.open}>
      <Container>
        <Header>
          <Title>{props.sidebarContent}</Title>
          <Closer onClick={() => props.setSidebarContent('')}>
            <Icon type="close" />
          </Closer>
        </Header>
        <Body>{props.children}</Body>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  width: 300px;
  top: 0;
  right: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  transform: translateX(100%);
  z-index: 8;

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 100%;
  }

  ${(props) =>
    props.open &&
    `
      transform: translateX(0);
    `}
`;

const Container = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
`;

const Header = styled.div`
  height: 60px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.grayscale.white};
  border-left: solid 1px ${(props) => props.theme.colors.grayscale.grayOne};
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${(props) => props.theme.colors.primary.second};
`;

const Closer = styled.button`
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  padding: 0;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color ease 0.3s;
  flex-shrink: 0;

  &:hover {
    background-color: ${(props) => props.theme.colors.grayscale.grayOne};
  }
`;

const Body = styled.div`
  padding: 0;
  overflow-y: auto;
  height: calc(100% - 60px);
`;
