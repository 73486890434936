import React from 'react';
import styled from 'styled-components';
import Participant from './Participant';
import type {ParticipantProps} from './types';

type DuoItemProps = ParticipantProps;

export default function DuoItem(props: DuoItemProps) {
  const Wrapper = props.isLocalParticipant ? MineContainer : Container;

  return (
    <Wrapper>
      <Participant smallBox={props.isLocalParticipant} {...props} />
    </Wrapper>
  );
}

const Container = styled.div`
  position: relative;
  flex: 0 0 65%;
  z-index: 0;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 25px ${(props) => props.theme.colors.shadow};
  @media ${(props) => props.theme.breakpoints.phone} {
    flex: 0 0 80%;
  }
  @media ${(props) => props.theme.breakpoints.tablet} {
    flex: 0 0 100%;
    height: 100%;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
    @media ${(props) => props.theme.breakpoints.phone} {
      padding-bottom: 130%;
    }
  }

  > div:first-child {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  video {
    width: 100%;
    height: 100%;
    @media ${(props) => props.theme.breakpoints.phone} {
      height: 100%;
      object-fit: cover;
    }
  }
`;

const MineContainer = styled(Container)`
  flex: 0 0 20%;
  margin-left: 2%;
  overflow: visible;

  @media ${(props) => props.theme.breakpoints.phone} {
    flex: 0 0 30%;
    margin-left: -10%;
  }
  @media ${(props) => props.theme.breakpoints.tablet} {
    flex: 0 0 34%;
    margin-left: -34%;
    height: auto;
  }

  &:first-child {
    flex: 0 0 65%;
    @media ${(props) => props.theme.breakpoints.tablet} {
      flex: 0 0 100%;
      margin-left: auto;
      height: 100%;
    }
  }
`;
