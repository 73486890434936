import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {db} from '../../utils/firebase';
import {Loading} from '../Loading';
import Icon from '../Icons';

export default function SendRequest({user, visible, setSendRequest}) {
  const [status, setStatus] = useState('');

  const submit = async (e) => {
    e.preventDefault();
    setStatus('loading');

    await db.ref('domande').push({
      date: new Date().toString(),
      user: user.name,
      userObj: user,
      message: e.target.message.value,
    });

    setStatus('done');

    setTimeout(() => {
      setSendRequest(false);
      setStatus('');
    }, 1500);
  };

  const content = () => {
    if (status === '') {
      return (
        <>
          <h3>Invia una domanda</h3>
          <Form action="#" onSubmit={submit}>
            <input
              type="text"
              name="message"
              placeholder="Invia una domanda alla redazione"
              required
            />
            <button>Invia</button>
          </Form>
        </>
      );
    }

    if (status === 'loading') {
      return (
        <LoadingContainer>
          <Loading width="24px" height="24px" />
        </LoadingContainer>
      );
    }

    if (status === 'done') {
      return <Success>Domanda inviata</Success>;
    }
  };

  return (
    <Container active={visible}>
      <Closer onClick={() => setSendRequest(false)} title="Chiudi">
        <Icon type="close" />
      </Closer>
      {content()}
    </Container>
  );
}

const Container = styled.div`
  width: calc(100% - 60px);
  max-width: 500px;
  position: absolute;
  transform: translateX(-200%);
  transition: transform ease 0.6s;
  transform-origin: left bottom;
  left: 20px;
  bottom: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 4px;
  z-index: 1;
  min-height: 64px;

  ${(props) =>
    props.active &&
    css`
      transform: translateX(0);
    `}

  @media ${(props) => props.theme.breakpoints.tablet} {
    top: 100px;
    bottom: initial;
  }

  h3 {
    margin: 0;
    margin-bottom: 16px;
    padding-right: 50px;
    font-size: 20px;
    color: ${(props) => props.theme.colors.elements.cta};
  }
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  input {
    flex: 1;
    flex: 1 1 0;
    margin-right: 8px;
    padding: 0 8px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    border-radius: 4px;
    border: solid 1px ${(props) => props.theme.colors.grayscale.grayOne};

    &:focus {
      border-color: ${(props) => props.theme.colors.elements.cta};
      box-shadow: 0 0 10px 3px rgba(12, 156, 217, 0.3);
    }

    @media ${(props) => props.theme.breakpoints.tablet} {
      margin-bottom: 12px;
      margin-right: 0;
      line-height: 50px;
    }
  }

  button {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.elements.cta};
    color: white;
    border: none;
    transition: background-color ease 0.3s;
    font-size: 16px;
    font-size: 700;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      background-color: ${(props) => props.theme.colors.elements.ctaHover};
    }

    @media ${(props) => props.theme.breakpoints.tablet} {
      width: 100%;
      height: 50px;
      line-height: 50px;
    }
  }
`;

const LoadingContainer = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
`;

const Success = styled.span`
  color: ${(props) => props.theme.colors.secondary.success};
  font-weight: 700;
`;

const Closer = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  padding: 0;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color ease 0.3s;
  flex-shrink: 0;

  &:hover {
    background-color: ${(props) => props.theme.colors.grayscale.grayOne};
  }
`;
