import {keyframes} from 'styled-components';

export const spin = keyframes`
    from {
        transform: rotate(0);
    }
    
    to {
        transform: rotate(360deg);
    }
`;

export const slideInRight = keyframes`
    from {
        transform: translateX(20px);
        opacity: 0;
    }
    
    to {
        transform: translateX(0);
        opacity: 1;
    }
`;

export const appear = keyframes`
    from {
        opacity: 0;
        position: absolute;
        left: 40px;
    }
    
    to {
        opacity: 1;
        left: 55px;
    }
`;

export const rotateRight = keyframes`
    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(18deg);
    }
    
    100% {
        transform: rotate(0);
    }
`;
export const rotateLeft = keyframes`
    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(-18deg);
    }
    
    100% {
        transform: rotate(0);
    }
`;
export const moveRight = keyframes`
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(10%);
    }
    
    100% {
        transform: rotate(0);
    }
`;
export const moveLeft = keyframes`
    0% {
        transform: translateX(0)
    }

    50% {
        transform: translateX(-10%);
    }
    
    100% {
        transform: translateX(0);
    }
`;
