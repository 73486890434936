import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import PlayVideoIcon from '../assets/images/icon-play-video.svg';
import PauseVideoIcon from '../assets/images/icon-pause-video.svg';
import FullscreenIcon from '../assets/images/icon-fullscreen.svg';

const VideoPlayer = ({video, poster, timing}) => {
    const [playing, setPlaying] = useState(false);
    const [current, setCurrent] = useState(0);
    const [seconds, setSeconds] = useState('00');
    const [minutes, setMinutes] = useState('00');
    const [percentage, setPercentage] = useState(0);

    const myVideo = useRef();
    const myRange = useRef();

    useEffect(() => {
        const video = myVideo.current;
        const range = myRange.current;
        const videoDuration = Math.round(video.duration);

        range.setAttribute('max', videoDuration);
    }, [playing]);

    useEffect(() => {
        setPlaying(false);
    }, [video]);

    useEffect(() => {
        myVideo.current.currentTime = timing;
    }, [timing]);

    const formatTime = (timeInSeconds) => {
        const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);

        return {
            minutes: result.substr(3, 2),
            seconds: result.substr(6, 2),
        };
    }

    const handleTimeUpdate = () => {
        const video = myVideo.current;
        const time = formatTime(Math.round(video.currentTime));

        setCurrent(video.currentTime);
        setSeconds(time.seconds);
        setMinutes(time.minutes);

        const totalLength = Math.round(video.duration) % 60;
        const percentage = (Math.round(video.currentTime) / totalLength) * 100;

        setPercentage(percentage);
    }

    const onPlay = () => {
        setPlaying(true);
    }
    
    const onPause = () => {
        setPlaying(false);
    }

    const handlePlayPause = () => {
        const video = myVideo.current;

        if (video.paused) {
            setPlaying(true);
            video.play();
        } else {
            video.pause();
            setPlaying(false);
        }
    }

    const handleOnEnd = () => {
        const video = myVideo.current;

        setPlaying(false);
        setCurrent(0);
        video.currentTime = 0;
    }

    const handleFullscreen = () => {
        const video = myVideo.current;

        if (video.requestFullscreen) {
            video.requestFullscreen();
        } else if (video.webkitRequestFullscreen) {
            video.webkitRequestFullscreen();
        }
    }

    const handleRangeChange = (e) => {
        setCurrent(e.target.value);

        const video = myVideo.current;
        video.currentTime = e.target.value;
    }

    return (
        <VideoContainer>
            <VideoStream>
                <VideoHover visible={!playing}>
                    <VideoPlayButtonHover
                        playing={playing}
                        onClick={handlePlayPause}
                    >
                    PLAY
                    </VideoPlayButtonHover>
                </VideoHover>
                <video
                    ref={myVideo}
                    onTimeUpdate={handleTimeUpdate}
                    onEnded={handleOnEnd}
                    onPlay={onPlay}
                    onPause={onPause}
                    poster={poster}
                    src={video}
                    // muted
                    // autoPlay
                    // controls
                />
            </VideoStream>
            <VideoControls>
                <VideoPlayButton playing={playing} onClick={handlePlayPause}>Play/Pause</VideoPlayButton>
                <VideoTiming>{`${minutes}:${seconds}`}</VideoTiming>
                <VideoProgressBar>
                    <Range
                        ref={myRange}
                        type="range"
                        min="0"
                        max="0"
                        value={current}
                        percentage={percentage}
                        onChange={handleRangeChange}
                    />
                </VideoProgressBar>
                <VideoFullscreenButton onClick={handleFullscreen}>Fullscreen</VideoFullscreenButton>
            </VideoControls>
        </VideoContainer>
    );
}

export default VideoPlayer;

const VideoContainer = styled.div`
    width: 100%;
`;

const VideoStream = styled.div`
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    background-color: rgba(0,0,0,0.2);
    position: relative;

    video {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
    }
`;

const VideoHover = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 1;
    opacity: 0;
    transition: opacity ease .3s;

    ${props => props.visible && `
        opacity: 1;
    `}

    &:hover {
        opacity: 1;
    }
`;

const VideoPlayButtonHover = styled.button`
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    z-index: 1;
    width: 80px;
    height: 80px;
    text-indent: 100%;
    padding: 0;
    margin: 0;
    border: none;
    overflow: hidden;
    white-space: nowrap;
    background-image: url(${props => props.playing ? PauseVideoIcon : PlayVideoIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: transparent;
    cursor: pointer;
`;

const VideoControls = styled.div`
    display: flex;
    margin-top: 10px;
    padding: 10px 0;
    align-items: center;

    & > * + * {
        margin-left: 20px;
    }
`;

const VideoTiming = styled.span`
    width: 50px;
    display: inline-block;
    color: ${props => props.theme.colors.yellow};
`;

const VideoFullscreenButton = styled.button`
    width: 32px;
    height: 19px;
    background-image: url(${FullscreenIcon});
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    white-space: nowrap;
    text-indent: 100%;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
`;

const VideoPlayButton = styled.button`
    width: 32px;
    height: 32px;
    background-image: url(${props => props.playing ? PauseVideoIcon : PlayVideoIcon});
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    white-space: nowrap;
    text-indent: 100%;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
`;

const VideoProgressBar = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 4px;
        background-color: ${props => props.theme.colors.yellow};
        left: 0;
        top: calc(50% - 2px);
        transform: scaleX(${props => props.percentage}%);
    }
`;

const Range = styled.input`
    appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 4px;
    background: rgba(255,255,255,0.5);
    outline: none;
    cursor: pointer;
    background-image: linear-gradient(to right, ${props => props.theme.colors.yellow} ${props => props.percentage}%, transparent ${props => props.percentage}%);

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: ${props => props.theme.colors.yellow};
        cursor: pointer;

        &::after {
            content: "ciao";
        }
    }
`;