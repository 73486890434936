import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

const ButtonsGroup = ({children, ...props}) => {
  return <ButtonsGroupContainer {...props}>{children}</ButtonsGroupContainer>;
};

ButtonsGroup.propTypes = {
  align: PropTypes.bool,
  children: PropTypes.node,
  asFooter: PropTypes.bool,
};

const ButtonsGroupContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.asFooter &&
    css`
      padding-top: 32px;
    `}

  ${(props) =>
    props.align === 'left' &&
    css`
      justify-content: flex-start;
    `}
  ${(props) =>
    props.align === 'right' &&
    css`
      justify-content: flex-end;
    `}

  ${(props) =>
    props.spaced === 'top' &&
    css`
      margin-top: 30px;
    `}
  ${(props) =>
    props.spaced === 'bottom' &&
    css`
      margin-bottom: 30px;
    `}
`;

export default ButtonsGroup;
