import {useEffect} from 'react';
import styled, {css} from "styled-components";

// Images
import CloserIcon from '../assets/images/icon-delete-no-border.svg';

const ModalElement = ({ active, children, title, onClose}) => {
    useEffect(() => {
        if (active) {
            document?.body?.classList.add('noscroll');
        } else {
            document?.body?.classList.remove('noscroll');
        }

        return () => document?.body?.classList.remove('noscroll');
    }, [active]);

    const stopPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <Modal 
        active={active}
        onClick={onClose}>
            <ModalWrapper
                active={active} 
                onClick={stopPropagation}>
                <ModalHeader>
                    <ModalTitle>{title}</ModalTitle>
                    <ModalCloser onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    {children}
                </ModalBody>
            </ModalWrapper>
        </Modal>
    );
}

export default ModalElement;

const Modal = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    max-height: 100vh;
    padding: 10vh 20px;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0,0,0,0.7);
    cursor: pointer;
    overflow-y: auto;
    visibility: hidden;
    opacity: 0;
    transition: all linear .15s;
    z-index: 99;

    ${props => props.active && css`
        visibility: visible;
        opacity: 1;
    `}
`;
    
const ModalWrapper = styled.div`
    width: 100%;
    max-width: 500px;
    padding: 24px;
    background-color: white;
    border-radius: 4px;
    cursor: default;
    transform: translateY(20px);
    transition: transform 0.15s linear;
    color: initial;

    ${props => props.active && css`
        transform: translateY(0);
    `}
`;

const ModalHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
`;

const ModalTitle = styled.h3`
    margin: 0;
    font-weight: 400;
    font-size: 28px;
`;

const ModalCloser = styled.button`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 4px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    background-image: url(${CloserIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
`;

const ModalBody = styled.div`

`;