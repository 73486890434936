function generateCalendar(dates, label) {
    var calstr =
        'BEGIN:VCALENDAR' +
        '\nVERSION:2.0' +
        '\nPRODID:-//hacksw/handcal//NONSGML v1.0//EN';
    for (var i = 0; i < dates.length; i++) {
        const date = dates[i];
        const str = date.split('-').join('');
        calstr += '\nBEGIN:VEVENT' + '\nUID:' + label;
        calstr += '\nDTSTAMP:' + str + 'T000000';
        calstr += '\nDTSTART:' + str + 'T170000';
        calstr += '\nDTEND:' + str + 'T190000';
        calstr += '\nSUMMARY:Novo Nordisk | Change Maker | ' + label;
        calstr += '\nDESCRIPTION:Novo Nordisk | Change Maker | ' + label;
        calstr += '\nBEGIN:VALARM';
        calstr += '\nTRIGGER:-PT30M';
        calstr += '\nDESCRIPTION:Novo Nordisk | Change Maker | ' + label;
        calstr += '\nACTION:AUDIO';
        calstr += '\nEND:VALARM';
        calstr += '\nBEGIN:VALARM';
        calstr += '\nTRIGGER:-PT2H';
        calstr += '\nDESCRIPTION:Novo Nordisk | Change Maker | ' + label;
        calstr += '\nACTION:AUDIO';
        calstr += '\nEND:VALARM';
        calstr += '\nEND:VEVENT';
    }
    calstr += '\nEND:VCALENDAR';
    return calstr;
}

function download(text) {
    var link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute(
        'href',
        'data:text/calendar;charset=utf-8,' + encodeURIComponent(text)
    );
    link.setAttribute('download', 'save-the-date.ics');
    link.click();
}

export const createFilePath = (dates, label) => {
    let calendar = generateCalendar(dates, label);

    if (navigator.msSaveBlob) {
        const blob = new Blob([calendar]);
        navigator.msSaveBlob(blob, 'save-the-date.ics');
        return;
    }

    download(calendar);
};
