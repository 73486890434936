/* global gtag */

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useUser } from "../contexts/UserContext";

export function trackPage(page) {
  // Sending the pageview no longer requires passing the page
  // value since it's now stored on the tracker object.
  gtag('event', 'page_view', {
    page_title: page,
    page_location: page,
    page_path: page,
  });
}

export function trackEvent(event) {
  gtag('event', event);
}


export function trackUser(user) {
  gtag('set', 'dimension1', user);
  gtag('set', 'dimension2', user);
}

export function PageTracker() {
  let location = useLocation();
  const {user} = useUser();

  useEffect(() => {
    trackPage(location.pathname);
  }, [location, user]);

  return null;
}