import React, {useRef, useEffect, useState} from 'react';
import useFullscreen from '../../../hook/useFullscreen';

import VideoBox from './PartecipantBox/Screen';
// import VideoBoxSmall from '../VideoBoxSmall';
import usePiP from '../../../hook/usePiP';

import type {AudioTrack, VideoTrack} from '../../../types/video-room';
import type {ParticipantCommand, ScreenProps} from './types';
import {
  IconFullscreen,
  IconFullscreenOff,
  IconPip,
  IconPipOff,
} from '../../Icons';

export default function Screen({
  participant,
  // adminMute = false,
  // canBeRecorded = false,
  // localRecording = false,
  isLocalParticipant = false,
}: ScreenProps) {
  const videoTrack = participant.screen?.track;

  const hasVideo = !!videoTrack;

  const fullscreenRef = useRef<HTMLDivElement>();
  const videoRef = useRef<HTMLVideoElement>();
  const audioRef = useRef();

  const fullscreen = useFullscreen(fullscreenRef);
  const pip = usePiP(videoRef);

  const commands: ParticipantCommand[] = [
    {
      type: 'fullscreen',
      enabled: fullscreen.isSupported && hasVideo,
      tooltip: fullscreen.isFullscreen
        ? 'Esci da schermo intero'
        : 'Schermo interno',
      handle: fullscreen.toggle,
      icon: fullscreen.isFullscreen ? IconFullscreenOff : IconFullscreen,
    },
    {
      type: 'picture-in-picture',
      enabled: pip.isSupported && hasVideo,
      tooltip: 'Picture in Picture',
      handle: pip.toggle,
      icon: pip.isPip ? IconPipOff : IconPip,
    },
  ];

  const Box = VideoBox;

  // fetch username
  const [currentUser, setCurrentUser] = useState('');

  // useEffect(() => {
  //   async function fetchData() {
  //     const db = await getUsers();
  //     const user = db.find((item) => item.id === participant.identity);
  //     setCurrentUser(user ? user.name : '');
  //   }
  //   fetchData();
  // }, []);

  return (
    <Box
      owner={currentUser ? currentUser : participant.identity}
      isLocalVideo={isLocalParticipant}
      fullscreen={fullscreen}
      fullscreenRef={fullscreenRef}
      audioRef={audioRef}
      videoRef={videoRef}
      hasVideo={hasVideo}
      commands={commands}
    >
      <AppendedTracks
        videoTrack={videoTrack}
        videoRef={videoRef}
        audioTrack={null}
        audioRef={audioRef}
      />
    </Box>
  );
}

interface TracksContainerProps {
  videoTrack: VideoTrack;
  audioTrack: AudioTrack;
  videoRef: React.MutableRefObject<HTMLVideoElement>;
  audioRef: React.MutableRefObject<HTMLAudioElement>;
}

function AppendedTracks({
  videoTrack,
  videoRef,
  audioTrack,
  audioRef,
}: TracksContainerProps) {
  const tracksContainerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!videoTrack) {
      return;
    }

    videoRef.current = videoTrack.attach();

    tracksContainerRef.current.appendChild(videoRef.current);
    videoTrack.on('stopped', () => {
      if (videoRef.current) {
        videoRef.current.remove();
        videoRef.current = null;
      }
    });

    return () => videoRef.current && videoRef.current.remove();
  }, [videoTrack, videoRef]);

  useEffect(() => {
    if (!audioTrack) {
      return;
    }

    audioRef.current = audioTrack.attach();

    tracksContainerRef.current.appendChild(audioRef.current);

    audioTrack.on('stopped', () => {
      if (audioRef.current) {
        audioRef.current.remove();
        audioRef.current = null;
      }
    });

    return () => audioRef.current && audioRef.current.remove();
  }, [audioTrack, audioRef]);

  return <div ref={tracksContainerRef}></div>;
}
