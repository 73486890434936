import React, { useState } from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

import {
    FormContainer,
    Form,
    Label,
    Input,
    FormMessage,
    CheckboxWrapper
} from '../components/FormElements';
import { Button } from '../components/Button';

const Resources = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    return (
        <Container>
            <Inner>
                <Title>Registrati</Title>
                <FormContainer>
                    <Form action="#">
                        <Label>Nome</Label>
                        <Input name="name" type="text" placeholder="Nome" />
                        <Label>Cognome</Label>
                        <Input name="surname" type="text" placeholder="Cognome" />
                        <Label>Email</Label>
                        <Input name="email" type="text" placeholder="Email" />
                        <Label>Password</Label>
                        <Input name="password" type="password" placeholder="Password" />
                        <Label>Ripeti Password</Label>
                        <Input name="password-2" type="password" placeholder="Ripeti Password" />
                        <CheckboxWrapper>
                            <input name="privacy" type="checkbox" id="privacy" />
                            <label htmlFor="privacy">Dichiaro di aver preso visione e di accettare l'<a href="https://pro.novonordisk.it/privacy-policy/informativa-sul-trattamento-dei-dati-personali.html" target="_blank" rel="nofollow noreferrer">Informativa sulla privacy</a></label>
                        </CheckboxWrapper>
                        <Button wide type="submit" disabled={loading}>{loading ? 'Caricamento...' : 'Registrati'}</Button>
                        {error && (
                            <FormMessage>
                                Errore generico del form
                            </FormMessage>
                        )}
                        <p>
                            <Link to="/login">Accedi</Link><br /><br />
                            <Link to="#">Password dimenticata?</Link>
                        </p>
                    </Form>
                </FormContainer>
            </Inner>
        </Container>
    );
}

export default Resources;

const Container = styled.div`
    width: 100%;
`;

const Inner = styled.div`
    width: 100%;
    max-width: ${props => props.theme.sizes.mw};
    margin: 0 auto;
    padding: 100px 20px;
    min-height: calc(100vh - 137.44px);

    @media ${props => props.theme.breakpoints.tablet} {
        min-height: 100%;
    }
`;

const Title = styled.h2`
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.theme.colors.yellow};
`;