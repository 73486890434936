import React from 'react';
import styled, {css} from 'styled-components';
// import {Logo} from '../../Admin/Layout/Logo';
import {ButtonParticipants} from '../../Buttons';
import youmain from '../../../assets/youmain.svg';
import Logo from '../../../../assets/images/logo-small.png';

interface HeaderProps {
  short?: boolean;
  peopleCount: number;
  roomMaxSize: number;
}

export default function Header(props: HeaderProps) {
  return (
    <VideoRoomHeaderContainer $short={props.short}>
      <div>
        <img src={Logo} alt="Change Maker" />
      </div>
      <div>
        {/* <ButtonParticipants
          invert={false}
          // onClick={() => props.handleSidebar('group')}
          roomMaxSize={props.roomMaxSize}
          count={props.peopleCount}
          toggle
        /> */}
      </div>
    </VideoRoomHeaderContainer>
    // <>
    //   <HeaderLogo>
    //     <img src={Logo} alt="Vienna EASD 2020" />
    //   </HeaderLogo>
    // </>
  );
}

const VideoRoomHeaderContainer = styled.header<{$short?: boolean}>`
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  height: ${(props) => props.theme.layout.roomHeader};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  ${(props) =>
    props.$short &&
    css`
      right: 370px;
      @media ${(props) => props.theme.breakpoints.tablet} {
        right: 0;
      }
    `}

  > div {
    display: flex;
    align-items: center;

    img {
      height: 30px;
      width: auto;
    }
  }
`;
