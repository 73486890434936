import React, {useState} from 'react';
import { Switch, Route, useRouteMatch} from "react-router-dom";

import PreEvent from '../components/PreEvent';
import Webinar from '../components/Webinar';

// Images
import IconMediatraining from '../assets/images/icon-mediatraining.png';
import ConsoliImage from '../assets/images/medici/agostino-consoli.png';
import DiBartoloImage from '../assets/images/medici/paolo-di-bartolo.png';
import SolainiImage from '../assets/images/medici/raffaele-solaini.png';
import VideoPoster from '../assets/images/pt-1.png';

// Videos
import Video from '../assets/videos/video.mp4';

const DATA = {
    label: "Comunicazione",
    title: "L’importanza<br />di comunicare ai colleghi.",
    description: "Scopri le migliori tecniche di storytelling per comunicare efficacemente ai tuoi colleghi le caratteristiche della Semaglutide orale.",
    comingLabel: "Appuntamento al 22 luglio dalle 17 alle 19.",
    disclaimer: "Per la partecipazione al Workshop è richiesto l'accesso da pc fisso o portatile.",
    icon: IconMediatraining,
    videos: [
        {
            video: 'https://novonordisk-ambassadorprogram.improntaadv.com/videos/change-maker-pt2.mp4',
            poster: VideoPoster,
            title: 'Replay',
            // times: [
            //     {
            //         title: 'Nome del capitolo',
            //         timing: '2',
            //         poster: Avatar,
            //     },
            //     {
            //         title: 'Nome del capitolo 2',
            //         timing: '5',
            //         poster: Avatar,
            //     },
            // ]
        },
    ],
    status: 'done',
    downloads: null,
    availables: 0,
    date: [
        '2021-07-22',
    ],
    people: [
        {
            title: 'Prof.',
            name: 'Agostino Consoli',
            image: ConsoliImage,
        },
        {
            title: 'Dott.',
            name: 'Paolo Di Bartolo',
            image: DiBartoloImage,
        },
        {
            title: 'Prof.',
            name: 'Raffaele Solaini',
            image: SolainiImage,
        }
    ]
}

const Comunicazione = () => {
    let { path, url } = useRouteMatch();

    return (
         <Switch>
            <Route exact path={path}>
            <PreEvent
                linkToEvent="#"
                linkToOldSession={`${url}/webinar`}
                {...DATA}
            />
          </Route>
            <Route path={`${path}/:webinar`}>
                <Webinar
                    {...DATA}
                />
          </Route>
        </Switch>
    );
}

export default Comunicazione;