import React, {useState} from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";

import PreEvent from '../components/PreEvent';
import Webinar from '../components/Webinar';

// Images
import IconScientific from '../assets/images/icon-scientific.png';
import EspositoImage from '../assets/images/medici/katherine-esposito.png';
import AvogaroImage from '../assets/images/medici/angelo-avogaro.png';
import FrontoniImage from '../assets/images/medici/simona-frontoni.png';
import DelPratoImage from '../assets/images/medici/stefano-del-prato.png';
import VideoPoster from '../assets/images/pt-1.png';
import VideoPoster2 from '../assets/images/poster2.png';
import Avatar from '../assets/images/medici/agostino-consoli.png';

// Videos
import Video from '../assets/videos/video.mp4';
import Video2 from '../assets/videos/video2.mp4';

const DATA = {
    status: 'done',
    label: "Innovazione",
    title: "Le caratteristiche<br />della Semaglutide orale.",
    description: "Ascolta gli interventi degli esperti e scopri le principali caratteristiche del principio attivo, come agisce e i benefici evidenziati negli studi.",
    comingLabel: "Appuntamento all’8 luglio dalle 17 alle 19.",
    icon: IconScientific,
    videos: [
        {
            video: 'https://novonordisk-ambassadorprogram.improntaadv.com/videos/change-maker-pt1.mp4',
            poster: VideoPoster,
            title: 'Replay',
            // times: [
            //     {
            //         title: 'Nome del capitolo',
            //         timing: '2',
            //         poster: Avatar,
            //     },
            //     {
            //         title: 'Nome del capitolo 2',
            //         timing: '5',
            //         poster: Avatar,
            //     },
            // ]
        },
        // {
        //     video: Video2,
        //     poster: VideoPoster2,
        //     title: 'Nome del video'
        // },
        // {
        //     video: Video,
        //     poster: VideoPoster,
        //     title: 'Nome del video'
        // },
    ],
    downloads: [
        // {
        //     file: Video,
        //     label: 'Scarica questo'
        // },
        // {
        //     file: Video,
        //     label: 'Scarica quello'
        // }
    ],
    availables: 0,
    date: [
        '2021-07-08',
    ],
    people: [
        {
            title: 'Prof.ssa',
            name: 'Katherine Esposito',
            image: EspositoImage,
        },
        {
            title: 'Prof.ssa',
            name: 'Simona Frontoni',
            image: FrontoniImage,
        },
        {
            title: 'Prof.',
            name: 'Angelo Avogaro',
            image: AvogaroImage,
        },
        {
            title: 'Prof.',
            name: 'Stefano Del Prato',
            image: DelPratoImage,
        }
    ]
}

const Innovazione = () => {
    let { path, url } = useRouteMatch();

    return(
        <Switch>
            <Route exact path={path}>
                <PreEvent
                    linkToEvent="#"
                    linkToOldSession={`${url}/webinar`}
                    {...DATA}
                />
            </Route>
            <Route path={`${path}/:webinar`}>
                <Webinar
                    {...DATA}
                />
            </Route>
        </Switch>
    );
}

export default Innovazione;