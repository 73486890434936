import React from 'react';
import styled from 'styled-components';

import {Link} from 'react-router-dom';

import {Text} from '../components/Typografy';
import { Circles, Dots } from '../components/GraphicElements';

// Images
import LogoImage from '../assets/images/logo-big.png';
import IconMediatraining from '../assets/images/icon-mediatraining.png';
import IconLeadership from '../assets/images/icon-leadership.png';
import IconScientific from '../assets/images/icon-scientific.png';


const Home = () => {
    return (
        <Container>
            <BigImage src={LogoImage} alt="Change Maker" />
            <Wrapper>
                <DotsStyled />
                <DotsStyledLower />
                <CirclesStyled />
                <Grid>
                    <Col>
                        <Title>Un ruolo<br />fondamentale.<br /><YellowText>Il tuo.</YellowText></Title>
                        <Text>
                            Novo Nordisk lancia il primo trattamento a base di Semaglutide che non ha bisogno
                            di essere iniettato. La Semaglutide orale rappresenta un punto di svolta in ambito diabetologico
                            ed è importante poter contare su persone qualificate che abbiano le competenze necessarie
                            a veicolarne le caratteristiche e le qualità.
                        </Text>
                        <Text yellow>
                            Ti abbiamo scelto, insieme a pochi altri specialisti, per diventare portavoce di un cambiamento
                            in grado di migliorare la vita di tante persone.
                        </Text>
                    </Col>
                    <Col>
                        <IconsGrid>
                            <IconElement to="/innovazione">
                                <img src={IconScientific} alt="Scientific" />
                                <Text>Innovazione</Text>
                                <Text>Scopri le caratteristiche principali<br />della Semaglutide orale.</Text>
                            </IconElement>
                            <IconElement to="/comunicazione">
                                <img src={IconMediatraining} alt="Mediatraining" />
                                <Text>Comunicazione</Text>
                                <Text>Accresci le abilità<br />di storyteller.</Text>
                            </IconElement>
                            <IconElement to="/leadership">
                                <img src={IconLeadership} alt="Leadership" />
                                <Text>Leadership</Text>
                                <Text>Affina le tue capacità<br />di leadership generativo.</Text>
                            </IconElement>
                        </IconsGrid>
                    </Col>
                </Grid>
            </Wrapper>
        </Container>
    );
}

export default Home;

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 0;
`;

const BigImage = styled.img`
    display: block;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 300px 40px 150px;

    @media ${props => props.theme.breakpoints.tablet} {
        padding: 50px 40px;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    position: relative;
    padding-top: 400px;
    padding-bottom: 100px;
    overflow: hidden;

    @media ${props => props.theme.breakpoints.laptop} {
        padding: 50px 0;
    }
`;

const CirclesStyled = styled(Circles)`
    transform: translateX(calc(-50% - 500px)) rotate(36deg);
`;

const DotsStyled = styled(Dots)`
    left: initial;
    right: 0;
`;

const DotsStyledLower = styled(Dots)`
    left: initial;
    right: 0;
    top: initial;
    bottom: 50px;
`;

const Grid = styled.div`
    position: relative;
    max-width: ${props => props.theme.sizes.mw};
    display: flex;
    align-items: flex-start;
    margin: 0 auto;
    padding: 0 20px;
    padding-right: 50px;
    flex-wrap: wrap;
`;
const Col = styled.div`
    flex: 1;

    &:first-of-type {
        max-width: 50%;
    }

    @media ${props => props.theme.breakpoints.laptop} {
        flex: initial;
        width: 100%;
    }
    
    @media ${props => props.theme.breakpoints.tablet} {
        &:first-of-type {
            max-width: 100%;
        }
    }
`;

const Title = styled.h2`
    font-size: 90px;
    line-height: 100px;
    font-weight: 400;

    @media ${props => props.theme.breakpoints.tablet} {
        font-size: 50px;
        line-height: 60px;
    }
`;

const YellowText = styled.span`
    display: inline-block;
    border-bottom: solid 1px currentColor;
    color: ${props => props.theme.colors.yellow};
`;

const IconsGrid = styled.div`
    padding-left: 50px;
    position: relative;

    @media ${props => props.theme.breakpoints.laptop} {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 80px 40px;
    }
`;

const IconElement = styled(Link)`
    display: inline-block;
    position: absolute;
    text-decoration: none;
    transition: transform ease .3s;

    @media (hover: hover) {
        &:hover {
            transform: translateY(-5px);
        }
    }

    &:nth-child(1) {
        top: 0;
        left: 30%;
    }
    &:nth-child(2) {
        top: 100px;
        right: 0;
    }
    &:nth-child(3) {
        top: 360px;
        left: 50%;
    }

    @media ${props => props.theme.breakpoints.laptop} {
        position: relative;
        flex: 1;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            top: initial;
            left: initial;
            right: initial;
        }
    }
    
    img {
        display: block;
        width: 130px;
        margin: 0 auto 24px;
    }

    p:first-of-type {
        text-transform: uppercase;
        color: ${props => props.theme.colors.yellow};
        margin-bottom: 8px;
    }

    p {
        text-align: center;
        margin: 0;
    }
`;