import React from 'react';
import {VideoRoomContextType} from '../../../contexts/VideoRoomContext';
import GroupGridProvider from './GroupGridProvider';

import {GroupItem} from '../Participant';
import ScreenItem from '../Participant/ScreenItem';

interface GroupProps {
  videoRoom: VideoRoomContextType;
  hasSidebar: boolean;
}

export default function Group({videoRoom, hasSidebar}: GroupProps) {
  const videoCount = videoRoom.room.remotes.length + 1;
  const hasScreenSharing = !!videoRoom.room.screenSharing;

  return (
    <>
      {hasScreenSharing && (
        <GroupGridProvider
          videoCount={1}
          aside={false}
          sidebar={hasSidebar}
          isPinned
        >
          <ScreenItem participant={videoRoom.room.screenSharing} />
        </GroupGridProvider>
      )}
      <GroupGridProvider
        videoCount={videoCount}
        aside={hasScreenSharing}
        sidebar={hasSidebar}
      >
        {videoRoom.room.remotes.map((remote, index) => (
          <GroupItem
            key={remote.identity}
            participant={remote}
            adminMute={videoRoom.muteParticipant}
            adminKick={videoRoom.handleKick}
            participantNumber={index + 1}
          />
        ))}

        <GroupItem
          participant={videoRoom.room.localParticipant}
          isLocalParticipant={true}
          participantNumber={0}
        />
      </GroupGridProvider>
    </>
  );
}
