import React, {useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {IconBrowser} from '../Icons';
import {HeadingS, Text, TextS} from '../Typography';

export default function BrowserSupport() {
  const theme = useContext(ThemeContext);
  return (
    <Container>
      <HeadingS>C’è un problema! Proviamo a risolverlo.</HeadingS>
      <Center>
        <IconBrowser fill={theme.colors.warning} size="100px" />
        <Text>
          Il browser che stai usando non supporta le funzionalità richieste
        </Text>
      </Center>
      <Center>
        <TextS>
          Per poter usufruire del servizio ti consigliamo di utilizzare i
          seguenti browser: Microsoft&nbsp;Edge, Google&nbsp;Chrome,
          Mozilla&nbsp;Firefox o Safari.
        </TextS>
      </Center>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: block;
  margin-top: ${(props) => props.theme.layout.frontendSpace};
  background-color: #fff;
  text-align: center;
  width: 100%;
  height: auto;
  border-radius: 8px;
  padding: 24px;
  z-index: 1;
  margin: 0 auto 10px;
  @media (max-width: 1024px) {
    width: 90%;
  }

  h4 {
    font-size: 18px;
    margin: 0 0 5px;
  }

  ${Text} {
    color: ${(props) => props.theme.colors.warning};
    font-weight: 700;
  }
`;

const Center = styled.div`
  text-align: center;
  margin-bottom: 24px;
  padding-top: 30px;

  &:last-child {
    margin-bottom: 0;
    padding-top: 0;
  }
`;
