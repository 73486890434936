import React from 'react';
import Header from './Header';
import {Chat as ChatComponent} from '../../Chat';

import {ChatMessage} from '../../../types/video-room';

type Props = {
  myIdentity: string;
  messages: ChatMessage[];
  onSendMessage: (text: string) => void;
};

export default function Chat({
  messages = [],
  myIdentity,
  onSendMessage,
}: Props) {
  return (
    <>
      <Header title="Chat" />
      <ChatComponent
        myIdentity={myIdentity}
        messages={messages}
        onSend={onSendMessage}
      />
    </>
  );
}
