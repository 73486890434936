import React, {useCallback, useRef} from 'react';
import styled, {css} from 'styled-components';
import {
  IS_SCREEN_SHARING_SUPPORTED,
  useLocalTracks,
} from '../../../contexts/LocalTracksContext';
import {useVideoRoom} from '../../../contexts/VideoRoomContext';
import useMouseMoveActive from '../../../hook/useMouseMoveActive';
import {ButtonControl} from '../../Buttons';
import {ContextualMenu, ContextualMenuItem} from '../../ContextualMenu';
import {IconEndCall, IconScreen, IconDetails, Youmain, IconChat} from '../../Icons';
import VideoControls from '../../Video/VideoControls';
import impronta from '../../../assets/impronta.svg';
import youmain from '../../../assets/youmain.svg';

type FooterType = {
  short: boolean;
  onQuit: () => void;
  isAdmin: boolean;
  messagesCount?: number;
  sidebarStatus?: SidebarType;
  handleSidebar: (type: string) => void;
};

const Footer = ({short, onQuit, isAdmin, messagesCount, sidebarStatus, handleSidebar}: FooterType) => {
  const containerRef = useRef<HTMLDivElement>();
  // const active = useMouseMoveActive(containerRef, true);
  const videoRoom = useVideoRoom();
  const localTracks = useLocalTracks();

  const toggleScreen = useCallback(() => {
    const isActive = videoRoom.enabledScreen;

    if (isActive) {
      videoRoom.stopSharing();
    } else {
      videoRoom.shareScreen();
    }
  }, [videoRoom]);

  const goTo = (path) => {
    window.open(
      'https://www.youmain.it/' + path,
      '_blank',
      'noopener, noreferrer'
    );
  };

  const goToPowerBy = () => {
    window.open('https://www.youmain.it', '_blank', 'noopener, noreferrer');
  };

  return (
    <VideoRoomFooterContainer ref={containerRef} $active={true} $short={short}>
      <div>
        {/* <ButtonControl icon={IconDetails} invert={false} label="Dettagli">
          <ContextualMenu left>
            <PoweredByButton onClick={goToPowerBy}>
              <Youmain />
            </PoweredByButton>
            <ContextualMenuItem
              title="Termini del servizio"
              onClick={() => goTo('tos')}
            />
            <ContextualMenuItem
              title="Privacy policy"
              onClick={() => goTo('privacy-policy')}
            />
          </ContextualMenu>
        </ButtonControl> */}
      </div>

      <div>
        <VideoControls
          enabledCamera={videoRoom.enabledCamera}
          enabledMic={videoRoom.enabledMic}
          videoDevices={localTracks.devices.filter(
            (d) => d.kind === 'videoinput'
          )}
          audioDevices={localTracks.devices.filter(
            (d) => d.kind === 'audioinput'
          )}
          openMic={videoRoom.openMic}
          closeMic={videoRoom.closeMic}
          openCamera={videoRoom.openCamera}
          closeCamera={videoRoom.closeCamera}
        />

        {IS_SCREEN_SHARING_SUPPORTED && isAdmin && (
          <ButtonControl
            icon={IconScreen}
            invert={videoRoom.enabledScreen}
            onClick={toggleScreen}
            tooltip="Condivisione schermo"
            toggle
          />
        )}

        <ButtonControl
            icon={IconChat}
            iconColor="black"
            invert={sidebarStatus === 'chat'}
            onClick={() => handleSidebar('chat')}
            count={messagesCount}
            tooltip="Chat"
            toggle
          />
      </div>

      <div>
        <ButtonControl
          icon={IconEndCall}
          iconColor="red"
          invert={false}
          onClick={onQuit}
          label="Abbandona"
        />
      </div>
    </VideoRoomFooterContainer>
  );
};

export default Footer;

export const VideoRoomFooterContainer = styled.footer<{
  $active: boolean;
  $short: boolean;
}>`
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => props.theme.layout.roomFooter};
  position: fixed;
  left: 0;
  right: 0;
  bottom: -${(props) => props.theme.layout.roomFooter};
  padding: 0 20px;
  z-index: 2;

  @media ${(props) => props.theme.breakpoints.phone} {
    bottom: 0;
    background-color: transparent;
  }

  ${(props) =>
    props.$active &&
    css`
      bottom: 0;
    `}
  ${(props) =>
    props.$short &&
    css`
      right: 370px;
      @media ${(props) => props.theme.breakpoints.tablet} {
        right: 0;
      }
    `}

  > div {
    display: flex;
  }
`;

const PoweredByButton = styled.div`
  appearance: none;
  background-color: #fec52d;
  border: none;
  width: 100%;
  padding: 32px 12px 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid #eaeaea;
  text-align: left;

  &:hover {
    background-color: #000;

    path,
    circle {
      fill: #fec52d;
    }
  }
`;

const FooterPoweredBy = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 12px;
  bottom: calc(${(props) => props.theme.layout.roomFooter} + 10px);
  font-size: 1rem;
  z-index: 2;
`;
