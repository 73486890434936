import React from 'react';
import styled, {css} from 'styled-components';
import {Text} from '../Typography';
import {spin} from '../Animations';

const errorTypes = {
  Forbidden: 'Non hai il permesso per visualizzare questo contenuto',
  'Store not found': 'Non ci sono Store in questa azienda',
};

const Loading = (props) => {
  return (
    <LoadingContainer error={props.error}>
      {props.error ? (
        <Text>{errorTypes[props.error] || props.error?.message}</Text>
      ) : (
        <Loader />
      )}
    </LoadingContainer>
  );
};

export default Loading;

// Utils
const loadingSize = 20;
const loadingBorder = 3;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 5px;
  padding: 12px;
  p {
    color: #fff;
    margin: 0;
  }

  ${(props) =>
    props.error &&
    css`
      background-color: ${(props) => props.theme.colors.error};
    `}
`;
const Loader = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: ${loadingSize}px;
    height: ${loadingSize}px;
    border: solid ${loadingBorder}px
      ${(props) => props.theme.colors.primary.first};
    border-bottom-color: white;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - ${loadingSize / 2 + loadingBorder}px);
    left: calc(50% - ${loadingSize / 2 + loadingBorder}px);
    animation: ${spin} linear infinite 0.5s;
  }
`;
