import React, {useCallback, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {format} from 'date-fns';
import ChatMessage from './ChatMessage';
import Icon from '../Icons';

export default function Chat({messages, onSend, me, getParticipantById}) {
  const inputRef = useRef();
  const buttonRef = useRef();
  const chatListRef = useRef();

  useEffect(() => {
    chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
  }, [messages]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (inputRef.current.value === '') {
        return;
      }

      const message = inputRef.current.value;

      onSend(message);
      inputRef.current.value = '';
      buttonRef.current.disabled = true;
    },
    [onSend]
  );

  const setButton = () => {
    buttonRef.current.disabled = inputRef.current.value === '';
  };

  return (
    <Container>
      <Footer novalidate onSubmit={onSubmit}>
        <Input
          onChange={setButton}
          onBlur={setButton}
          ref={inputRef}
          autoComplete="off"
          type="text"
          name="message"
          placeholder="Scrivi un messaggio"
        />
        <Button disabled ref={buttonRef}>
          <Icon type="send" />
        </Button>
      </Footer>
      <Body ref={chatListRef}>
        {messages.map((message) => (
          <ChatMessage
            key={message.id}
            mine={message.user === me}
            author={getParticipantById(message.user)}
            text={message.message}
            date={format(message.date, 'HH:mm')}
          />
        ))}
      </Body>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  color: white;
`;

const Body = styled.div`
  padding: 20px;
  overflow-y: auto;
`;

const Footer = styled.form`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 20px;
  border-top: solid 1px ${(props) => props.theme.colors.grayscale.medGrayTwo};
  background: rgba(0, 0, 0, 0.8);
`;

const Input = styled.input`
  width: 100%;
  flex: 1;
  display: block;
  background-color: transparent;
  border: none;
  height: 32px;
  line-height: 32px;
  padding: 0;
  font-size: 16px;
  font-weight: 300;
  color: ${(props) => props.theme.colors.grayscale.white};
  border-bottom: solid 1px transparent;

  &:focus {
    border-color: ${(props) => props.theme.colors.grayscale.white};
  }

  ::placeholder {
    color: gray;
  }
`;

const Button = styled.button`
  display: block;
  margin: 0;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 4px;
  margin-left: 16px;
  flex-shrink: 0;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:hover:not(:disabled) {
    background-color: #ffffff40;
  }

  &:active:not(:disabled) {
    transform: scale(1.05);
  }
`;
