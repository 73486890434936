import React from 'react';
import styled from 'styled-components';

import {Link} from 'react-router-dom';
import { Circles } from '../components/GraphicElements';

// Images
import DownloadIcon from '../assets/images/icon-download-2.svg';
import Arg1 from '../assets/images/arg-1.png';
import Arg2 from '../assets/images/arg-2.png';
import Arg3 from '../assets/images/arg-3.png';
import Arg4 from '../assets/images/arg-4.png';

const Resources = () => {
    return (
        <Container>
            <CirclesStyled />
            <Inner>
                <Main>
                    <Section>
                        <h2>Consulta i riferimenti bibliografici</h2>
                        <Grid>
                            <Col>
                                <h3>Bigliografia argomento 1</h3>
                            </Col>
                            <Col>
                                <h3>Bigliografia argomento 2</h3>
                            </Col>
                            <Col>
                                <h3>Bigliografia argomento 3</h3>
                            </Col>
                        </Grid>
                        <ul>
                            <li>
                                <Link to="/">
                                    John B. Buse MD, Anders L. Carlson MD, Mitsuhisa Komatsu MD, Ofri Mosenzon MD, Ludger Rose MD, Bo Liang MD, Kristine Buchholtz MD,
                                    Hiroshi Horio MSc,Takashi Kadowaki MD. “Fast-acting insulin aspart versus insulin aspart in the setting of insulin degludec-treated type 1 diabetes:
                                    Efficacy and safety from a randomized double-blind trial”. Diabetes Obes Metab 2018 Dec; 20(12):2885-2893.
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    Pedro Iglesias and Juan J. Díez. “Insulin therapy in renal disease”. Obesity and Metabolism 2008 (10): 811–823.
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    John B. Buse MD, Anders L. Carlson MD, Mitsuhisa Komatsu MD, Ofri Mosenzon MD, Ludger Rose MD, Bo Liang MD, Kristine Buchholtz MD,
                                    Hiroshi Horio MSc,Takashi Kadowaki MD. “Fast-acting insulin aspart versus insulin aspart in the setting of insulin degludec-treated type 1 diabetes:
                                    Efficacy and safety from a randomized double-blind trial”. Diabetes Obes Metab 2018 Dec; 20(12):2885-2893.
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    Pedro Iglesias and Juan J. Díez. “Insulin therapy in renal disease”. Obesity and Metabolism 2008 (10): 811–823.
                                </Link>
                            </li>
                        </ul>
                    </Section>
                    <Section>
                        <h2>Scarica le slide contenute nei video</h2>
                        <Grid>
                            <Col>
                                <h3>Argomento 1</h3>
                                <ul>
                                    <li>
                                        Slide lorem ipsum <Link to="/">download</Link>
                                    </li>
                                    <li>
                                        Slide lorem ipsum <Link to="/">download</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col>
                                <h3>Argomento 2</h3>
                                <ul>
                                    <li>
                                        Slide lorem ipsum <Link to="/">download</Link>
                                    </li>
                                    <li>
                                        Slide lorem ipsum <Link to="/">download</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col>
                                <h3>Argomento 3</h3>
                                <ul>
                                    <li>
                                        Slide lorem ipsum <Link to="/">download</Link>
                                    </li>
                                    <li>
                                        Slide lorem ipsum <Link to="/">download</Link>
                                    </li>
                                </ul>
                            </Col>
                        </Grid>
                    </Section>
                    <Section>
                        <h2>Comunicati e rassegna stampa</h2>
                        <Grid>
                            <Col>
                                <ul>
                                    <li>
                                        <Link to="/">Link lorem ipsum</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Link lorem ipsum</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Link lorem ipsum</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Link lorem ipsum</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col>
                                <ul>
                                    <li>
                                        <Link to="/">Link lorem ipsum</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Link lorem ipsum</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Link lorem ipsum</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Link lorem ipsum</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col>
                                <ul>
                                    <li>
                                        <Link to="/">Link lorem ipsum</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Link lorem ipsum</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Link lorem ipsum</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Link lorem ipsum</Link>
                                    </li>
                                </ul>
                            </Col>
                        </Grid>
                    </Section>
                    <Section>
                        <h2>Contenuti esclusivi Novo Nordisk</h2>
                        <Images>
                            <img src={Arg1} alt="Arg 1" />
                            <img src={Arg2} alt="Arg 2" />
                            <img src={Arg3} alt="Arg 3" />
                            <img src={Arg4} alt="Arg 4" />
                        </Images>
                    </Section>
                </Main>
                <Side>
                    <LinkStyled to="/">Scarica Format Slide <img src={DownloadIcon} alt="" /></LinkStyled>
                    <LinkStyled to="/">Scarica Format Discorso <img src={DownloadIcon} alt="" /></LinkStyled>
                    <LinkStyled to="/">Scarica Sfondo Call <img src={DownloadIcon} alt="" /></LinkStyled>
                    <LinkStyled to="/">Prenota Stanza Call <img src={DownloadIcon} alt="" /></LinkStyled>
                </Side>
            </Inner>
        </Container>
    );
}

export default Resources;

const CirclesStyled = styled(Circles)`
    top: 0;
    left: 50%;
    transform: translate(calc(50% - 460px), -550px) rotate(65deg);
`;

const Container = styled.div`
    width: 100%;
    padding: 100px 20px;

    @media ${props => props.theme.breakpoints.header} {
        padding-top: 50px;
    }
`;

const Inner = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: ${props => props.theme.sizes.mw};
    margin: 0 auto;
`;

const Main = styled.div`
    padding-top: 100px;
    flex: 1;

    @media ${props => props.theme.breakpoints.laptop} {
        flex-basis: 100%;
        padding-top: 0;
    }
`;

const Side = styled.div`
    flex-basis: 20%;
    margin-left: 50px;

    @media ${props => props.theme.breakpoints.laptop} {
        flex-basis: 100%;
        margin-left: 20px;
        margin-top: 100px;
        padding-top: 100px;
        border-top: solid 1px white;
    }
`;

const Section = styled.section`
    position: relative;
    padding: 0 20px;

    & + & {
        margin-top: 100px;
    }

    h2 {
        font-size: 28px;
        line-height: 38px;
        font-weight: 400;
        margin-bottom: 40px;
    }

    h3 {
        font-size: 22px;
        line-height: 32px;
        font-weight: 400;
        color: ${props => props.theme.colors.yellow};
        margin-bottom: 32px;
    }

    ul {
        padding-left: 20px;
    }

    li {
        font-size: 16px;
        line-height: 24px;
    }

    li + li {
        margin-top: 24px;
    }

    a {
        color: ${props => props.theme.colors.heavenly};
    }

    img {
        display: inline-block;
        width: 100%;
        max-width: 300px;
    }

    &::before {
        content: "";
        position: absolute;
        left: -160px;
        top: 12px;
        width: 150px;
        height: 3px;
        background-color: ${props => props.theme.colors.yellow};
    }
`;

const Grid = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const Col = styled.div`
    flex: 1;

    & + & {
        margin-left: 30px;
    }

    @media ${props => props.theme.breakpoints.tablet} {
        flex-basis: 100%;

        & + & {
            margin-left: 0;
        }
    }
`;

const Images = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const LinkStyled = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-transform: uppercase;
    text-decoration: none;
    text-align: right;

    &:hover {
        text-decoration: underline;
    }

    img {
        margin-left: 16px;
    }

    & + & {
        margin-top: 6px;
    }

    @media ${props => props.theme.breakpoints.laptop} {
        justify-content: flex-start;
    }
`;