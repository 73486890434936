import {db} from '../utils/firebase';
import {useEffect} from 'react';

export default function useFirebasePresence(user) {
  useEffect(() => {
    const reference = db.ref(`/online/${user.onekey}`);

    reference.set(true).then(() => console.log('Online presence set'));

    reference
      .onDisconnect()
      .remove()
      .then(() => console.log('On disconnect function configured.'));

    return () => db.ref(`/online/${user.onekey}`).remove();
  }, []);
}
