import React from 'react';
import styled from 'styled-components';

const SubContextualMenu = ({children}) => {
  return <Container>{children}</Container>;
};

export default SubContextualMenu;

const Container = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 100%;
  padding-left: 4px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  div:hover > &,
  div:active > & {
    visibility: visible;
    opacity: 1;
  }
`;
