import React from 'react';
import styled from 'styled-components';
import type {ParticipantProps} from './types';
import {useVideoboxDimensions} from '../ParticipantGrid/GroupGridProvider';
import Screen from './Screen';

type ScreenItemProps = ParticipantProps;

export default function ScreenItem(props: ScreenItemProps) {
  const [containerWidth, containerHeight] = useVideoboxDimensions();

  return (
    <Container width={containerWidth} height={containerHeight}>
      <Screen smallBox={containerWidth < 250} {...props} />
    </Container>
  );
}

const Container = styled.div<{width: number; height: number}>`
  position: relative;
  display: flex;
  padding: 10px;

  align-items: center;
  justify-content: center;

  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;
