import React from 'react';
import styled, {keyframes} from 'styled-components';

import Commands from './Commands';
import {ScreenBoxProps} from '../types';
import useFirebaseVal from '../../../../../hooks/useFirebaseVal';

export default function VideoBox({
  owner,
  isLocalVideo,
  hasVideo,
  // onPin,
  // isPinned,
  // handRaised,
  fullscreenRef,
  fullscreen,
  commands,
  children,
}: ScreenBoxProps) {
   const [user] = useFirebaseVal(`users/${owner}`);
  const userName = user?.name;

  return (
    <VideoContainer $hasVideoTrack={hasVideo} ref={fullscreenRef}>
      {children}
      {/* {recording.isRecording && (
        <Recording onClick={recording.stop}>
          <span>REC</span>
          <IconRecord fill="#fff" size="22px" />
        </Recording>
      )}
      {localRecording && (
        <LocalRecording>
          <span>REC</span>
          <IconRecord fill="#fff" size="22px" />
        </LocalRecording>
      )} */}

      {!fullscreen.isFullscreen && (
        <VideoOver>
          <Commands commands={commands} color="#fff" size="22px" />
        </VideoOver>
      )}

      <StatusContainer>
        <VideoOwnerName>
          {isLocalVideo ? 'Stai presentando' : userName + ' sta presentando'}
        </VideoOwnerName>
      </StatusContainer>
    </VideoContainer>
  );
}

const VideoContainer = styled.div<{$hasVideoTrack: boolean}>`
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 100%;
  max-height: 100%;

  > div:first-child {
    height: 100%;
  }

  video {
    width: 100%;
    height: auto;
    max-height: 100%;
    border-radius: 5px;
  }

  ${(props) =>
    props.$hasVideoTrack
      ? `background-color: transparent;`
      : `background-color: rgba(0,0,0,0.5); width: 100%; height: 100%;`}
`;

const RecordingAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const Recording = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 1;
  background-color: #ffffff30;
  border-radius: 4px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color ease 0.3s;

  &:hover {
    background-color: #ffffff50;
  }

  span {
    margin-right: 8px;

    font-size: 14px;
    white-space: nowrap;
    font-weight: 300;
  }

  i {
    animation: ${RecordingAnimation} infinite linear 2s;
  }
`;

const LocalRecording = styled(Recording)`
  pointer-events: none;
`;

const StatusContainer = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100%;
  left: 0;
  right: 0;
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 1;
  opacity: 0;
  transition: opacity ease 300ms;

  ${VideoContainer}:hover & {
    opacity: 1;
  }
`;

const VideoOwnerName = styled.span`
  display: inline-block;
  width: auto;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 12px;
    padding: 4px 6px;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  text-align: center;
  color: white;
  background-color: ${(props) => props.theme.colors.neutral};
  font-size: 27px;
  text-transform: uppercase;
  width: 20%;
  max-width: 90px;
  min-width: 40px;
  border-radius: 50%;

  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }

  span {
    position: absolute;
    padding-top: 8px;
  }
`;

const VideoOver = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  width: auto;
  align-items: center;
  justify-content: center;
  z-index: 1;
  opacity: 0;
  padding: 4px;
  border-radius: 5px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity ease 300ms;

  ${VideoContainer}:hover & {
    opacity: 1;
    pointer-events: initial;
  }
`;
