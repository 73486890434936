import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {darken} from 'polished';
import {IconSend} from '../Icons';
import {ChatMessage} from '.';
import {format} from 'date-fns';

// {messages, onSend, me, getParticipantById} = props
const Chat = ({myIdentity, messages, onSend}) => {
  const [message, setMessage] = useState('');
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const chatListRef = useRef(null);

  // when receiving a new message, scroll bottom is the user has not scrolled up, else show a "new messages" button
  useEffect(() => {
    if (
      chatListRef.current.scrollTop >=
      chatListRef.current.scrollHeight - chatListRef.current.offsetHeight - 90
    ) {
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
      setHasNewMessage(false);
    } else {
      setHasNewMessage(true);
    }
  }, [messages]);

  // if we scroll down with the "new messages" button hide it
  useEffect(() => {
    const ref = chatListRef.current;
    const fn = () => {
      if (
        chatListRef.current.scrollTop >=
        chatListRef.current.scrollHeight - chatListRef.current.offsetHeight - 90
      ) {
        setHasNewMessage(false);
      }
    };

    chatListRef.current.addEventListener('scroll', fn);

    return () => {
      ref.removeEventListener('scroll', fn);
    };
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      onSend(message);
      setMessage('');
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    },
    [onSend, message]
  );

  const scrollBottom = () => {
    chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    setHasNewMessage(false);
  };

  return (
    <Container>
      <Body ref={chatListRef}>
        {messages.map((message) => (
          <ChatMessage
            key={message.id}
            // mine={message.user === me}
            mine={myIdentity === message.user}
            // author={getParticipantById(message.user)}
            author={message.author || message.user}
            text={message.message}
            date={format(message.date, 'HH:mm')}
          />
        ))}
      </Body>

      <Footer onSubmit={handleSubmit}>
        {hasNewMessage && (
          <NewMessagesBtn onClick={() => scrollBottom()}>
            Nuovi messaggi
          </NewMessagesBtn>
        )}
        <input
          type="text"
          name="message"
          autoComplete="off"
          placeholder="Scrivi un messaggio"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button disabled={message === ''} type="submit">
          <IconSend fill="white" />
        </button>
      </Footer>
    </Container>
  );
};

export default Chat;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 44px);
`;

const Body = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
`;

const Footer = styled.form`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: #f7f7f7;
    position: relative;

    input {
        width: 100%;
        margin-right: 16px;
        padding: 14px;
        border-radius: 8px;
        border: none;
        background-color: white;
        font-size: 14px;
        line-height: 14px;
        outline: none;

        &:focus {
            box-shadow: 0 0 0 2px ${(props) => props.theme.colors.primary.first};
        }

        &::placeholder {
            color: #d3d3d3;
        }
    }

    button {
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        display: flex;
        border-radius: 50%;
        border: none;
        color: white;
        align-items: center;
        justify-content: center;
        background-color: ${(props) => props.theme.colors.primary.first};
        cursor: pointer;
        transition: all ease 0.2s;
        user-select: none;

        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &:hover {
            background-color: ${(props) =>
                darken(0.1, props.theme.colors.primary.first)};
        }
    }
`;

const NewMessagesBtn = styled.div`
  position: absolute;
  transform: translateY(-110%);
  top: 0;
  left: 0;
  right: 0;
  appearance: none;
  display: inline-block;
  text-align: center;
  width: 100%;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.primary.first};
  border: none;
  padding: 8px 16px;
  color: #fff;
  box-shadow: 0px 2px 7px rgba(0,0,0,0.1);
  cursor: pointer;
`;
