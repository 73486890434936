import React, {useRef, useEffect} from 'react';

import {AudioTrack, VideoTrack} from '../../types/video-room';

interface ParticipantMediaProps {
  className?: string;
  videoTrack?: VideoTrack;
  audioTrack?: AudioTrack;
}

export default function ParticipantMedia(props: ParticipantMediaProps) {
  const container = useRef<HTMLDivElement>(null);
  const video = useRef<HTMLMediaElement>();
  const audio = useRef<HTMLMediaElement>();

  useEffect(() => {
    if (props.videoTrack) {
      video.current = props.videoTrack.attach();

      if (video.current) {
        container.current?.appendChild(video.current);
      }

      props.videoTrack.on(
        'stopped',
        () => video.current && video.current.remove()
      );
    }
  }, [props.videoTrack]);

  useEffect(() => {
    if (props.audioTrack) {
      audio.current = props.audioTrack.attach();

      if (audio.current) {
        container.current?.appendChild(audio.current);
      }

      props.audioTrack.on(
        'stopped',
        () => audio.current && audio.current.remove()
      );
    }
  }, [props.audioTrack]);

  return <div className={props.className} ref={container}></div>;
}
