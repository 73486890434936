import styled from 'styled-components';

export const HeadingL = styled.h1`
  margin: 0 0 16px;
  font-size: 40px;
  line-height: 60px;

  ${(props) => props.light && `font-weight: 400;`}

  @media ${(props) => props.theme.breakpoints.phone} {
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 10px;
  }
`;

export const HeadingM = styled.h2`
  margin: 0 0 16px;
  font-size: 27px;
  line-height: 44px;

  @media ${(props) => props.theme.breakpoints.phone} {
    line-height: 1.2;
    font-size: 23px;
    margin-bottom: 10px;
  }

  ${(props) => props.light && `font-weight: 400;`}
`;

export const HeadingS = styled.h3`
  margin: 0 0 16px;
  font-size: 22px;
  line-height: 24px;

  @media ${(props) => props.theme.breakpoints.phone} {
    font-size: 20px;
    margin-bottom: 10px;
  }

  ${(props) => props.light && `font-weight: 400;`}
`;

export const HeadingXS = styled.h3`
  margin: 0 0 16px;
  font-size: 18px;
  line-height: 28px;

  @media ${(props) => props.theme.breakpoints.phone} {
    margin-bottom: 10px;
  }

  ${(props) => props.light && `font-weight: 400;`}

  ${(props) => props.$color && `color: ${props.$color};`}
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 23px;
  color: ${(props) => props.theme.colors.bodyText};
  margin: 0 0 16px;
`;

export const TextS = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.bodyText};
  margin: 0 0 16px;
`;

export const Subtitle = styled(Text)`
  margin-bottom: 30px;
`;

export const Label = styled.label`
  display: inline-block;
  width: 100%;
  margin-left: 16px;
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${(props) => props.theme.colors.label};
`;

export const Nbsp = styled.div`
  display: inline-block;
  white-space: nowrap;
`;
