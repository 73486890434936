import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';
import IconArrow from './icon-arrow-down-white.svg';

type ContextualMenuProps = PropsWithChildren<{
  left?: boolean;
  right?: boolean;
}>;

const ContextualMenu = ({children, left, right}: ContextualMenuProps) => {
  return (
    <Container $left={left} $right={right}>
      <Inside>{children}</Inside>
    </Container>
  );
};

export default ContextualMenu;

const Container = styled.div<{$left?: boolean; $right?: boolean}>`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 15px;
  z-index: 0;
  display: none;

  &:hover,
  &:active {
    display: block;
  }

  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 30px;
    background-image: url(${IconArrow});
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: calc(100% - 20px);
    left: calc(50% - 25px);
    z-index: 0;
  }

  button:hover > &,
  button:active > & {
    visibility: visible;
    opacity: 1;
    display: block;
  }

  ${(props) =>
    props.$left &&
    `
      left: 0;
      transform: translateX(0);
      &::after {
        left: 0;
      }
    `}
  ${(props) =>
    props.$right &&
    `
      left: inherit;
      right: 0;
      transform: translateX(0);
      &::after {
        left: inherit;
        right: 0;
      }
    `}
`;

const Inside = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
`;
