import React, {PropsWithChildren} from 'react';
import styled, {css} from 'styled-components';
import {darken} from 'polished';

type ButtonControlProps = PropsWithChildren<{
  invert: boolean;
  icon?: React.ComponentType;
  iconColor?: string;
  onClick?: () => void;
  transparent?: boolean;
  label?: string;
  toggle?: boolean;
  count?: number;
  tooltip?: string;
}>;

const ButtonControl = ({
  children,
  invert,
  icon,
  iconColor,
  onClick,
  transparent,
  label,
  toggle = false,
  count = 0,
  tooltip,
}: ButtonControlProps) => {
  const IconComp = icon || React.Fragment;
  let iconFill = iconColor || null;

  if (invert) {
    iconFill = 'white';
  }

  return (
    <Container
      $label={label ? label : ''}
      $invert={invert ? invert : false}
      $transparent={transparent ? transparent : false}
      $color={iconFill}
      $toggle={toggle}
      onClick={onClick}
      $tooltip={tooltip}
      title={tooltip}
    >
      {count > 0 && <Count>{count}</Count>}
      <IconComp fill={iconFill} />
      {label && <span>{label}</span>}
      {children}
    </Container>
  );
};

export default ButtonControl;

const Container = styled.button<{
  $invert: boolean;
  $label: string;
  $transparent: boolean;
  $color: string;
  $toggle: boolean;
  $tooltip?: string;
}>`
  position: relative;
  width: ${(props) => (props.$label ? 'auto' : '70px')};
  height: 40px;
  background-color: white;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid transparent;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.2s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  @media ${(props) => props.theme.breakpoints.phone} {
    width: 44px;
  }

  span {
    color: ${(props) => props.$color};
    font-size: 15px;
    margin-left: 5px;
    @media ${(props) => props.theme.breakpoints.phone} {
      display: none;
    }
  }

  & + button {
    margin-left: 24px;
    @media ${(props) => props.theme.breakpoints.tablet} {
      margin-left: 12px;
    }
  }

  &:hover {
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.15);
  }

  ${(props) =>
    props.$invert &&
    css`
      background-color: ${props.theme.colors.secondary.decline};

      &:hover {
        background-color: ${darken(0.1, props.theme.colors.secondary.decline)};
      }

      ${Count} {
        background-color: #fff;
        color: ${props.theme.colors.secondary.decline};
      }
    `}

  ${(props) =>
    props.$invert &&
    props.$toggle &&
    css`
      background-color: ${props.theme.colors.secondary.success};

      &:hover {
        background-color: ${darken(0.1, props.theme.colors.secondary.success)};
      }

      ${Count} {
        background-color: #fff;
        color: ${props.theme.colors.secondary.success};
      }
    `}

  ${(props) =>
    props.$transparent &&
    css`
      background-color: transparent;

      &:hover {
        background-color: ${darken(0.1, props.theme.colors.secondary.decline)};
      }
    `}
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  font-size: 11px;
  font-weight: 700;
  color: #fff;
  background-color: ${(props) => props.theme.colors.secondary.decline};
  position: absolute;
  right: 5px;
  bottom: 5px;
`;
