import React from 'react';
import styled, {css} from 'styled-components';
import Linkify from 'react-linkify';
import {lighten} from 'polished';

interface ChatMessageProps {
  mine?: boolean;
  author: string;
  date: string;
  text: string;
}

const ChatMessage = ({mine, author, date, text}: ChatMessageProps) => {
  return (
    <Container $mine={mine}>
      <Head>
        <Author>{author}</Author>
        <Date>{date}</Date>
      </Head>
      <Body>
        <Linkify componentDecorator={linkDecorator}>{text}</Linkify>
      </Body>
    </Container>
  );
};

const linkDecorator = (
  decoratedHref: string,
  decoratedText: string,
  key: number
): React.ReactNode => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={decoratedHref} key={key}>
      {decoratedText}
    </a>
  );
};

export default ChatMessage;

const Container = styled.div<{$mine?: boolean}>`
  position: relative;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #f6f6f6;

  & + & {
    margin-top: 20px;
  }

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: solid 13px transparent;
    border-top-color: #f6f6f6;
    border-right-color: #f6f6f6;
    position: absolute;
    top: 0;
    left: -20px;
  }

  ${(props) =>
    props.$mine &&
    css`
      background-color: ${(props) => lighten(0.35, props.theme.colors.primary.first)};

      &::after {
        right: -20px;
        left: initial;
        border-top-color: ${(props) => lighten(0.35, props.theme.colors.primary.first)};
        border-left-color: ${(props) => lighten(0.35, props.theme.colors.primary.first)};
        border-right-color: transparent;
      }

      ${Head} {
        flex-direction: row-reverse;
      }
      ${Body}  {
        text-align: right;
      }
    `}
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
`;

const Author = styled.span`
  display: inline-block;
  font-weight: 700;
  font-size: 15px;
   color: #000;
`;

const Date = styled.span`
  display: inline-block;
  margin: 0 10px;
  font-weight: 300;
  font-size: 12px;
  font-style: italic;
  color: ${(props) => props.theme.colors.elements.chatMine};
`;

const Body = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: #000;

  a {
    color: ${(props) => props.theme.colors.primary.first};
  }
`;
