import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import memoize from 'fast-memoize';
import styled, {css} from 'styled-components';

const VideoBoxDimensionContext = React.createContext([251, 251]);

interface GroupGridProps {
  videoCount: number;
  aside: boolean;
  sidebar: boolean;
  children: React.ReactNode;
  isPinned?: boolean;
}

export default function GroupGridProvider({
  videoCount,
  aside,
  sidebar,
  isPinned = false,
  children,
}: GroupGridProps) {
  const [dimensions, setDimensions] = useState([251, 251]);

  const containerRef = useRef<HTMLDivElement>();

  const dimCalculation = useCallback(() => {
    if (!containerRef.current) {
      return;
    }
    const {clientWidth: width, clientHeight: height} = containerRef.current;

    const {newWidth, newHeight} = calcBest(width, height, videoCount);

    if (newWidth === dimensions[0] && newHeight === dimensions[1]) {
      return;
    }

    setDimensions([newWidth, newHeight]);
  }, [videoCount, aside, dimensions, sidebar]);

  useEffect(() => {
    dimCalculation();

    if (!window.ResizeObserver) {
      const interval = setInterval(dimCalculation, 50);

      window.addEventListener('resize', dimCalculation);

      return () => {
        clearInterval(interval);
        window.removeEventListener('resize', dimCalculation);
      };
    }

    const observer = new ResizeObserver((entries) => {
      dimCalculation();
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [dimCalculation]);

  return (
    <VideoBoxDimensionContext.Provider value={dimensions}>
      <Container $aside={aside} $pinned={isPinned} ref={containerRef}>
        <Content canScroll={!isPinned}>{children}</Content>
      </Container>
    </VideoBoxDimensionContext.Provider>
  );
}

export const useVideoboxDimensions = () => useContext(VideoBoxDimensionContext);

const calcBest = memoize(function calcBest(
  width: number,
  height: number,
  count: number
) {
  const squared = calcIterative(width, height, count, S11);
  const A1 = squared.newHeight * squared.newWidth;

  const ratio169 = calcIterative(width, height, count, S169);
  const A2 = ratio169.newHeight * ratio169.newWidth;
  const ratio43 = calcIterative(width, height, count, S43);
  const A3 = ratio43.newHeight * ratio43.newWidth;

  if (A1 > A2) {
    if (A1 > A3) {
      // console.log('Meglio quadrato');
      return squared;
    } else {
      // console.log('Meglio 4:3');
      return ratio43;
    }
  } else {
    if (A2 > A3) {
      // console.log('Meglio 16:9');
      return ratio169;
    } else {
      // console.log('Meglio 4:3');
      return ratio43;
    }
  }
});

function calcIterative(
  width: number,
  height: number,
  count: number,
  ratio: number
) {
  let calculatedHeight = 1;
  let newWidth = 0;
  do {
    newWidth++;

    let perRow = Math.floor(width / newWidth);
    let rows = Math.ceil(count / perRow);
    calculatedHeight = rows * (newWidth / ratio);

    // console.log({
    //   newWidth,
    //   rows,
    //   calculatedHeight,
    //   width,
    //   height,
    // });
  } while (calculatedHeight <= height);

  newWidth--;

  console.log({
    ratio,
    newWidth,
    newHeight: newWidth / ratio,
  });

  return {
    newWidth,
    newHeight: newWidth / ratio,
  };
}

const S43 = 4 / 3;
const S169 = 16 / 9;
const S11 = 1;

const Container = styled.div<{$aside: boolean; $pinned: boolean}>`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: hidden;

  width: ${(props) => (props.$pinned ? '70%' : '100%')};
  height: 100%;

  @media ${(props) => props.theme.breakpoints.header} {
    width: 100%;
  }

  ${(props) =>
    props.$aside &&
    css`
      width: 30%;
      @media ${(props) => props.theme.breakpoints.header} {
        flex-flow: row wrap;
        align-items: center;
        height: calc(
          100% - ${(props) => props.theme.layout.roomHeader} -
            ${(props) => props.theme.layout.roomFooter}
        );
        width: 100%;
      }
    `};
`;

const Content = styled.div<{canScroll: boolean}>`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;

  ${(props) =>
    props.canScroll &&
    css`
      overflow-y: auto;
    `};
`;