import styled from 'styled-components';

import CirclesImage from '../assets/images/circles-pattern.svg';
import DotsImage from '../assets/images/dots-pattern.svg';

export const Circles = styled.div`
    width: 1600px;
    height: 1600px;
    background-image: url(${CirclesImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(calc(-50% - 950px), -1000px);
    pointer-events: none;

    @media ${props => props.theme.breakpoints.header} {
        display: none;
    }
`;

export const Dots = styled.div`
    width: 150px;
    height: 100px;
    background-image: url(${DotsImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    top: 150px;
    left: 0;
    pointer-events: none;

    @media ${props => props.theme.breakpoints.landscape} {
        display: none;
    }
`;

export const Divider = styled.div`
    width: 110px;
    height: 3px;
    background-color: ${props => props.theme.colors.yellow};
    margin: 20px 0;
`;