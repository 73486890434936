import React from 'react';
import styled, {css} from 'styled-components';
import {spin} from '../Animations';
import {darken, lighten} from 'polished';

const Button = (props) => {
  const {type, title, color, ...rest} = props;
  return (
    <Container type={type || 'button'} color={color} {...rest}>
      {title}
    </Container>
  );
};

export default Button;

// Utils for Button
const loadingSize = 16;
const loadingBorder = 2;

export const Container = styled.button`
  overflow: hidden;
  padding: 10px 24px;
  border-radius: 5px;
  border: solid 2px transparent;
  background-color: ${(props) => props.theme.colors.elements.cta};
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  white-space: nowrap;
  outline: none;
  transition: all ease 0.2s;
  cursor: pointer;

  /* Button next other button */
  & + & {
    margin-left: 20px;
  }

  /* STATUS */
  /* Hover status */
  @media (hover: hover) {
    &:hover:not(:disabled) {
      background-color: ${(props) => props.theme.colors.elements.ctaHover};
      color: #fff;
    }
  }

  &:focus {
    background-color: ${(props) => props.theme.colors.elements.ctaHover};
  }

  /* Active status */
  &:active:active {
    background-color: ${(props) => props.theme.colors.elements.ctaHover};
    color: #fff;
  }

  /* STYLES */
  /* full width */
  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `}

  /* Small button */
  ${(props) =>
    props.small &&
    css`
      font-size: 16px;
      line-height: 16px;
      padding: 6px 16px;
    `}

  /* Rounded button */
  ${(props) =>
    props.rounded &&
    css`
      border-radius: 30px;
    `}

  /* Disabled button */
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `}

  /* Loading button */
  ${(props) =>
    props.loading &&
    css`
      position: relative;
      text-indent: 300%;
      pointer-events: none;
      cursor: default;

      &::after {
        content: '';
        display: block;
        width: ${loadingSize}px;
        height: ${loadingSize}px;
        border: solid ${loadingBorder}px rgba(255, 255, 255, 0.5);
        border-bottom-color: white;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - ${loadingSize / 2 + loadingBorder}px);
        left: calc(50% - ${loadingSize / 2 + loadingBorder}px);
        animation: ${spin} linear infinite 0.5s;
      }
    `}

    /* TYPES */
    /* Secondary button */
  ${(props) =>
    props.secondary &&
    css`
      background-color: ${(props) => props.theme.colors.neutral};

      @media (hover: hover) {
        &:hover:not(:disabled) {
          background-color: ${(props) =>
            darken(0.1, props.theme.colors.neutral)};
        }
      }

      &:active:active {
        background-color: ${(props) => darken(0.2, props.theme.colors.neutral)};
      }
    `}

    /* Custom color button */
    ${(props) =>
    props.color &&
    css`
      color: white;
      background-color: ${props.color};

      @media (hover: hover) {
        &:hover:not(:disabled) {
          background-color: ${darken(0.1, props.color)};
        }
      }

      &:active:active {
        background-color: ${darken(0.2, props.color)};
      }
    `}

     /* Outline button */
    ${(props) =>
    props.outline &&
    css`
      color: ${props.theme.colors.main};
      background-color: white;
      border-color: ${props.theme.colors.main};

      @media (hover: hover) {
        &:hover:not(:disabled) {
          background-color: white;
          color: ${darken(0.1, props.theme.colors.main)};
          border-color: ${darken(0.1, props.theme.colors.main)};
        }
      }

      &:active:active {
        background-color: white;
        color: ${darken(0.2, props.theme.colors.main)};
        border-color: ${darken(0.2, props.theme.colors.main)};
      }
    `}

    ${(props) =>
    props.outline &&
    props.loading &&
    css`
      &::after {
        border-color: ${lighten(0.3, props.theme.colors.main)};
        border-bottom-color: ${darken(0.2, props.theme.colors.main)};
      }
    `};
`;
