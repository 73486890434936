import styled, {css} from 'styled-components';
import iconCheck from '../../assets/icon-check.svg';

export const PageWithBackground = styled.div`
  display: block;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
`;

export const Page = styled.div`
  display: block;
  width: 100%;
  min-height: 100vh;
  position: relative;
  @media (max-width: 1024px) {
    padding-bottom: 40px;
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 65%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  @media (max-width: 1024px) {
    width: 90%;
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 25px 0;
  }
`;
export const Card = styled.div<{$small?: boolean}>`
  display: block;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  width: 65%;
  padding: 24px;
  position: relative;
  z-index: 1;
  @media (max-width: 1024px) {
    width: 100%;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  ${(props) =>
    props.$small &&
    css`
      width: 32%;
      margin-right: 3%;
      align-self: flex-start;
      @media (max-width: 1024px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 24px;
      }
    `}
`;

export const LinkButton = styled.div`
  display: inline-block;
  appearance: none;
  font-weight: 700;
  text-transform: uppercase;
  background-color: ${(props) => props.theme.colors.success};
  color: #fff;
  margin-top: 16px;
  margin: 16px auto 0;
  width: 100%;
  text-align: center;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 1.7rem;
  letter-spacing: 1px;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 400ms;

  &:hover {
    background-color: ${(props) => props.theme.colors.blue.ctaHover};
  }

  a {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 16px 30px;
  }
`;

export const Footer = styled.footer`
  display: block;
  position: absolute;
  right: 12px;
  bottom: 12px;
  font-size: 12px;
  z-index: 2;
`;

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const FormElement = styled.div<{$third?: boolean}>`
  display: block;
  position: relative;

  ${(props) =>
    props.$third &&
    css`
      flex: 0 0 33%;
      @media (max-width: 1024px) {
        flex: 0 0 100%;
      }
    `}

  label {
    font-size: 1.2rem;
    font-weight: 700;
    color: #717171;
    text-transform: uppercase;
  }

  input {
    width: 100%;
    margin-top: 4px;
    padding: 10px;
    border: 1px solid #bebebe;
    border-radius: 3px;
    font-size: 1.6rem;
    font-family: 'Kumbh Sans', sans-serif;
    margin-bottom: 24px;
  }

  button {
    display: inline-block;
    appearance: none;
    font-weight: 700;
    text-transform: uppercase;
    background-color: ${(props) => props.theme.colors.success};
    color: #fff;
    margin-top: 16px;
    margin: 16px auto 0;
    width: 100%;
    text-align: center;
    font-family: 'Kumbh Sans', sans-serif;
    font-size: 1.7rem;
    letter-spacing: 1px;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    padding: 16px 30px;
    transition: background-color 400ms;

    &:hover {
      background-color: #69c324;
    }

    &:focus {
      outline: none;
    }
  }

  input[type='radio']:checked + img {
    border: 3px solid ${(props) => props.theme.colors.success};
  }
  input[type='radio']:checked + img + .radioButtonCheck {
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: ${(props) => props.theme.colors.success};
    z-index: 2;
    right: 8px;
    top: 12px;
    background-image: url(${iconCheck});
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
  }

  img {
    border: 3px solid #ffffff;
    border-radius: 3px;
    margin-top: 4px;
    cursor: pointer;
  }

  .radioButton {
    input {
      position: absolute;
      z-index: -1;
    }
  }
`;

export const PreRoomCards = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  @media (max-width: 1024px) {
    padding: 0;
  }
`;
export const PreRoomCard = styled.div`
  display: block;
  width: 40%;
  background-color: #fff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  padding: 24px;
  position: relative;
  z-index: 1;
  margin: 0 auto 0 0;
  align-self: stretch;
  color: black;

  @media (max-width: 1024px) {
    width: 90%;
    margin: 0 auto;
    align-self: flex-start;
    border-radius: 0 0 8px 8px;
  }
`;

export const PreRoomCardHeader = styled.div`
  display: block;
  width: 40%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  padding: 10px 24px;
  margin: 20px auto;
  position: relative;
  z-index: 1;
  @media (max-width: 1024px) {
    width: 90%;
  }

  img {
    width: 100px;
    height: auto;
  }
`;
