import React, {PropsWithChildren} from 'react';
import styled, {css} from 'styled-components';
import {ChildContextualMenu} from '.';
import IconArrow from './icon-arrow-black.svg';
import IconSelected from './icon-check-black.svg';

type ContextualMenuItemProps = PropsWithChildren<{
  title: string;
  icon?: React.ComponentType;
  onClick?: Function;
  selected?: boolean;
  textRed?: boolean;
}>;

const ContextualMenuItem = ({
  children,
  icon: IconComp,
  title,
  onClick,
  selected,
  textRed,
}: ContextualMenuItemProps) => {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  };

  let content = null;

  if (children) {
    content = <ChildContextualMenu>{children}</ChildContextualMenu>;
  }

  const hasChildMenu = !!content;

  return (
    <Container onClick={handleClick} $textRed={textRed ? textRed : false}>
      <Inner>
        {IconComp && <IconComp />}
        {title}
        {hasChildMenu && <Icon src={IconArrow} />}
        {content}
        {selected && <Icon src={IconSelected} />}
      </Inner>
    </Container>
  );
};

export default ContextualMenuItem;

const Container = styled.div<{$textRed: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  font-size: 13px;
  white-space: nowrap;
  transition: all ease 0.2s;
  z-index: 1;

  ${(props) =>
    props.$textRed &&
    css`
      color: ${props.theme.colors.secondary.decline};
    `}

  &:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:hover {
    background-color: #f9f9f9;
  }

  & + & {
    border-top: 1px solid #eaeaea;
  }
`;

const Inner = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  padding: 8px 12px;
  align-items: center;
  justify-content: space-between;

  svg {
    margin-right: 6px;
    width: 20px;
    height: auto;
  }

  > div {
    > div > div {
      max-width: 160px;
      text-overflow: ellipsis;
      display: block;
    }
  }
`;

const Icon = styled.img`
  margin-left: 10px;
`;
