import {useCallback, useEffect, useState} from 'react';
import type {MutableRefObject} from 'react';

const requestAction = (function () {
  if (typeof window === 'undefined') {
    return null;
  }

  const element = document.body;

  if (element.requestFullscreen) {
    return 'requestFullscreen';
  }

  // @ts-ignore
  if (element.mozRequestFullScreen) {
    return 'mozRequestFullScreen';
  }
  // @ts-ignore
  if (element.webkitRequestFullScreen) {
    return 'webkitRequestFullScreen';
  }

  return null;
})();

const isSupported = !!requestAction;

export type FullscreenHook = {
  isSupported: boolean;
  isFullscreen: boolean;
  start: () => void;
  stop: () => void;
  toggle: () => void;
};

export default function useFullscreen(
  elementRef: MutableRefObject<HTMLElement>
) {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const start = useCallback(async () => {
    if (!isSupported) {
      console.error('Fullscreen is not supported');
      return;
    }

    if (!elementRef.current) {
      console.error('Element does not exist');
      return;
    }

    try {
      await elementRef.current[requestAction]();
      setIsFullscreen(true);
    } catch (err) {
      alert(
        `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
      );
    }
  }, [elementRef]);

  const stop = useCallback(() => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  }, []);

  const toggle = useCallback(() => {
    if (document.fullscreenElement) {
      stop();
    } else {
      start();
    }
  }, [stop, start]);

  const handleFullscreenExit = useCallback(() => {
    if (!document.fullscreenElement) {
      setIsFullscreen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenExit);
    return () =>
      document.removeEventListener('fullscreenchange', handleFullscreenExit);
  }, [handleFullscreenExit]);

  return {
    isSupported,
    isFullscreen,
    start,
    stop,
    toggle,
  };
}
