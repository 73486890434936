import storage from 'local-storage-fallback'
import React, { useContext, useEffect, useState } from 'react';
import useFirebaseVal from '../hooks/useFirebaseVal';
import Base from '../layout/Base';

import Login from '../pages/Login';
import Loading from '../pages/Loading';
import { trackEvent, trackUser } from '../utils/tracking';

const KEY = 'userkey';

export const UserContext = React.createContext();

export const UserProvider = (props) => {
  const [userId, setUserId] = useState(() => storage.getItem(KEY));
  const [user] = useFirebaseVal(`users/${userId}`);

  const handleLogin = data => {
    storage.setItem(KEY, data.onekey);
    setUserId(data.onekey);
  }

  const handleLogout = data => {
    storage.removeItem(KEY);
    setUserId(null);
  }


  useEffect(() => {
    if(!user) {
      return;
    }

    trackUser(user.email);
    trackEvent('user_logged');
  }, [user]);

  return (
    <UserContext.Provider value={{user, handleLogin, handleLogout}}>
      {
        user === undefined ? <Base><Loading /></Base> :
        user === null ? <Base><Login /></Base> :
        props.children
      }
    </UserContext.Provider>
  )
};


export const useUser = () => useContext(UserContext);