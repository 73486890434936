import React from 'react';
import styled from 'styled-components';

const COLORS = ['#175829', '#208B40', '#7EB746'];
let color = COLORS[Math.floor(Math.random() * COLORS.length)];

export default function Avatar(props) {
  return (
    <AvatarStyled randomColor={props.randomColor} small={props.small}>
      {getInitials(props.name)}
      {props.tooltip && <AvatarToolTip>{props.name}</AvatarToolTip>}
    </AvatarStyled>
  );
}

function getInitials(name) {
  if (!name) {
    return 'ND';
  }

  return name
    .split(' ')
    .map((s) => s.trim())
    .filter(Boolean)
    .slice(0, 2)
    .map((word) => word.charAt(0));
}

const AvatarStyled = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 16px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.grayscale.white};
  background-color: ${(props) => props.theme.colors.primary.third};

  ${(props) =>
    props.randomColor &&
    `
    background-color: '${color}';
  `}

  ${(props) =>
    props.small &&
    `
        width: 24px;
        height: 24px;
        font-size: 10px;
    `};
`;

const AvatarToolTip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 105%;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 6px;
  background-color: ${(props) => props.theme.colors.grayscale.medGrayOne};
  border-radius: 30px;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  white-space: nowrap;
  text-transform: lowercase;
  color: ${(props) => props.theme.colors.grayscale.white};
  max-width: 80px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  pointer-events: none;
  transition: opacity ease 0.3s;
  opacity: 0;

  ${AvatarStyled}:hover & {
    opacity: 1;
  }
`;
