import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';

export const ButtonSubmit = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  padding: 0 16px;
  border-radius: 6px;
  border: solid 1px transparent;
  background-color: ${(props) => props.theme.colors.elements.cta};
  font-weight: 500;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 24px;
  color: ${(props) => props.theme.colors.grayscale.white};
  cursor: pointer;

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.elements.ctaHover};
  }

  ${(props) =>
    props.disabled &&
    `
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export const LoginLink = styled(Link)`
  padding: 0 16px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  border: solid 2px currentColor;
  border-radius: 30px;
  color: ${(props) => props.theme.colors.grayscale.white};
  transition: all ease 0.3s;

  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.colors.primary.second};
      background-color: ${(props) => props.theme.colors.grayscale.white};
    }
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    color: ${(props) => props.theme.colors.primary.second};
    font-size: 14px;
    line-height: 14px;
    height: 32px;
  }
`;

export const ButtonWithLabel = styled.button`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border: none;
  border-radius: 30px;
  color: ${(props) => props.theme.colors.grayscale.white};
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  transition: background-color ease 0.3s;
  background-color: ${(props) => props.theme.colors.primary.second};
  transition: all ease 0.3s;

  &:hover {
    background-color: ${(props) => props.theme.colors.elements.ctaHover};
  }

  span {
    display: inline-block;
    margin-left: 8px;
  }
`;

export const ButtonCircle = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 1px ${(props) => props.theme.colors.grayscale.greyOne};
  line-height: 24px;
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.primary.first};
  background-color: currentColor;
  cursor: pointer;
  transition: all ease 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonControl = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: solid 1px ${(props) => props.theme.colors.grayscale.greyOne};
  line-height: 24px;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.grayscale.white};
  background-color: currentColor;
  cursor: pointer;
  transition: all ease 0.3s;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.2);
    }
  }

  &:active {
    transform: scale(1.05);
  }

  color: ${(props) =>
    props.active
      ? props.theme.colors.grayscale.black
      : props.theme.colors.grayscale.white};
  color: ${(props) =>
    props.decline
      ? props.theme.colors.secondary.decline
      : props.theme.colors.grayscale.white};

  ${(props) =>
    props.alert &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 7px;
        right: 8px;
        display: block;
        width: 9px;
        height: 9px;
        background-color: ${(props) => props.theme.colors.secondary.decline};
        border: solid 2px ${(props) => props.theme.colors.grayscale.white};
        border-radius: 50%;
      }
    `}

  &::after {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 12px;
    pointer-events: none;
    transition: all ease 0.3s;
    transform: translateX(-50%);
    background-color: ${(props) => props.theme.colors.grayscale.white};
    color: ${(props) => props.theme.colors.grayscale.black};
    user-select: none;
    opacity: 0;

    ${(props) =>
      props.tooltip &&
      css`
        content: '${props.tooltip}';
      `}

    ${(props) =>
      props.right &&
      `
      left: initial;
      right: 0;
      transform: translateX(0);
    `}

    ${(props) =>
      props.decline &&
      `
      background-color: ${props.theme.colors.secondary.decline};
      color: ${props.theme.colors.grayscale.white};
    `}

    @media ${(props) => props.theme.breakpoints.tablet} {
      left: 50%;
      right: initial;
      transform: translateX(-50%);
    }
  }

  @media (hover: hover) {
    &:hover::after {
      opacity: 1;
    }
  }
`;
