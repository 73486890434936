import {useState} from 'react';
import styled, { keyframes } from 'styled-components';
import {Link} from 'react-router-dom';
import {Circles, Divider} from './GraphicElements';
import {Text} from './Typografy';
import {PeopleList, PeopleSingle} from './PeopleList';
import Modal from './Modal';
import {Button} from './Button';

import {createFilePath} from '../helpers/calendar';

// Images
import IconPlay from '../assets/images/icon-play.png';
import useFirebaseVal from '../hooks/useFirebaseVal';
import { useUser } from '../contexts/UserContext';
import { trackEvent } from '../utils/tracking';

const PreEvent = props => {
    const [modal, setModal] = useState(false);
    const {user} = useUser();
    const [booked, setBooked] = useFirebaseVal(`events/${props.label}/${user.onekey}`);

    const {status = 'coming'} = props;

    const createHtmlCode = (html) => {
        return {__html: html};
    }

    const bookWebinar = () => {
        trackEvent('register_' + props.label);
        // Do booking stuff
        setBooked(true);
        setModal(false);
    }

    const handleSaveFile = () => {
        trackEvent('calendar_' + props.label);
        createFilePath(props.date, props.label);
    };

    return (
        <Container>
            <Wrapper>
                <CirclesStyled />
                <Grid>
                    <Content>
                        <Label>{props.label}</Label>
                        <Title dangerouslySetInnerHTML={createHtmlCode(props.title)} />
                        <Divider />
                        <Text>{props.description}</Text>
                        <Action>
                            <ActionIcon
                                src={status === 'done' ? IconPlay : props.icon}
                                alt={props.label}
                            />
                            <ActionContent>
                                {status === 'coming' && (
                                    <>
                                        <p>{props.comingLabel}</p>
                                        {booked ? (
                                            <SlideComponent>
                                                <button style={{ color: '#00EBFF'}} onClick={handleSaveFile}>Scarica il calendar</button>
                                            </SlideComponent>
                                        ) : (
                                            <>
                                                <button onClick = {() => setModal(true)}>Registrati</button>
                                                {props.availables > 0 && <Counter>Posti disponibili: {props.availables}</Counter>}
                                            </>
                                        )}
                                    </>
                                )}
                                {status === 'active' && (
                                    <>
                                        <Link to="/webinar-room">Partecipa</Link>
                                        <p>{props.disclaimer}</p>
                                    </>
                                )}
                                {status === 'done' &&
                                    <Link to={props.linkToOldSession}>Rivedi la sessione</Link>}
                            </ActionContent>
                        </Action>
                    </Content>
                    <Right>
                        <PeopleList>
                            {props.people.map((person, i) => {
                                return (
                                    <PeopleSingle key={i}>
                                        <img src={person.image} alt={person.name} />
                                        <span>{person.title}<br />{person.name}</span>
                                    </PeopleSingle>
                                );
                            })}
                        </PeopleList>
                    </Right>
                </Grid>
            </Wrapper>
            <Modal
                title="Registrati"
                onClose={() => setModal(false)}
                active={modal}
            >
                <p>Conferma la tua registrazione all'evento.</p>
                <Button outlined onClick={() => setModal(false)}>Annulla</Button>
                <Button onClick={bookWebinar}>Registrati</Button>
            </Modal>
        </Container>
    );
}

export default PreEvent;

const slide = keyframes`
    from {
        transform: translateX(-50px);
    }

    to {
        transform: translateX(0px);
    }
`;

const SlideComponent = styled.span`
    display: inline-block;
    animation: ${slide} .2s ease-in-out;
`;

const Container = styled.div`
    width: 100%;
    margin: 0 auto 0;
`;

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    padding-top: 200px;
    padding-bottom: 50px;

    @media ${props => props.theme.breakpoints.landscape} {
        padding-top: 50px;
    }
`;

const CirclesStyled = styled(Circles)`
    top: -100px;
    bottom: initial;
    transform: translateX(-30px) rotate(10deg);
`;

const Grid = styled.div`
    max-width: ${props => props.theme.sizes.mw};
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const Content = styled.div`
    width: 55%;

    @media ${props => props.theme.breakpoints.laptop} {
        width: 80%;
    }

    @media ${props => props.theme.breakpoints.tablet} {
        width: 100%;
    }
`;

const Right = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`;

const Label = styled.span`
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    color: ${props => props.theme.colors.yellow};
`;

const Title = styled.h2`
    font-size: 70px;
    line-height: 90px;
    font-weight: 400;
    margin: 20px 0;

    @media ${props => props.theme.breakpoints.laptop} {
        font-size: 38px;
        line-height: 48px;
    }
`;

const Action = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 90px 0;
`;

const ActionIcon = styled.img`
    display: block;
    width: 115px;
    margin-right: 24px;
`;

const ActionContent = styled.div`
    p {
        margin: 0 0 16px;
        font-size: 28px;
        line-height: 32px;
    }

    button {
        display: inline-block;
        width: auto;
        text-align: left;
    }

    button, span, a {
        color: ${props => props.theme.colors.yellow};
        font-size: 32px;
        line-height: 44px;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        padding: 0;
    }

    button, a {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const Counter = styled.p`
    font-size: 15px !important;
    line-height: 20px !important;
    margin: 20px 0 0 !important;
    color: white !important;
    text-transform: none !important;
`;