/* eslint-disable react-hooks/rules-of-hooks */
import {useCallback, useEffect} from 'react';

export default function useExitConfirmation() {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  const handleUnload = useCallback((e) => {
    // Cancel the event
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = '';
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);

    return () => window.removeEventListener('beforeunload', handleUnload);
  }, [handleUnload]);
}
