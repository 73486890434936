import React, {useCallback, useState} from 'react';
import useFirebaseVal from '../hooks/useFirebaseVal';

// Images
import {format} from 'date-fns';

export default function Data() {
  const [joined] = useFirebaseVal('joined');
  const [events] = useFirebaseVal('events');
  const [online] = useFirebaseVal('online');
  const [domande] = useFirebaseVal('domande');
  const [chat] = useFirebaseVal('chat-sala-comune');

  return (
    <div style={{color: '#000'}}>
      <h1>Dati</h1>

      <strong>Iscritti:</strong>
      <ul>
        {Object.entries(events || {}).map(([event, obj]) => (
          <li key={event}>{event}: {Object.values(obj).length}</li>
        ))}
      </ul>

      <strong>Online: {Object.values(online || {}).length}</strong>
      <br />
      <strong>Entrati: {Object.values(joined || {}).length}</strong>
      <br />
      <strong>Domande: {Object.values(domande || {}).length}</strong>
      <br />

      <h4>Domande:</h4>
      <ol>
        {Object.values(domande || {}).map((q) => (
          <li key={q.date}>
            <strong>{q.user}</strong>: {q.message}
          </li>
        ))}
      </ol>

      <h4>Chat:</h4>
      <ol>
        {Object.values(chat || {}).map((q) => (
          <li key={q.date}>
            <strong>
              [{format(q.date, 'H:mm')}] {q.user}
            </strong>
            : {q.message}
          </li>
        ))}
      </ol>
    </div>
  );
}
