import styled from 'styled-components';

import CheckIcon from '../assets/images/icon-check.svg';

export const FormContainer = styled.div`
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 40px 0;

    @media ${props => props.theme.breakpoints.tablet} {
        border: none;
        padding: 20px 0px 50px;
    }
`;

export const Form = styled.form`

`;

export const Label = styled.label`
    display: block;
    margin-bottom: 16px;
`;

export const Input = styled.input`
    width: 100%;
    height: 50px;
    padding: 0 16px;
    line-height: 24px;
    font-size: 16px;
    margin-bottom: 24px;
    border: solid 2px white;
    outline: none;
    font-family: Montserrat, sans-serif;

    &:focus {
        border-color: ${props => props.theme.colors.yellow};
    }
`;

export const FormButton = styled.button`
    width: 100%;
    padding: 16px;
    text-align: center;
    color: white;
    background-color: ${props => props.theme.colors.yellow};
    border: solid 1px transparent;
    outline: none;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    color: ${props => props.theme.colors.blue};
    transition: background-color ease .2s;

    &:focus {
        border-color: white;
    }

    &:hover:not(:disabled) {
        cursor: pointer;
        background-color: ${props => props.theme.colors.yellowHover};
    }
`;

export const FormMessage = styled.p`
    padding: 16px;
    line-height: 24px;
    border: solid 1px white;
`;

export const CheckboxWrapper = styled.div`
    margin-bottom: 24px;
    position: relative;

    input[type="checkbox"] {
        width: 1px;
        height: 1px;
        appearance: none;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 12px;
        left: 10px;
    }

    label {
        display: inline-block;
        position: relative;
        font-size: 14px;
        line-height: 22px;
        padding-left: 32px;
        cursor: pointer;
        margin: 0;
    }

    label::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        border: solid 2px transparent;
        background-color: white;
        background-image: url(${CheckIcon});
        background-position: center;
        background-size: 0;
        background-repeat: no-repeat;
        padding: 0;
    }

    input:checked + label::before {
        background-size: 100%;
        background-color: ${props => props.theme.colors.yellow};
        border-color: ${props => props.theme.colors.yellow};
    }
    
    label:hover::before,
    input:focus + label::before {
        border-color: ${props => props.theme.colors.yellow};
    }
`;