import React, {useState} from 'react';
import styled from 'styled-components';

import {Link} from 'react-router-dom';

// Images
import LogoImage from '../assets/images/logo-small.png';

import {
    FormContainer,
    Form,
    Label,
    Input,
    FormMessage,
    CheckboxWrapper
} from '../components/FormElements';

import {Button} from '../components/Button';
import useFirebaseVal from '../hooks/useFirebaseVal';
import { useUser } from '../contexts/UserContext';

const Resources = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [users] = useFirebaseVal('users');

    const userContext = useUser();

    const handleSubmit = e => {
        e.preventDefault();

        setLoading(true);
        setError(false);

        const user = Object.values(users).find(users => users.email.toLowerCase() === email.toLocaleLowerCase());

        if(!user)  {
            setLoading(false);
            setError(true);
            return;
        }

        userContext.handleLogin(user);
    }

    return (
        <Container>
            <Inner>
                <Logo>
                    <img src={LogoImage} alt="Change Maker" />
                </Logo>
                <Title>Accedi</Title>
                <center>
                    <p>Inserisca la sua email per accedere alla piattaforma.</p>
                </center>
                <FormContainer>
                    <Form action="#" onSubmit={handleSubmit}>
                        <Label htmlFor="email">Email</Label>
                        <Input id="email" name="email" type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" required />
                        {/* <Label htmlFor="password">Password</Label>
                        <Input id="password" name="password" type="password" placeholder="Password" /> */}
                        <CheckboxWrapper>
                            <input name="privacy" type="checkbox" id="privacy" required />
                            <label htmlFor="privacy">Dichiaro di aver preso visione e di accettare l'<a href="https://pro.novonordisk.it/privacy-policy/informativa-sul-trattamento-dei-dati-personali.html" target="_blank" rel="nofollow noreferrer">Informativa sulla privacy</a></label>
                        </CheckboxWrapper>
                        <Button wide type="submit" disabled={loading}>{loading ? 'Caricamento...' : 'Accedi'}</Button>
                        {error && (
                            <FormMessage>
                                Utente non trovato
                            </FormMessage>
                        )}
                        {/* <p>
                            <Link to="/signin">Registrati</Link>
                        </p> */}
                    </Form>
                </FormContainer>
            </Inner>
        </Container>
    );
}

export default Resources;

const Container = styled.div`
    width: 100%;
`;

const Inner = styled.div`
    width: 100%;
    max-width: ${props => props.theme.sizes.mw};
    margin: 0 auto;
    padding: 100px 20px;
    min-height: calc(100vh - 137.44px);

    @media ${props => props.theme.breakpoints.tablet} {
        min-height: 100%;
    }
`;

const Logo = styled.div`
    width: 300px;
    margin: 0 auto 100px;
    
    img {
        display: block;
        width: 100%;
    }
`;

const Title = styled.h2`
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.theme.colors.yellow};
`;