import React, { useState } from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";

import PreEvent from '../components/PreEvent';
import Webinar from '../components/Webinar';

// Images
import IconLeadership from '../assets/images/icon-leadership.png';
import BerraImage from '../assets/images/medici/cesare-berra.png';
import MontaliImage from '../assets/images/medici/gian-paolo-montali.png';
import VideoPoster from '../assets/images/poster.jpg';

// Videos
import Video from '../assets/videos/video.mp4';

const DATA = {
    label: "Leadership",
    title: "Affina le tue abilità di&nbsp;leader.",
    description: "Lo scopo di questo webinar è potenziare le tue caratteristiche di leader generativo, grazie all’aiuto di formatori scelti.",
    icon: IconLeadership,
    comingLabel: "Appuntamento all’8 settembre dalle 17 alle 19.",
    videos: null,
    downloads: null,
    availables: 0,
    status: 'done',
    date: [
        '2021-09-08',
    ],
    people: [
        {
            title: 'Gian Paolo',
            name: 'Montali',
            image: MontaliImage,
        },
        {
            title: 'Dott.',
            name: 'Cesare Berra',
            image: BerraImage,
        }
    ],
}

const Leadership = () => {
    let { path, url } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <PreEvent
                    linkToEvent="#"
                    linkToOldSession={`${url}/webinar`}
                    {...DATA}
                />
            </Route>
            <Route path={`${path}/webinar`}>
                <Webinar
                    {...DATA}
                />
            </Route>
        </Switch>
    );
}

export default Leadership;