import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';
import {HeadingXS} from '../../Typography';

type HeaderProps = PropsWithChildren<{
  title: string;
  small?: boolean;
}>;

const Header = ({title, children, small}: HeaderProps) => {
  return (
    <VideoRoomSidebarHeaderContainer $small={small}>
      <HeadingXS>{title}</HeadingXS>
      {children}
    </VideoRoomSidebarHeaderContainer>
  );
};

export default Header;

export const VideoRoomSidebarHeaderContainer = styled.div<{$small: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;
  min-height: 43px;
  color: #000;

  ${(props) =>
    props.$small &&
    `
      ${HeadingXS} {
        font-size: 18px;
      }
    `}
`;
