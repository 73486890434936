import styled, { keyframes, css } from 'styled-components';

const VideoBackground = ({ videoUrl, moved }) => {
    return (
        <VideoContainer moved={moved}>
            <video autoPlay loop playsInline muted>
                <source src={videoUrl} type="video/mp4" />
            </video>
        </VideoContainer>
    );
};

export default VideoBackground;

const fadeIn = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

const VideoContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
  animation: ${fadeIn} ease-in-out 0.8s;

  ${(props) =>
        props.moved &&
        css`
      left: 40%;

      @media (max-width: 1024px) {
        left: 0;
      }
    `}

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 1px;
    -webkit-border-radius: 1px;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 1px;
    -webkit-border-radius: 1px;
  }
`;
