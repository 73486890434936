import React, {useCallback, useEffect, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import FirstLetters from 'first-letters';
import {darken} from 'polished';

import {ParticipantBoxProps} from '../types';
import Commands from './Commands';
import ThemeDecorations from './ThemeDecorations';
import {
  IconMicOff,
  IconCamOff,
  IconRecord,
  IconHand,
  IconMenu,
  IconClose,
  IconPlaceholder,
} from '../../../Icons';

export default function VideoBoxSmall({
  owner,
  isMute,
  isLocalVideo,
  hasVideo,
  // onPin,
  // isPinned,
  // handRaised,
  fullscreenRef,
  fullscreen,
  commands,
  recording,
  children,
  theme,
  participantNumber,
}: ParticipantBoxProps) {
  const nameFirstLetters = FirstLetters(owner || '');
  const [menuOpen, setMenu] = useState(false);

  const handleMenu = useCallback(() => {
    setMenu((menuOpen) => !menuOpen);
  }, []);

  useEffect(() => {
    if (!menuOpen) {
      return;
    }

    document.body.addEventListener('click', handleMenu);

    return () => document.body.removeEventListener('click', handleMenu);
  }, [menuOpen, handleMenu]);

  return (
    <VideoContainer
      $hasVideoTrack={hasVideo}
      ref={fullscreenRef}
      $isFullscreen={fullscreen.isFullscreen}
    >
      {children}
      {recording.isRecording && (
        <Recording onClick={recording.stop}>
          <span>REC</span>
          <IconRecord fill="#fff" size="22px" />
        </Recording>
      )}
      {!recording.isRecording && recording.isRecorded && (
        <LocalRecording>
          <span>REC</span>
          <IconRecord fill="#fff" size="22px" />
        </LocalRecording>
      )}
      {/*
      {handRaised && (
        <HandRaised>
          <IconHand fill="#fff" size="22px" />
        </HandRaised>
      )} */}
      {!hasVideo && (
        <Placeholder>
          <span>
            <IconPlaceholder size="50px" />
          </span>
        </Placeholder>
      )}

      {/* <MenuBtn onClick={() => handleMenu()} $open={menuOpen}>
        {menuOpen ? <IconClose fill="#fff" /> : <IconMenu fill="#fff" />}
      </MenuBtn>
      <VideoOver $open={menuOpen}>
        <VideoOwnerName>
          {isLocalVideo ? owner + ' (Tu)' : owner}
        </VideoOwnerName>
        <Commands commands={commands} color="#000" size="18px" inline />
      </VideoOver> */}

      <StatusContainer>
        {isMute && (
          <IconContainer>
            <IconMicOff fill="#fff" size="20px" />
          </IconContainer>
        )}
        {!hasVideo && (
          <IconContainer>
            <IconCamOff fill="#fff" size="20px" />
          </IconContainer>
        )}
      </StatusContainer>
    </VideoContainer>
  );
}

const VideoContainer = styled.div<{
  $hasVideoTrack: boolean;
  $isFullscreen: boolean;
}>`
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 100%;
  max-height: 100%;

  > div:first-child {
    height: 100%;
  }

  video {
    position: absolute;
    top: 0px;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
    transform: translate(-50%, 0px);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 10px;

    @media ${(props) => props.theme.responsive.desktopLarge} {
      width: 100%;
    }
  }

  ${(props) =>
    props.$hasVideoTrack
      ? `background-color: transparent;`
      : `background-color: rgba(0,0,0,0.5); width: 100%; height: 100%;`}

  ${(props) =>
    props.$isFullscreen &&
    `
      video {
        width: 100%;
        height: 100%;
      }
    `}
`;

const HandRaisedAlert = keyframes`
  0% {
    background-color: black;
  }
  50% {
    background-color: #208B40;
  }
  100% {
    background-color: black;
  }
`;

const RecordingAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const Recording = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 1;
  color: #fff;
  background-color: ${(props) => props.theme.colors.secondary.decline};
  border-radius: 4px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color ease 0.3s;

  &:hover {
    background-color: ${(props) =>
      darken(0.1, props.theme.colors.secondary.decline)};
  }

  span {
    margin-right: 8px;

    font-size: 14px;
    white-space: nowrap;
    font-weight: 300;
  }

  i {
    animation: ${RecordingAnimation} infinite linear 2s;
  }
`;

const LocalRecording = styled(Recording)`
  pointer-events: none;
`;

const HandRaised = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-start;
  top: 5px;
  left: 5px;
  max-width: 100%;
  padding: 4px 8px;
  border-radius: 6px;
  animation: ${HandRaisedAlert} ease-in-out 3s infinite;

  span {
    display: block;
    margin-left: 8px;
    font-size: 14px;
    line-height: 24px;
    color: white;
  }
`;

const StatusContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  padding: 12px 3% 10px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
`;

const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) =>
    darken(0.1, props.theme.colors.secondary.decline)};
  margin-right: 6px;

  &:last-child {
    margin-right: 0;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    background-color: transparent;
    width: 26px;
    height: 26px;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  text-align: center;
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 27px;
  text-transform: uppercase;
  width: 20%;
  max-width: 90px;
  min-width: 50px;
  border-radius: 50%;

  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }

  span {
    position: absolute;
    padding-top: 8px;
  }

  @media ${(props) => props.theme.breakpoints.phone} {
    font-size: 20px;
    span {
      padding-top: 4px;
    }
  }
`;

const MenuBtn = styled.button<{$open: boolean}>`
  appearance: none;
  border: none;
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 40px;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0;
  z-index: 3;
  transition: opacity ease 300ms;

  &:focus {
    outline: none;
  }

  ${VideoContainer}:hover & {
    opacity: 1;
  }

  ${(props) => props.$open && `opacity: 1;`}

  @media ${(props) => props.theme.breakpoints.tablet} {
    opacity: 1;
  }
`;

const VideoOver = styled.div<{$open: boolean}>`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: auto;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 12px 45px 6px 6px;
  border-radius: 5px;
  min-height: 50px;
  color: #fff;
  background-color: #fff;
  opacity: 0;
  box-shadow: 0px 0px 5px ${(props) => props.theme.colors.shadow};
  transition: opacity ease 300ms;

  ${(props) => props.$open && `opacity: 1;`}
`;

const VideoOwnerName = styled.span`
  display: inline-block;
  width: auto;
  color: #000;
  font-size: 14px;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media ${(props) => props.theme.breakpoints.tablet} {
    background-color: transparent;
  }

  ${/* sc-sel */ VideoOver} & {
    width: 100%;
    padding-top: 6px;
    padding-left: 3px;
    text-align: left;
  }
`;
