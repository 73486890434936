// @ts-nocheck
import {useState, useEffect, useCallback} from 'react';
import { useUser } from '../../contexts/UserContext';
import {VideoRoomContextType} from '../contexts/VideoRoomContext';
import {ChatMessage} from '../types/video-room';

export default function useChat(
  room: VideoRoomContextType,
  trackNewMessages: boolean
) {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [hasNewMessages, setHasNewMessages] = useState(0);
  const {user} = useUser();

  const onMessage = useCallback((participant, message: ChatMessage) => {
    setHasNewMessages((n) => n + 1);
    setMessages((messages) => messages.concat(message));
  }, []);

  const sendMessage = useCallback(
    (text) => {
      const message: ChatMessage = {
        id: Date.now(),
        date: Date.now(),
        user: room.room.localParticipant.identity,
        author: user.name || room.room.localParticipant.name,
        message: text,
      };

      setMessages((messages) => messages.concat(message));
      room.twilioWrapper.sendCommand({type: 'chat', payload: message});
    },
    [room, setMessages]
  );

  useEffect(() => {
    room.twilioWrapper.addListener('chat', onMessage);

    return () => room.twilioWrapper.removeListener('chat', onMessage);
  }, [room, onMessage]);

  useEffect(() => {
    if (trackNewMessages === false) {
      setHasNewMessages(0);
    }
  }, [messages, trackNewMessages]);

  return {
    messages,
    sendMessage,
    hasNewMessages,
  };
}
