import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import LogoImage from '../assets/images/logo-novo.svg';

const Footer = () => {
    const { pathname } = useLocation();

    if (pathname === '/webinar-room' || pathname.includes('/room')) {
        return null;
    }

    return (
        <Container>
            <Inner>
                <Logo src={LogoImage} alt="Novo Nordisk" />
                <Content>
                    <p>Materiale destinato unicamente agli operatori sanitari. Vietata la distribuzione o l’esposizione al pubblico.</p>
                    <p>
                        <a 
                            href="https://pro.novonordisk.it/privacy-policy/informativa-sul-trattamento-dei-dati-personali.html"
                            target="_blank"
                            rel="noreferrer nofollow"
                        >
                        Informativa sulla privacy
                        </a>
                    </p>
                </Content>
            </Inner>
        </Container>
    );
}

export default Footer;

const Container = styled.footer`
    width: 100%;
    padding: 0 20px;
`;

const Inner = styled.div`
    width: 100%;
    max-width: ${props => props.theme.sizes.mw};
    margin: 0 auto;
    border-top: solid 1px white;
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Logo = styled.img`
    display: block;
    width: 80px;
`;

const Content = styled.div`
    text-align: right;
    font-size: 16px;
    line-height: 24px;

    p {
        margin: 0;
    }

    a {
        color: ${props => props.theme.colors.heavenly};
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;