import React, {useState} from 'react';
import styled, {keyframes} from 'styled-components';
import {Divider} from './GraphicElements';
import {Text} from './Typografy';
import VideoPlayer from './VideoPlayer';

// Images
import BurgerIconImage from '../assets/images/icon-burger.svg';
import DownloadIcon from '../assets/images/icon-download.svg';
import PlayVideoIcon from '../assets/images/icon-play-video.svg';

const Webinar = ({ label, title, description, videos, downloads}) => {
    const [currentVideoIdx, setCurrentVideoIdx] = useState(0);
    const [videoTiming, setVideoTiming] = useState(0);
    
    const createHtmlCode = (html) => {
        return { __html: html };
    }

    const handleChangeVideo = (index) => {
        setCurrentVideoIdx(index);
        setVideoTiming(0);
    }

    const handleChangeTiming = (timing) => {
        setVideoTiming(timing);
    }

    return (
        <Container>
            <Inner>
                <Table>
                    <Main>
                        <Burger />
                        {(!videos || videos.length === 0) && <h2>Il video del webinar sarà caricato a breve.</h2>}
                        {videos && videos.length > 0 &&
                            <VideoPlayer 
                                video={videos[currentVideoIdx].video} 
                                poster={videos[currentVideoIdx].poster}
                                timing={videoTiming} 
                            />}
                    </Main>
                    <Sidebar>
                        <Label>{label}</Label>
                        <Title dangerouslySetInnerHTML={createHtmlCode(title)} />
                        <Divider />
                        <Text>{description}</Text>
                        {videos && videos.length > 0 && (
                            <VideoList>
                                {videos.map((video, videoIdx) => (
                                    <div>
                                        <VideoLink
                                            key={videoIdx}
                                            active={currentVideoIdx === videoIdx}
                                            onClick={() => handleChangeVideo(videoIdx)}
                                        >
                                            <VideoLinkPoster>
                                                <img src={video.poster} alt="play video" />
                                            </VideoLinkPoster>
                                            {video.title}
                                        </VideoLink>
                                        {currentVideoIdx === videoIdx && video.times && (
                                            <VideoTimesMenu>
                                                {video.times && video.times.map((time, i) => (
                                                    <span key={i} onClick={() => handleChangeTiming(time.timing)}>
                                                        <img src={time.poster} alt={time.title} />
                                                        {time.title}
                                                    </span>
                                                ))}
                                            </VideoTimesMenu>
                                        )}
                                    </div>
                                ))}
                            </VideoList>
                        )}
                        {downloads && downloads.length > 0 && (
                            <Downloads>
                                {downloads.map((download, downloadIdx) => (
                                    <Download href={download.file} download key={downloadIdx}>
                                        <img src={DownloadIcon} alt="Scarica" />
                                        <span>{download.label}</span>
                                    </Download>
                                ))}
                            </Downloads>
                        )}
                    </Sidebar>
                </Table>
            </Inner>
        </Container>
    );
}

export default Webinar;

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    margin-bottom: 100px;
    margin-top: 50px;
`;

const Inner = styled.div`
    width: 100%;
    max-width: ${props => props.theme.sizes.mw};
    margin: 0 auto;
    padding: 0 20px;
`;

const Table = styled.div`
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const Main = styled.div`
    position: relative;
    flex: 3 0 0;
    padding: 70px 50px 20px;
    padding-left: 0;

    &::before {
        content: "";
        width: calc(100% - 50px);
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        background-color: ${props => props.theme.colors.yellow};
    }

    @media ${props => props.theme.breakpoints.landscape} {
        flex: 1 0 100%;
        padding: 40px 0;
        order: 2;

        &::before {
            width: 100%;
        }
    }
`;

const Sidebar = styled.div`
    position: relative;
    flex: 1 0 0;
    padding-top: 70px;
    padding-left: 50px;

    &::before {
        content: "";
        height: calc(100% - 50px);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 1px;
        background-color: white;
    }

    @media ${props => props.theme.breakpoints.landscape} {
        flex: 1 0 100%;
        padding: 0;
        order: 1;
        margin-bottom: 40px;

        &::before {
            content: none;
        }
    }
`;

const Label = styled.span`
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: ${props => props.theme.colors.yellow};
`;

const Title = styled.h3`
    font-size: 38px;
    line-height: 48px;
    font-weight: 400;
    margin: 16px 0 0;
`;

const Downloads = styled.div`
    margin-top: 100px;
    margin-bottom: 20px;
`;

const Download = styled.a`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;

    & + & {
        margin-top: 20px;
    }

    &:hover {
        text-decoration: underline;
    }

    img {
        display: block;
        width: 24px;
        margin-right: 10px;
    }

    span {
        font-size: 14px;
        line-height: 20px;
    }
`;

const VideoList = styled.div`
    margin-top: 50px;

    * + * {
        margin-top: 16px;
    }
`;

const VideoLink = styled.button`
    display: block;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: inherit;
    font-family: inherit;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: inherit;
    color: ${props => props.theme.colors.yellow};
    font-weight: ${props => props.active ? '700' : 'inherit'};
    text-align: left;
    line-height: 1.5;

    &:hover {
        text-decoration: underline;
    }
`;

const VideoLinkPoster = styled.div`
    width: 100px;
    position: relative;
    margin-right: 10px;
    border-radius: 4px;
    overflow: hidden;
    flex-shrink: 0;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.2);
        background-image: url(${PlayVideoIcon});
        background-position: center;
        background-size: 24px;
        background-repeat: no-repeat;
    }

    img {
        display: block;
        width: 100px;
    }
`;

const VideoTimesMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 16px;
    border-left: dashed 1px ${props => props.theme.colors.yellow};

    img {
        display: block;
        width: 60px;
        margin-right: 10px;
        border-radius: 4px;
    }

    span {
        position: relative;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        &::after {
            content: "";
            position: absolute;
            left: -20px;
            top: calc(50% - 4px);
            height: 8px;
            width: 8px;
            display: block;
            background-color: ${props => props.theme.colors.yellow};
            border-radius: 50%;
        }

        &:hover {
            text-decoration: underline;
        }
    }
`;

const Burger = styled.button`
    width: 50px;
    height: 50px;
    background-image: url(${BurgerIconImage});
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    outline: none;
    position: absolute;
    top: -25px;
    right: -25px;
    z-index: 1;

    @media ${props => props.theme.breakpoints.landscape} {
        display: none;
    }
`;