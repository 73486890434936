import styled, {css} from 'styled-components';

export const Page = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: ${(props) => props.theme.colors.blue};
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  @media ${(props) => props.theme.breakpoints.tablet} {
    height: calc(var(--vh, 1vh) * 100);
  }
`;

export const PageInside = styled.div<{$short: boolean}>`
  width: 100%;
  height: 100%;
  margin: 0 auto 0 0;
  padding-top: 0;
  padding-bottom: 60px;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
  box-sizing: border-box;

  ${(props) =>
    props.$short &&
    css`
      width: calc(100% - 370px);
      @media ${(props) => props.theme.breakpoints.tablet} {
        width: 100%;
      }
    `}

  @media ${(props) => props.theme.breakpoints.header} {
    padding: ${(props) => props.theme.layout.roomHeader} 0
      ${(props) => props.theme.layout.roomFooter};
    flex-direction: column;
  }

  @media ${(props) => props.theme.breakpoints.phone} {
    padding: ${(props) => props.theme.layout.roomHeader} 10px
      ${(props) => props.theme.layout.roomFooter};
    height: 100%;
  }
`;
