import React from 'react';
import {ButtonControl} from '../Buttons';
import {IconMic, IconMicOff, IconCam, IconCamOff} from '../Icons';
import {ContextualMenu, ContextualMenuItem} from '../ContextualMenu';

type VideoControlsProps = {
  enabledCamera: boolean;
  enabledMic: boolean;
  videoDevices: MediaDeviceInfo[];
  audioDevices: MediaDeviceInfo[];
  openMic: (deviceId?: string) => Promise<any>;
  closeMic: () => Promise<void>;
  openCamera: (deviceId?: string) => Promise<any>;
  closeCamera: () => Promise<void>;
};

const VideoControls = ({
  enabledCamera,
  enabledMic,
  videoDevices,
  audioDevices,
  openMic,
  closeMic,
  openCamera,
  closeCamera,
}: VideoControlsProps) => {
  return (
    <>
      <ButtonControl
        icon={enabledMic ? IconMic : IconMicOff}
        onClick={enabledMic ? closeMic : openMic}
        invert={!enabledMic}
      >
        <ContextualMenu>
          {audioDevices.length > 0 && (
            <ContextualMenuItem title="Seleziona microfono" icon={IconMic}>
              {audioDevices.map((device) => (
                <ContextualMenuItem
                  key={device.deviceId}
                  title={device.label}
                  selected={null}
                  onClick={() => openMic(device.deviceId)}
                />
              ))}
            </ContextualMenuItem>
          )}
          <ContextualMenuItem
            textRed
            title={enabledMic ? 'Muta microfono' : 'Attiva microfono'}
            onClick={enabledMic ? closeMic : openMic}
          />
        </ContextualMenu>
      </ButtonControl>
      <ButtonControl
        icon={enabledCamera ? IconCam : IconCamOff}
        iconColor={'black'}
        invert={!enabledCamera}
        onClick={enabledCamera ? closeCamera : openCamera}
      >
        <ContextualMenu>
          {videoDevices.length > 0 && (
            <ContextualMenuItem title="Seleziona camera" icon={IconCam}>
              {videoDevices.map((device) => (
                <ContextualMenuItem
                  key={device.deviceId}
                  title={device.label}
                  selected={null}
                  onClick={() => openCamera(device.deviceId)}
                />
              ))}
            </ContextualMenuItem>
          )}
          <ContextualMenuItem
            textRed
            title={enabledCamera ? 'Disattiva camera' : 'Attiva camera'}
            onClick={enabledCamera ? closeCamera : openCamera}
          />
        </ContextualMenu>
      </ButtonControl>
    </>
  );
};

export default VideoControls;
