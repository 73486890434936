import React, {useState} from 'react';
import styled from 'styled-components';
import { Link, NavLink} from "react-router-dom";
import { useLocation } from "react-router-dom";
import User from './User';

// Images
import BurgerIconImage from '../assets/images/icon-burger.svg';
import LogoImage from '../assets/images/logo-small.png';
import { useUser } from '../contexts/UserContext';

const Header = () => {
    const {user} = useUser();

    const [menuIsVisible, setMenuIsVisible] = useState(false);

    const { pathname } = useLocation();

    if (pathname === '/login' ||
        pathname === '/signin' ||
        pathname === '/webinar-room' ||
        pathname.includes('/room') ||
        pathname === '/loading') {
        return null;
    }

    return (
        <Container>
            <Inner>
                <Link to="/">
                    <Logo src={LogoImage} alt="Change Maker" />
                </Link>
                <MenuConteiner visible={menuIsVisible}>
                    <Menu>
                        <ul>
                            <li onClick={() => setMenuIsVisible(false)}>
                                <NavLink activeClassName="active" to="/innovazione">Innovazione</NavLink>
                            </li>
                            <li onClick={() => setMenuIsVisible(false)}>
                                <NavLink to="/comunicazione">Comunicazione</NavLink>
                            </li>
                            <li onClick={() => setMenuIsVisible(false)}>
                                <NavLink to="/leadership">Leadership</NavLink>
                            </li>
                            {/* <LiColored onClick={() => setMenuIsVisible(false)}>
                                <NavLink to="/resources">Resources</NavLink>
                            </LiColored> */}
                        </ul>
                    </Menu>
                    <User name={user.name} />
                </MenuConteiner>
                <Burger onClick={() => setMenuIsVisible(!menuIsVisible)} />
            </Inner>
        </Container>
    );
}

export default Header;

const Burger = styled.button`
    width: 40px;
    height: 40px;
    background-color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: none;
    outline: none;
    background-image: url(${BurgerIconImage});
    background-position: center;
    background-size: 90%;
    background-repeat: no-repeat;
    cursor: pointer;
    display: none;
    margin-left: 40px;

    @media ${props => props.theme.breakpoints.header} {
        display: block;
    }
`;

const Container = styled.header`
    width: 100%;
`;

const Inner = styled.div`
    position: relative;
    width: 100%;
    max-width: ${props => props.theme.sizes.mw};
    margin: 0 auto;
    padding: 60px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Logo = styled.img`
    display: block;
    width: 250px;
    margin: auto;

    @media ${props => props.theme.breakpoints.tablet} {
        width: 200px;
    }
`;

const Menu = styled.menu`
    margin: 0 50px;
    margin-right: auto;
    margin-left: auto;
    padding: 0;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        display: inline-block;
    }

    li + li {
        margin-left: 60px;
    }

    a {
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
            text-decoration: underline;
        }
    }

    a.active {
        color: ${props => props.theme.colors.yellow};
        text-decoration: underline;
    }
`;

const LiColored = styled.li`
    a {
        color: ${props => props.theme.colors.heavenly};
        text-decoration: underline;
    }
`;

const MenuConteiner = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;


    @media ${props => props.theme.breakpoints.header} {
        display: ${props => props.visible ? 'block' : 'none'};
        justify-content: flex-start;

        position: absolute;
        top: 100%;
        left: 0;
        background-color: ${props => props.theme.colors.blue};
        border: none;
        border-top: solid 1px white;
        border-bottom: solid 1px white;
        width: 100%;
        height: 100%;
        padding: 40px;
        z-index: 9;
        height: auto;

        menu {
            margin-bottom: 20px;
        }

        li {
            display: block;
        }

        li + li {
            margin-left: 0;
            margin-top: 20px;
        }
    }
`;