import React, {ButtonHTMLAttributes} from 'react';
import styled, {css} from 'styled-components';
import {spin} from '../Animations';

interface ButtonInlineProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  loading?: boolean;
}

const ButtonInline = ({title, loading, ...rest}: ButtonInlineProps) => {
  return (
    <Container $loading={loading} {...rest}>
      {title}
    </Container>
  );
};

export default ButtonInline;

// Utils for Button
const loadingSize = 10;
const loadingBorder = 2;

const Container = styled.button<{$loading?: boolean}>`
  overflow: hidden;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  outline: none;
  transition: all ease 0.2s;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  padding: 0;
  background-color: transparent;
  color: ${(props) => props.theme.colors.primary.first};
  border: none;

  /* STATUS */
  /* Hover status */
  @media (hover: hover) {
    &:hover:not(:disabled) {
      border-bottom: 1px solid ${(props) => props.theme.colors.primary.first};
    }
  }

  /* Active status */
  &:active:active {
    border-bottom: 1px solid ${(props) => props.theme.colors.primary.first};
  }

  /* STYLES */
  /* Disabled button */
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `}

  /* Loading button */
  ${(props) =>
    props.$loading &&
    css`
      position: relative;
      text-indent: 300%;
      pointer-events: none;
      cursor: default;

      &::after {
        content: '';
        display: block;
        width: ${loadingSize}px;
        height: ${loadingSize}px;
        border: solid ${loadingBorder}px ${props.theme.colors.main};
        border-bottom-color: white;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - ${loadingSize / 2 + loadingBorder}px);
        left: calc(50% - ${loadingSize / 2 + loadingBorder}px);
        animation: ${spin} linear infinite 0.5s;
      }
    `}
`;
