import {db} from '../utils/firebase';
import {useState, useEffect, useCallback} from 'react';

export default function useFirebaseVal(key) {
  const [value, setValue] = useState(undefined);

  const updateValue = useCallback((snapshot) => setValue(snapshot.val()), []);

  useEffect(() => {
    db.ref(key).on('value', updateValue);

    return () => db.ref(key).off('value', updateValue);
  }, [key]);

  const setRemoteValue = useCallback((value) => db.ref(key).set(value), [key]);

  return [value, setRemoteValue];
}
