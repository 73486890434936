import styled, {css} from "styled-components";

export const Button = styled.button`
    padding: 16px;
    text-align: center;
    color: white;
    background-color: ${props => props.theme.colors.yellow};
    border: solid 1px transparent;
    outline: none;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    color: ${props => props.theme.colors.blue};
    transition: all ease .2s;

    & + & {
        margin-left: 16px;
    }

    &:hover:not(:disabled) {
        cursor: pointer;
        background-color: ${props => props.theme.colors.yellowHover};
        border-color: ${props => props.theme.colors.yellowHover};
    }

    ${props => props.outlined && css`
        background-color: transparent;
        color: ${props => props.theme.colors.blue};
        border: solid 1px currentColor;

        &:hover:not(:disabled) {
            color: ${props => props.theme.colors.yellowHover};
            border-color: currentColor;
            background-color: transparent;
        }
    `}

    ${props => props.wide && css`
        width: 100%;
    `}
`;