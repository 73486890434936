import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalVideoTrack,
} from 'twilio-video';

export const IS_SCREEN_SHARING_SUPPORTED =
  navigator.mediaDevices &&
  typeof navigator.mediaDevices.getDisplayMedia !== 'undefined';

const IS_ENUMERATE_SUPPORTED =
  navigator.mediaDevices &&
  typeof navigator.mediaDevices.enumerateDevices !== 'undefined';

interface LocalTracksType {
  audio?: LocalAudioTrack;
  video?: LocalVideoTrack;
  screen?: LocalVideoTrack;
}

interface LocalTracksContextType {
  tracks: LocalTracksType;
  devices: MediaDeviceInfo[];

  getAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
  releaseAudioTrack: () => Promise<void>;
  getVideoTrack: (deviceId?: string) => Promise<LocalVideoTrack>;
  isVideoDeviceInUse: (deviceId: string) => boolean;
  isAudioDeviceInUse: (deviceId: string) => boolean;
  releaseVideoTrack: () => Promise<void>;
  getScreenTrack: () => Promise<LocalVideoTrack>;
  releaseScreenTrack: () => Promise<void>;
  fetchDevices: () => Promise<void>;
}

const LocalTracksContext = createContext<LocalTracksContextType>({
  devices: [],
  tracks: {
    audio: null,
    video: null,
    screen: null,
  },
  getAudioTrack: () => null,
  isAudioDeviceInUse: () => false,
  releaseAudioTrack: () => null,
  getVideoTrack: () => null,
  isVideoDeviceInUse: () => false,
  releaseVideoTrack: () => null,
  getScreenTrack: () => null,
  releaseScreenTrack: () => null,
  fetchDevices: () => null,
});

export const LocalTracksProvider = (props: PropsWithChildren<{}>) => {
  const [audio, setAudio] = useState<LocalAudioTrack>(null);
  const [video, setVideo] = useState<LocalVideoTrack>(null);
  const [screen, setScreen] = useState<LocalVideoTrack>(null);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

  const getAudioTrack = useCallback(
    async (deviceId?: string) => {
      if (audio) {
        if (deviceId && isAudioDeviceInUse(deviceId)) {
          return audio;
        }

        await releaseAudioTrack();
      }

      // can throw an error
      const track = await createLocalAudioTrack({
        name: 'mic',
        noiseSuppression: true,
        deviceId: deviceId,
      });

      track.on('stopped', () => setAudio(null));

      setAudio(track);
      return track;
    },
    [audio]
  );

  const isAudioDeviceInUse = useCallback(
    (deviceId: string) =>
      audio?.mediaStreamTrack.getSettings().deviceId === deviceId,
    [audio]
  );

  const releaseAudioTrack = useCallback(async () => {
    if (audio) {
      audio.stop();
    }
  }, [audio]);

  const getVideoTrack = useCallback(
    async (deviceId?: string) => {
      if (video) {
        if (deviceId && isVideoDeviceInUse(deviceId)) {
          return video;
        }

        await releaseVideoTrack();
      }

      const track = await createLocalVideoTrack({
        frameRate: 24,
        height: 720,
        width: 1280,
        name: 'camera',
        deviceId: deviceId,
      });

      track.on('stopped', () => setVideo(null));
      setVideo(track);
      return track;
    },
    [video]
  );

  const isVideoDeviceInUse = useCallback(
    (deviceId: string) =>
      video?.mediaStreamTrack.getSettings().deviceId === deviceId,
    [video]
  );

  const releaseVideoTrack = useCallback(async () => {
    if (video) {
      video.stop();
    }
  }, [video]);

  const getScreenTrack = useCallback(async () => {
    if (screen) {
      return screen;
    }

    const stream = await navigator.mediaDevices.getDisplayMedia();
    const screenTrack = new LocalVideoTrack(stream.getTracks()[0], {
      logLevel: 'debug',
      name: 'sharing',
    });

    screenTrack.on('stopped', async () => setScreen(null));

    setScreen(screenTrack);

    return screenTrack;
  }, [screen]);

  const releaseScreenTrack = useCallback(async () => {
    if (screen) {
      screen.stop();
    }
  }, [screen]);

  const fetchDevices = useCallback(async () => {
    if (!IS_ENUMERATE_SUPPORTED) {
      return;
    }

    const devices = await navigator.mediaDevices.enumerateDevices();
    setDevices(devices);
  }, []);

  const ctx: LocalTracksContextType = {
    devices,
    tracks: {
      audio,
      video,
      screen,
    },
    fetchDevices,
    getAudioTrack,
    isAudioDeviceInUse,
    releaseAudioTrack,
    getVideoTrack,
    isVideoDeviceInUse,
    releaseVideoTrack,
    getScreenTrack,
    releaseScreenTrack,
  };

  return (
    <LocalTracksContext.Provider value={ctx}>
      {props.children}
    </LocalTracksContext.Provider>
  );
};

export const useLocalTracks = () => {
  const context = useContext(LocalTracksContext);

  useEffect(() => {
    if (!IS_ENUMERATE_SUPPORTED) {
      return;
    }

    context.fetchDevices();
    navigator.mediaDevices.ondevicechange = context.fetchDevices;

    return () => (navigator.mediaDevices.ondevicechange = null);
  }, []);

  return context;
};
