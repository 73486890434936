import React, {useRef, useEffect} from 'react';
import useFullscreen from '../../../hook/useFullscreen';

import {VideoBox, SmallVideoBox} from './PartecipantBox';
import usePiP from '../../../hook/usePiP';

import type {AudioTrack, VideoTrack} from '../../../types/video-room';
import type {ParticipantCommand, ParticipantProps} from './types';
import {
  IconFullscreen,
  IconFullscreenOff,
  IconPip,
  IconPipOff,
} from '../../Icons';

export default function Participant({
  participant,
  // isPinned,
  // onPin,
  // handRaised = false,
  adminMute,
  adminKick,
  isLocalParticipant = false,
  smallBox = false,
  participantNumber,
}: ParticipantProps) {
  const videoTrack = participant.video?.track;
  const audioTrack = participant.audio?.track;

  const hasVideo = !!videoTrack;

  const fullscreenRef = useRef<HTMLDivElement>();
  const videoRef = useRef<HTMLVideoElement>();
  const audioRef = useRef();

  const fullscreen = useFullscreen(fullscreenRef);
  const pip = usePiP(videoRef);

  // const Box = !smallBox ? VideoBox : SmallVideoBox;
  // use only one VideoBox
  const Box = VideoBox;

  const commands: ParticipantCommand[] = [
    {
      type: 'fullscreen',
      enabled: fullscreen.isSupported && hasVideo,
      tooltip: fullscreen.isFullscreen
        ? 'Esci da schermo intero'
        : 'Schermo interno',
      handle: fullscreen.toggle,
      icon: fullscreen.isFullscreen ? IconFullscreenOff : IconFullscreen,
    },
    {
      type: 'picture-in-picture',
      enabled: pip.isSupported && hasVideo,
      tooltip: 'Picture in Picture',
      handle: pip.toggle,
      icon: pip.isPip ? IconPipOff : IconPip,
    },
  ];

  return (
    <Box
      // owner={participant.name || participant.identity}
      owner={participant.identity}
      fullscreen={fullscreen}
      fullscreenRef={fullscreenRef}
      audioRef={audioRef}
      videoRef={videoRef}
      isMute={!participant.audio?.isTrackEnabled}
      // isPinned={isPinned}
      // onPin={onPin}
      hasVideo={!!videoTrack}
      // handRaised={handRaised}
      isLocalVideo={isLocalParticipant}
      commands={commands}
      recording={{}}
      participantNumber={participantNumber}
    >
      <AppendedTracks
        videoTrack={videoTrack}
        videoRef={videoRef}
        audioTrack={audioTrack}
        audioRef={audioRef}
      />
    </Box>
  );
}

interface TracksContainerProps {
  videoTrack: VideoTrack;
  audioTrack: AudioTrack;
  videoRef: React.MutableRefObject<HTMLVideoElement>;
  audioRef: React.MutableRefObject<HTMLAudioElement>;
}

function AppendedTracks({
  videoTrack,
  videoRef,
  audioTrack,
  audioRef,
}: TracksContainerProps) {
  const tracksContainerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!videoTrack) {
      return;
    }

    videoRef.current = videoTrack.attach();

    tracksContainerRef.current.appendChild(videoRef.current);
    videoTrack.on('stopped', () => {
      if (videoRef.current) {
        videoRef.current.remove();
        videoRef.current = null;
      }
    });

    return () => videoRef.current && videoRef.current.remove();
  }, [videoTrack, videoRef]);

  useEffect(() => {
    if (!audioTrack) {
      return;
    }

    audioRef.current = audioTrack.attach();

    tracksContainerRef.current.appendChild(audioRef.current);

    audioTrack.on('stopped', () => {
      if (audioRef.current) {
        audioRef.current.remove();
        audioRef.current = null;
      }
    });

    return () => audioRef.current && audioRef.current.remove();
  }, [audioTrack, audioRef]);

  return <div ref={tracksContainerRef}></div>;
}
