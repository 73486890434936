import React, {useCallback, useMemo, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useLocalTracks} from '../contexts/LocalTracksContext';
import LocalMediaSetup from '../components/Video/LocalMediaSetup';
import {Button} from '../components/Buttons';
import {
  PreRoomCard,
  PageWithBackground,
  PreRoomCards,
} from '../components/Layout';
import BrowserSupport from '../components/BrowserSupport';
import Video from 'twilio-video';
import {uuid} from 'uuidv4';
import Room from './Room';
import Logo from '../../assets/images/logo-big.png';
import bg from '../../assets/images/background.jpg';
import VideoLogo from '../../assets/videos/logo-change-maker.mp4';
import VideoBackground from '../../components/VideoBackground';
import { useUser } from '../../contexts/UserContext';

export default function PreRoom() {
  const localTracks = useLocalTracks();
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');
  const history = useHistory();
  const {room} = useParams();
  const {user} = useUser();

  const clientIdentity = useMemo(() => {
    let identity = localStorage.getItem('userkey');

    if (identity) {
      return identity;
    }

    identity = user.onekey || uuid();
    localStorage.setItem('userkey', identity);
    return identity;
  }, [user]);

  const handleEnter = useCallback(async () => {
    const fd = new FormData();
    fd.append('room', room);
    fd.append('identity', clientIdentity);

    setIsLoading(true);

    try {
      const response = await fetch(
        `https://improntaadv.com/xmas2020/api-trisiko.php`,
        {
          method: 'POST',
          body: fd,
        }
      );

      const data = await response.json();
      setToken(data.token);
      // history.push(`/regia/${room}/${data.token}`);
    } catch (e) {
      console.error(e);
      alert('Si è verificato un errore');
      setIsLoading(false);
    }
  }, [room, clientIdentity, history]);

  if (Video.isSupported === false) {
    return (
      <PageWithBackground style={{backgroundImage: `url(${bg})`}}>
        <PreRoomCards>
          <PreRoomCard>
            <img
              src={Logo}
              alt="Novo Nordisk - Change Maker"
              style={{marginBottom: '32px', width: '120px', height: 'auto'}}
            />
            <BrowserSupport />
          </PreRoomCard>
        </PreRoomCards>
      </PageWithBackground>
    );
  }

  if (token) {
    return <Room room={room} token={token} />;
  }

  return (
    <PageWithBackground style={{backgroundImage: `url(${bg})`}}>
      <VideoBackground moved videoUrl={VideoLogo} />
      <PreRoomCards>
        <PreRoomCard>
          <img
            src={Logo}
            alt="Novo Nordisk - Change Maker"
            style={{marginBottom: '32px', width: '120px', height: 'auto'}}
          />
          <div>
            <h2>Salve</h2>
            <p>
              Provi il suo video e il suo audio, quando è pronto, prema{' '}
              <strong>
                <em>"entra"</em>
              </strong>
              {'.'}
            </p>
            <LocalMediaSetup
              videoTrack={localTracks.tracks.video}
              audioTrack={localTracks.tracks.audio}
              openMic={localTracks.getAudioTrack}
              closeMic={localTracks.releaseAudioTrack}
              openCamera={localTracks.getVideoTrack}
              closeCamera={localTracks.releaseVideoTrack}
              videoDevices={localTracks.devices.filter(
                (d) => d.kind === 'videoinput'
              )}
              audioDevices={localTracks.devices.filter(
                (d) => d.kind === 'audioinput'
              )}
            />

            <Button
              onClick={handleEnter}
              loading={isLoading}
              full
              title="Entra"
              style={{marginTop: '24px'}}
              team={room}
            ></Button>
          </div>
        </PreRoomCard>
      </PreRoomCards>
    </PageWithBackground>
  );
}
