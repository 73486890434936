import React from 'react';
import styled from 'styled-components';
import {ParticipantCommand} from '../types';

type CommandsProps = {
  commands: ParticipantCommand[];
  color: string;
  size: string;
  inline?: boolean;
};

export default function Commands({
  color,
  size,
  inline = false,
  commands,
}: CommandsProps) {
  const buttons = commands
    .filter((c) => c.enabled)
    .map((command) => (
      <ButtonVideoControl
        $color={color}
        $inline={inline}
        key={command.type}
        $tooltip={command.tooltip}
        onClick={command.handle}
      >
        <command.icon fill={color} size={size} />
      </ButtonVideoControl>
    ));

  if (buttons.length > 0) {
    return <CommandsContainer $inline={inline}>{buttons}</CommandsContainer>;
  }

  return null;
}

const CommandsContainer = styled.div<{$inline: boolean}>`
  padding: 4px;
  display: inline-flex;

  ${(props) => props.$inline && `display: block;`}
`;

const ButtonVideoControl = styled.button<{
  $inline: boolean;
  $decline?: boolean;
  $tooltip: string;
  $color: string;
}>`
  position: relative;
  margin: 4px 8px;
  padding: 0;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  appearance: none;
  cursor: pointer;

  &:hover {
    &:after {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }

  ${(props) => props.$decline && `background-color: rgba(255, 96, 96);`}

  &::after {
    content: '${(props) => props.$tooltip}';
    position: absolute;
    color: ${(props) => props.$color};
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    bottom: calc(100% + 10px);
    left: 50%;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 12px;
    text-transform: capitalize;
    pointer-events: none;
    transition: all ease 0.3s;
    white-space: nowrap;
    transform: translateX(-50%);
    opacity: 0;
  }

  ${(props) =>
    props.$inline &&
    `
    margin: 4px 0;
    width: auto;

    &::after {
      position: relative;
      left: inherit;
      bottom: inherit;
      opacity: 1;
      transform: none;
      text-shadow: none;
    }
  `}
`;
