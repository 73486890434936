import EventEmitter from 'eventemitter3';

import {
  connect,
  LocalAudioTrack,
  LocalDataTrack,
  LocalVideoTrack,
} from 'twilio-video';

import {ChatMessage, ExtParticipant} from '../types/video-room';

type DataMessage =
  | {type: 'chat'; payload: ChatMessage}
  | {type: 'mute'; payload: string}
  | {type: 'file'; payload: null};

export default class TwilioWrapper extends EventEmitter {
  room = null;

  dataTrack = null;

  async connect(
    token: string,
    audioTrack: LocalAudioTrack,
    videoTrack: LocalVideoTrack
  ) {
    this.dataTrack = new LocalDataTrack();

    this.room = await connect(token, {
      tracks: [audioTrack, videoTrack, this.dataTrack].filter(Boolean),
    });

    this.room.participants.forEach(this.trackParticipant);

    this.room.on('dominantSpeakerChanged', this._update);

    this.room.on('participantConnected', (participant) => {
      console.log(
        'Participant "%s:%s" connected',
        participant.sid,
        participant.identity
      );

      this.trackParticipant(participant);
    });

    this.room.on('participantDisconnected', this._update);

    this.room.once('disconnected', this._update);
  }

  public sendCommand(data: DataMessage) {
    console.log('Invio messaggio', data);
    this.dataTrack.send(JSON.stringify(data));
  }

  private trackParticipant = (participant: ExtParticipant) => {
    participant.on('trackSubscribed', (track) => {
      console.log('Track subscribed', track);
      track.on('enabled', this._update);
      track.on('disabled', this._update);
      track.on('started', this._update);

      if (track.kind === 'data') {
        track.on('message', (data: string) => {
          const message = JSON.parse(data) as DataMessage;
          console.log('Messaggio ricevuto', message);

          this.emit(message.type, participant.identity, message.payload);
        });
      }
    });

    participant.on('trackUnsubscribed', (track) => {
      console.log('Track unsubscribing', track);

      if (track.detach) {
        track.detach().forEach((element) => element.remove());
      }
      this._update();
    });

    this._update();
  };

  private _update = () => this.emit('update');
}
