import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import {Link} from 'react-router-dom';
import useFirebasePresence from '../hooks/useFirebasePresence';
import useExitConfirmation from '../hooks/useExitConfirmation';
import { useUser } from '../contexts/UserContext';
import useFirebaseVal from '../hooks/useFirebaseVal';
import SidebarRight from './Webinar/SidebarRight';
import SendRequest from './SendRequest';
import { ButtonControl } from './Buttons';
import Icon from './Icons';
import useChat from '../hooks/useChat';
import Chat from './Chat';
import PopupChangeRoom from './PopupChangeRoom';
import Logo from '../assets/images/logo-big.png';
import { db } from '../utils/firebase';

export default function WebinarRoom() {
    const chat = useChat('sala-comune');

    const {user} = useUser();
    const [videoUrl] = useFirebaseVal('vimeo');
    const [step] = useFirebaseVal('step');
    const [lockMessage] = useFirebaseVal('lockMessage');
    const [sidebarContent, setSidebarContent] = useState('');
    const [sendRequest, setSendRequest] = useState(false);
    const [workshopTime] = useFirebaseVal('workshopTime');
    const [changeRoomLink] = useFirebaseVal(`uw/${user.onekey}`);

    useFirebasePresence(user);
    // useExitConfirmation();

    useEffect(() => {
      if(step === 'open') {
        db.ref(`/joined`).push(user);
      }
    }, [user, step])

    return (
        <Container>
            {step === 'lock' && (
              <RoomMessage>
                <Link to="/">
                  <img src={Logo} alt="Change Maker" />
                </Link>
                <center>
                  <p><strong>{lockMessage}</strong></p>
                  <br />
                  <br />
                  <Link to="/">Torna alla Home</Link>
                </center>
              </RoomMessage>
            )}
            {step === 'open' && (
              <RoomMeetingContainer>
            {workshopTime && <PopupChangeRoom text="Si sposti nella sua room per partecipare al&nbsp;workshop!" url={changeRoomLink ? `room/${changeRoomLink}` : null} />}
                  <RoomMeetingWrapper shrink={sidebarContent !== ''}>
                      <LogoWrapper>
                        <Link to="/">
                          <img src={Logo} alt="Logo" />
                        </Link>
                      </LogoWrapper>

                      <IframeContainer>
                          <IframeWrapper>
                              <iframe
                                  src={videoUrl}
                                  width="100%"
                                  height="100%"
                                  frameBorder="0"
                                  allow="autoplay; fullscreen"
                                  allowfullscreen
                                  title="streaming"
                              ></iframe>
                          </IframeWrapper>
                      </IframeContainer>
                  </RoomMeetingWrapper>

                  <SidebarRight
                      open={sidebarContent !== ''}
                      sidebarContent={sidebarContent}
                      setSidebarContent={setSidebarContent}
                  >
                      {sidebarContent === 'chat' && (
                          <Chat
                              messages={chat.messages}
                              onSend={(text) => chat.sendMessage(user, text)}
                              me={user.name}
                              getParticipantById={(a) => a}
                          />
                      )}
                  </SidebarRight>

                  <SendRequest
                      user={user}
                      visible={sendRequest}
                      setSendRequest={setSendRequest}
                  />

                  <MeetingRoomControlsContainer>
                      <ContainerSmallSizeLeft />

                      <ContainerSmallSize>
                          <ButtonControl
                              tooltip={sendRequest ? 'Chiudi' : 'Invia una domanda'}
                              onClick={() => setSendRequest(!sendRequest)}
                          >
                              <Icon type={sendRequest ? 'requestOff' : 'request'} />
                          </ButtonControl>
                          <ButtonControl
                              alert={false}
                              tooltip="Chat"
                              onClick={() =>
                                  sidebarContent === 'chat'
                                      ? setSidebarContent('')
                                      : setSidebarContent('chat')
                              }
                          >
                              <Icon type={sidebarContent === 'chat' ? 'chatOff' : 'chat'} />
                          </ButtonControl>
                      </ContainerSmallSize>
                  </MeetingRoomControlsContainer>
              </RoomMeetingContainer>
            )}
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

const RoomMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 10px;
  width: calc(100% - 40px);
  max-width: 800px;
  animation: ${fadeIn} ease-in-out 0.8s;

  img {
    display: block;
    width: 100%;
    max-width: 700px;
    margin: 0 auto 50px
  }

  p {
    text-align: center;
    font-size: 28px;
    line-height: 1.5;
    color: ${props => props.theme.colors.grayscale.black};
    color: white;
  }
`;

const RoomMeetingContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  animation: ${fadeIn} ease-in-out 0.8s;
`;

const RoomMeetingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;

  ${(props) =>
        props.shrink &&
        `
      width: calc(100% - 300px);
        @media (max-width: 640px) {
          width: 100%;
        }
    `}
`;

const IframeContainer = styled.div`
  position: absolute;
  top: 50px;
  bottom: 100px;
  right: 50px;
  left: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  will-change: transform;
  /* opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transition: all ease-in-out 1s; */
  animation: ${fadeIn} ease-in-out 0.8s;
`;

const IframeWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
`;

const LogoWrapper = styled.div`
  width: 120px;
  position: absolute;
  left: 50px;
  bottom: 30px;
  display: flex;
  z-index: 1;

  img {
    display: block;
    margin: auto;
    width: 100%;
  }
`;

const MeetingRoomControlsContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  padding: 20px;
  height: ${(props) => props.theme.sizes.headerHeight}px;
  transition: transform ease 0.3s;

  button {
    margin: 0 6px;
    flex-shrink: 0;
  }

  ${(props) =>
        props.hidden &&
        `
    transform: translateY(100%);
  `}
`;

const ContainerSmallSize = styled.div`
  flex-shrink: 0;
  width: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

const ContainerSmallSizeLeft = styled(ContainerSmallSize)`
  justify-content: flex-start;
`;
