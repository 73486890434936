import React from 'react';
import styled from 'styled-components';
import Participant from './Participant';
import type {ParticipantProps} from './types';
import {useVideoboxDimensions} from '../ParticipantGrid/GroupGridProvider';

type GroupItemProps = ParticipantProps;

export default function GroupItem(props: GroupItemProps) {
  const [containerWidth, containerHeight] = useVideoboxDimensions();

  return (
    <Container width={containerWidth} height={containerHeight}>
      <Participant
        smallBox={props.isLocalParticipant || containerWidth < 250}
        {...props}
      />
    </Container>
  );
}

const Container = styled.div<{width: number; height: number}>`
  position: relative;
  display: flex;
  padding: 20px;

  align-items: center;
  justify-content: center;

  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 10px;
  }

  > div:first-child {
    padding-bottom: 56.25%;
  }
`;
