import React, {useCallback, useEffect, useState} from 'react';
import {useVideoRoom, VideoRoomProvider} from '../contexts/VideoRoomContext';
import {Header, Page, PageInside, Footer} from '../components/VideoRoom';
import ParticipantGrid from '../components/VideoRoom/ParticipantGrid';
import Loading from '../components/Loading';
import useExitConfirmation from '../hook/useExitConfirmation';
import PopupChangeRoom from '../../components/PopupChangeRoom';
import background from '../../assets/images/background.jpg';
import useChat from '../hook/useChat';
import Sidebar from '../components/VideoRoom/Sidebar';
import { useUser } from '../../contexts/UserContext';
import useFirebaseVal from '../../hooks/useFirebaseVal';

function Room({token, room}) {
  const videoRoom = useVideoRoom()
  const {user} = useUser();

  const isAdmin = user && user.isAdmin;

  const [sidebar, setSidebar] = useState<SidebarType>('');
 const chat = useChat(videoRoom, sidebar !== 'chat');

  const handleDisconnect = useCallback(() => {
    videoRoom.quit();
    window.location.href = '/';
  }, [videoRoom]);

  // Changing room popup
  const [changeRoomLink] = useFirebaseVal(`exit-${room}`);

  const toggleSidebar = useCallback(
    (type) => setSidebar((sidebar) => (sidebar === type ? '' : type)),
    []
  );

  useEffect(() => {
    if (videoRoom.room?.roomState === 'disconnected') {
      handleDisconnect();
    }
  }, [videoRoom.room?.roomState, handleDisconnect]);

  useEffect(() => {
    videoRoom.enter(token, '123');

    return () => {videoRoom.quit()};
  }, []);

  useExitConfirmation();

  useEffect(() => {
    window.addEventListener('unload', videoRoom.quit);
    return () => window.removeEventListener('unload', videoRoom.quit);
  }, [videoRoom.quit]);

  useEffect(() => {
    const calcVH = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', calcVH);
    return () => window.removeEventListener('resize', calcVH);
  }, []);

  if (!videoRoom.initialized) {
    return (
      <>
        <Page>
          <Loading />
          {/* <h1>Loading room</h1>
          Token: {router.query.token}
          <br />
          Initialized: {videoRoom.initialized ? 'yes' : 'no'} */}
        </Page>
      </>
    );
  }

  return (
    <>
      <Page style={{backgroundImage: `url(${background})`}}>
        {!!changeRoomLink && <PopupChangeRoom url={changeRoomLink} text="Grazie per la partecipazione!<Br />Torni con i colleghi per scoprire i risultati." />}
        <Header
          short={!!sidebar}
          peopleCount={videoRoom.room.remotes.length + 1}
          roomMaxSize={8}
        />
        <PageInside $short={!!sidebar}>
          <ParticipantGrid hasSidebar={!!sidebar} />
        </PageInside>
      </Page>
      <Sidebar
          handleSidebar={toggleSidebar}
          active={!!sidebar}
          type={sidebar}
          messages={chat.messages}
          onSendMessage={chat.sendMessage}
          // onKick={videoRoom.handleKick}
          // onMute={videoRoom.muteParticipant}
          // files={files.files}
          // onFileUpload={files.uploadFile}
          // onFileDownload={files.downloadFile}
          // onFileDelete={isAdmin ? files.deleteFile : () => {}}
          isAdmin={isAdmin}
        />
      <Footer short={!!sidebar} onQuit={handleDisconnect} messagesCount={chat.hasNewMessages} sidebarStatus={sidebar}
        handleSidebar={toggleSidebar} isAdmin={isAdmin} />
    </>
  );
}

const RoomWithVideoRoom = (props) => (
  <VideoRoomProvider isAdmin={props.isAdmin}>
    <Room {...props} />
  </VideoRoomProvider>
);

export default RoomWithVideoRoom;
