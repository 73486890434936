import {MutableRefObject, useCallback, useState} from 'react';

const PIP_ENABLED =
  typeof window !== 'undefined' && 'pictureInPictureEnabled' in document;

export type PiPHook = {
  isSupported: boolean;
  isPip: boolean;
  start: () => void;
  stop: () => void;
  toggle: () => void;
};

export default function usePiP(
  videoRef: MutableRefObject<HTMLVideoElement>
): PiPHook {
  const [isPip, setIsPip] = useState(false);

  const start = useCallback(async () => {
    if (!PIP_ENABLED) {
      console.error('PiP is not supported');
      return;
    }

    if (!videoRef.current) {
      console.error('Element does not exist');
      return;
    }

    try {
      // @ts-ignore
      await videoRef.current.requestPictureInPicture();
      setIsPip(true);
    } catch (err) {
      alert(
        `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
      );
    }
  }, [videoRef]);

  const stop = useCallback(() => {
    // @ts-ignore
    if (document.pictureInPictureElement) {
      // @ts-ignore
      document.exitPictureInPicture();
    }

    setIsPip(false);
  }, []);

  const toggle = useCallback(() => {
    // @ts-ignore
    if (document.pictureInPictureElement) {
      stop();
    } else {
      start();
    }
  }, [start, stop]);

  return {
    isSupported: PIP_ENABLED,
    isPip,
    start,
    stop,
    toggle,
  };
}
