import {React} from 'react';
import styled from 'styled-components';
import { useLocation } from "react-router-dom";

import BGImage from '../assets/images/bg-pattern.png';
import {Circles, Dots} from '../components/GraphicElements';

const Base = props => {
    const {pathname} = useLocation();

    return(
        <Container>
            <Dots />
            {pathname === '/' && <Circles />}
            {props.children}
        </Container>
    );
}

export default Base;

const Container = styled.div`
    overflow: hidden;
    position: relative;
    min-height: 100vh;
    background-color: red;
    background-position: center top;
    background-size: 100%;
    background-repeat: repeat-y;
    background-image: linear-gradient(to bottom, ${props => props.theme.colors.blue} 300px, ${props => props.theme.colors.violet} 1000px, ${props => props.theme.colors.blue} 3000px);
    background-image: url(${BGImage}), linear-gradient(to bottom, ${props => props.theme.colors.blue} 300px, ${props => props.theme.colors.violet} 1000px, ${props => props.theme.colors.blue} 3000px);
`;