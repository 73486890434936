import React from 'react';
import styled, {css} from 'styled-components';

import Chat from './Chat';
// import Notifications from './Notifications';
import {
  ChatMessage,
  RemoteParticipant,
  RoomDocument,
  SidebarType,
} from '../../../types/video-room';
import {useVideoRoom} from '../../../contexts/VideoRoomContext';
import { IconClose } from '../../Icons';
// import {PartitionedRequests} from '../../../hook/useRoomRequests';
// import MeetingInfos from './MeetingInfos';
// import Files from './Files';

interface SidebarProps {
  handleSidebar: (type: string) => void;
  active: boolean;
  type: SidebarType;
  // roomMaxSize: number;
  // notifications: PartitionedRequests;
  messages: ChatMessage[];
  onSendMessage: (text: string) => void;
  // files: RoomDocument[];
  // onFileUpload: (file: File, durationType: string) => Promise<void>;
  // onFileDownload: (file: RoomDocument) => void;
  // onFileDelete?: (file: RoomDocument) => void;
  // onKick?: (participant: RemoteParticipant) => void;
  // onMute?: (participant: RemoteParticipant) => void;
  isAdmin: boolean;
}

const Sidebar = (props: SidebarProps) => {
  const videoRoom = useVideoRoom();

  // if (props.type === 'notifications') {
  //   return (
  //     <VideoRoomSidebarContainer $active={props.active}>
  //       <VideoRoomSidebarContent>
  //         <Notifications
  //           roomSize={videoRoom.room.remotes.length + 1}
  //           roomMaxSize={props.roomMaxSize}
  //           notifications={props.notifications}
  //         />
  //       </VideoRoomSidebarContent>
  //     </VideoRoomSidebarContainer>
  //   );
  // }

  if (props.type === 'chat') {
    return (
      <VideoRoomSidebarContainer $active={props.active}>
        <VideoRoomSidebarContent>
          <CloseSidebar onClick={() => props.handleSidebar('chat')}>
            <IconClose size={36} />
          </CloseSidebar>
          <Chat
            myIdentity={videoRoom.room.localParticipant.identity}
            messages={props.messages}
            onSendMessage={props.onSendMessage}
          />
        </VideoRoomSidebarContent>
      </VideoRoomSidebarContainer>
    );
  }

  // if (props.type === 'group') {
  //   return (
  //     <VideoRoomSidebarContainer $active={props.active}>
  //       <VideoRoomSidebarContent>
  //         <MeetingInfos
  //           roomMaxSize={props.roomMaxSize}
  //           remoteParticipants={videoRoom.room.remotes}
  //           localParticipant={videoRoom.room.localParticipant}
  //           onKick={props.onKick}
  //           onMute={props.onMute}
  //         />
  //       </VideoRoomSidebarContent>
  //     </VideoRoomSidebarContainer>
  //   );
  // }

  // if (props.type === 'files') {
  //   return (
  //     <VideoRoomSidebarContainer $active={props.active}>
  //       <VideoRoomSidebarContent>
  //         <Files
  //           files={props.files}
  //           onFileUpload={props.onFileUpload}
  //           onFileDownload={props.onFileDownload}
  //           onFileDelete={props.onFileDelete}
  //           isAdmin={props.isAdmin}
  //         />
  //       </VideoRoomSidebarContent>
  //     </VideoRoomSidebarContainer>
  //   );
  // }

  return null;
};

export default Sidebar;

export const VideoRoomSidebarContainer = styled.aside<{$active: boolean}>`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  width: 370px;
  padding: 12px 0 0;
  z-index: 4;
  transform: translateX(100%);
  box-shadow: 0 0 10px ${(props) => props.theme.colors.shadow};
  transition: transform 300ms, top 300ms, right 300ms;

  @media ${(props) => props.theme.breakpoints.phone} {
    transform: translateX(0) translateY(100%);
    width: 100%;
    top: ${props => props.theme.layout.roomHeader};
  }

  ${(props) =>
    props.$active &&
    css`
      transform: translateX(0);

      @media ${(props) => props.theme.breakpoints.tablet} {
        transform: translateX(0) translateY(0);
      }
      @media ${(props) => props.theme.breakpoints.phone} {
        transform: translateX(0) translateY(0);
      }
    `}
`;

export const VideoRoomSidebarContent = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 0 12px 12px;
  display: flex;
  flex-direction: column;
`;

const CloseSidebar = styled.div`
  position: fixed;
  right: 15px;
  top: 6px;
  z-index: 4;
  border-radius: 5px;
  height: 36px;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 400ms;

  &:hover {
    background-color: ${(props) => props.theme.colors.grayscale.grayTwo};
  }
`;
