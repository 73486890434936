const THEME_ONE = {
    colors: {
        blue: '#1A119B',
        violet: '#320070',
        heavenly: '#00EBFF',
        yellow: '#FFAD00',
        yellowHover: '#f1a300',
        shadow: 'rgba(0, 0, 0, 0.2)',
        primary: {
            first: '#2aaae9',
            second: '#198bc3',
            third: '#2aaae9',
        },
        secondary: {
            accent: '#FF6C00',
            decline: '#FF6060',
            success: '#0ED7A1',
            bg: '#eef0f6',
        },
        grayscale: {
            white: '#ffffff',
            grayOne: '#F5F5F5',
            grayTwo: '#BCBCBC',
            medGrayOne: '#919191',
            medGrayTwo: '#575757',
            black: '#000000',
        },
        elements: {
            chatMine: '#575757',
            header: 'rgba(255, 255, 255, 0.8)',
            cta: '#FFAD00',
            ctaHover: '#f1a300',
            headingPreRoom: {
                first: '#198bc3',
                second: '#BCBCBC',
            },
            headingHeader: {
                first: '#198bc3',
                second: '#BCBCBC',
            },
            animationBG: '#000',
        },
    },
    sizes: {
        mw: '1800px',
    },
    layout: {
        roomHeader: '55px',
        roomFooter: '65px',
    },
    breakpoints: {
        header: 'screen and (max-width: 1200px)',
        laptop: 'screen and (max-width: 1440px)',
        landscape: 'screen and (max-width: 1024px)',
        tablet: 'screen and (max-width: 768px)',
        phone: 'screen and (max-width: 640px)',
        smallPhone: 'screen and (max-width: 320px)',
    },
}

export const getCurrentTheme = () => THEME_ONE;