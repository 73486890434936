import styled, { keyframes } from "styled-components";
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo-small.png';

const PopupElement = ({ url, text }) => {

    return (
        <Popup>
            <PopupWrapper>
                <LogoContainer>
                    <img src={Logo} alt="Change Maker" />
                </LogoContainer>
                <PopupBody>
                    {url ? <>
                    <h2 dangerouslySetInnerHTML={{__html: text}} />
                    <Link to={url}>Entra</Link>
                    </> :
                    <h2>Preparazione della stanza del workshop in&nbsp;corso...</h2>}
                </PopupBody>
            </PopupWrapper>
        </Popup>
    );
}

export default PopupElement;

const fade = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;
const slide = keyframes`
    from {
        transform: translateY(20px);
    }
    to {
        transform: translateY(0);
    }
`;

const Popup = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    max-height: 100vh;
    padding: 20vh 20px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.7);
    overflow-y: auto;
    z-index: 99;
    animation: ${fade} .15s linear backwards;
`;

const PopupWrapper = styled.div`
    width: 100%;
    max-width: 600px;
    padding: 40px 20px;
    background-color: ${props => props.theme.colors.blue};
    border-radius: 4px;
    cursor: default;
    color: initial;
    animation: ${slide} 0.3s linear backwards;
`;

const LogoContainer = styled.div`
    max-width: 260px;
    margin: 0 auto;
    margin-bottom: 50px;

    img {
        display: block;
        margin: 0 auto;
        width: 100%;
    }
`;

const PopupBody = styled.div`

    * {
        margin: 0;
        color: white;
        text-align: center;
    }

    * + * {
        margin-top: 32px;
    }

    h2 {
        font-size: 24px;
        line-height: 1.5;
    }

    a {
        font-size: 20px;
        line-height: 1;
        display: block;
        color: ${props => props.theme.colors.yellow};
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    button {
        display: inline-block;
        width: auto;
        text-align: left;
    }

    button, span, a {
        color: ${props => props.theme.colors.yellow};
        font-size: 28px;
        line-height: 44px;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        padding: 0;
    }

    button, a {
        text-decoration: underline;
        cursor: pointer;
    }
`;