import {useState, useEffect, useCallback} from 'react';
import {db} from '../utils/firebase';

export default function useChat(room) {
  const KEY = `chat-${room}`;
  const [messages, setMessages] = useState([]);

  const sendMessage = useCallback(
    async (user, text) => {
      await db.ref(KEY).push({
        id: Date.now(),
        date: Date.now(),
        user: user.name,
        userObj: user,
        message: text,
      });
    },
    [messages, setMessages]
  );

  useEffect(() => {
    db.ref(KEY).on('value', (snapshot) => {
      console.log('onSnapshot', snapshot);
      const chats = [];
      snapshot.forEach((snap) => {
        chats.push(snap.val());
      });

      setMessages(chats);
    });
  }, []);

  return {
    messages,
    sendMessage,
  };
}
